import React from "react";
import DetailHeading from "../../../components/DetailHeading";
import SelectInput from "../../../components/SelectInput";
import CustomInput from "../../../components/CustomInput";

import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import {
  useAddServiceMutation,
  useGetContractorRemainingServiesQuery,
} from "../../../redux/api/services";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";

const validationSchema = Yup.object({
  service: Yup.string().required("select service"),
  hourlyRate: Yup.number("Must be number")
    .positive("Must be positive value")
    .required("hourly rate is required"),
});

function AddService({ setShowAddService }) {
  const {
    data: userServices,
    isLoading,
    isError,
    error,
  } = useGetContractorRemainingServiesQuery();
  // const {data:remainingServices,isLoading,isError,error}=useGetRemainingServiesQuery();
  const [addService, { isLoading: isAddingServie }] = useAddServiceMutation();
  const { handleSubmit, values, errors, handleChange } = useFormik({
    initialValues: {
      service: "",
      hourlyRate: "",
    },
    validationSchema,
    onSubmit: async ({ service, hourlyRate }) => {
      try {
        await addService({
          service_id: service,
          rate_hourly: hourlyRate,
        }).unwrap();
        setShowAddService(false);
      } catch (error) {
        console.log(error);
      }
    },
  });
  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <Error error={error} />;
  }

  return (
    <>
      <DetailHeading color="#161140">Add Services</DetailHeading>
      <form onSubmit={handleSubmit} className="mt-3">
        <SelectInput
          heading="Service"
          options={
            userServices
              ? userServices.data.map(({ id, name }) => ({
                  id,
                  name,
                }))
              : []
          }
          htmlFor="service"
          onChange={handleChange}
          value={values.service}
          error={errors.service}
        />
        <CustomInput
          heading="Hourly Rate"
          type="number"
          htmlFor="hourlyRate"
          placeholder="Enter hourly rate"
          onChange={handleChange}
          value={values.hourlyRate}
          error={errors.hourlyRate}
        />
        <Button type="submit" className="mt-2" disabled={isAddingServie}>
          {isAddingServie ? "Add..." : "Add"}
        </Button>
      </form>
    </>
  );
}

export default AddService;
