import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

// const { access_token } = getCurrentUser();
export const profileApi = createApi({
    reducerPath: "profileApi",
    tagTypes: ['profie', "proposals"],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            return authorizationHeader(headers, getState);
        },
    }),

    endpoints: (builder) => ({
        getUserProfile: builder.query({
            query: () => "/get_user_profile",
            providesTags: ['profile']
        }),
        getUserReviews: builder.query({
            query: () => "/user/reviews_list",
        }),
        getContractorReviews: builder.query({
            query: () => "/contractor/reviews_list",
        }),
        deleteProfile: builder.query({
            query: () => "/delete_user",
        }),
        getNotifications: builder.query({
            query: (page=1) => "/notifications"+`?page=${page}`,
        }),
    }),
});

export const {
    useGetUserProfileQuery,
    useGetUserReviewsQuery,
    useGetContractorReviewsQuery,
    useLazyDeleteProfileQuery,
    useGetNotificationsQuery
} = profileApi;
