import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import Notification from "../assets/icons/Notification.svg";
import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../utils/Path";
import { logoutOutCurrentUser, userRole } from "../utils/currentUser";
import { toast } from "react-toastify";
import {
  useGetUserProfileQuery,
  useLazyDeleteProfileQuery,
} from "../redux/api/profile";
import CustomModal from "./Modal";
import Button from "./Button";
import { removeRtkDataHandler } from "../utils";
import DropdownComp from "./Dropdown";
import { useGetNotificationsQuery } from "../redux/api/profile";
import { img_base_url } from "../utils";
import Loader from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewMessage,
  addNewNotification,
  resetMessages,
  resetNotifications,
} from "../redux/slices/notification";
import { useSocket } from "../utils/socket";

const HEADER = [
  {
    title: "Home",
    contractorPath: PATH.CONTRACTORHOME,
    userPath: PATH.USERHOME,
  },
  { title: "Jobs", contractorPath: PATH.CONTRACTORJOB, userPath: PATH.USERJOB },
  {
    title: "Messages",
    contractorPath: PATH.CONTRACTORCHATS,
    userPath: PATH.USERCHATS,
  },
];

function Header() {
  const role = userRole();
  const socket = useSocket();

  const [trigger, { isLoading }] = useLazyDeleteProfileQuery();
  const { messages, userNotifications } = useSelector(
    (state) => state.notifications
  );
  const dispatch = useDispatch();

  const {
    data: profileData,
    isLoading: isLoadingProfile,
    refetch,
  } = useGetUserProfileQuery();

  const { data: { id, name = "", email = "", image = null } = {} } =
    profileData || {};

  useEffect(() => {
    refetch();
  }, []);

  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const [, setSelected] = useState(0);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleHeading = (id) => {
    setSelected(id);
  };

  const handleLogoutUser = () => {
    logoutOutCurrentUser();
    navigate(PATH.LOGIN);
    toast.success("Logout Successfully");
  };

  // event for receive notifications
  useEffect(() => {
    if (!profileData) {
      return;
    }
    const handleReceiveNotifications = (message) => {
      if (message.data.notification.on_user === id) {
        dispatch(addNewNotification());
      }
    };

    socket.on("notification_receive", handleReceiveNotifications);

    return () => {
      socket.off("notification_receive", handleReceiveNotifications);
    };
  }, [socket, profileData]);
  // event for receive chat message
  useEffect(() => {
    if (!profileData) {
      return;
    }
    const handleMessageReceive = (data) => {
      if (data.message.receiver_id === id && !pathname.includes("chats")) {
        dispatch(addNewMessage());
      }
    };

    socket.on("message_receive", handleMessageReceive);

    return () => {
      socket.off("message_receive", handleMessageReceive);
    };
  }, [socket, profileData]);

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    refetch: refetchNotifications,
    isUninitialized,
  } = useGetNotificationsQuery(undefined, { refetchOnMountOrArgChange: true });

  return (
    <div className="fixed top-0 w-full z-10">
      <CustomModal open={showDeleteModal} setOpen={setshowDeleteModal}>
        <div className="mt-4">
          <h6>
            <span className="font-medium">Warning:</span> Account Deletion
          </h6>
          <p>
            {" "}
            <span className="font-medium">This Action Is Irreversible</span> -
            Proceed with Caution
          </p>
          <div className="flex justify-end gap-4 mt-3">
            <Button
              onClick={() => setshowDeleteModal(false)}
              disabled={isLoading}
              className="mt-2 w-auto"
            >
              No
            </Button>
            <Button
              onClick={async () => {
                await trigger();
                navigate("/login");
                removeRtkDataHandler();
              }}
              className="mt-2 w-auto"
            >
              {isLoading ? "Deleting..." : "Yes"}
            </Button>
          </div>
        </div>
      </CustomModal>
      <Navbar rounded className="shadow bg-white">
        <Navbar.Brand>
          <img src={Logo} width={100} alt="Logo" className="w-16" />
        </Navbar.Brand>
        <div className="flex items-center md:order-2 gap-3">
          {/* <img src={Search} className="hidden sm:block w-6 h-6" /> */}
          {!notifications && isLoadingNotifications ? (
            <Loader />
          ) : notifications ? (
            <DropdownComp
              label={
                <div>
                  <button
                    type="button"
                    className="relative inline-flex items-center text-sm font-medium text-center"
                    onClick={() => {
                      dispatch(resetNotifications());
                      if (!isUninitialized) {
                        refetchNotifications();
                      }
                    }}
                  >
                    <Avatar alt="Notification_img" img={Notification} rounded />
                    {userNotifications > 0 && (
                      <div className="absolute top-0 right-0">
                        <span className="relative flex h-3 w-3">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                          <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                        </span>
                      </div>
                    )}
                    <span className="sr-only">Notifications</span>
                  </button>
                </div>
              }
              placement="bottom"
              arrowIcon={false}
              data={notifications?.data?.data}
              style="max-w-[410px] !min-w-[300px] max-h-[350px] overflow-y-auto p-2"
            />
          ) : null}
          <div className="border rounded-full pr-1">
            {isLoadingProfile ? (
              <Loader />
            ) : (
              <Dropdown
                arrowIcon={true}
                inline
                label={
                  <>
                    <Avatar
                      alt="User settings"
                      img={image ? `${img_base_url}${image}` : ""}
                      rounded
                      className="p-1"
                    />
                    <p className="font-inter ml-2">{name}</p>
                  </>
                }
              >
                <Dropdown.Header>
                  <span className="block truncate text-sm font-medium">
                    {email}
                  </span>
                </Dropdown.Header>

                <div>
                  {role === "user" ? (
                    <>
                      <Dropdown.Item onClick={() => navigate(PATH.USERPROFILE)}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate(PATH.USERREVIEWS)}>
                        Reviews
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=>window.open(PATH.LICENSE,'_blank')}>
                        EULA & Privacy Policy
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-red-500 font-bold"
                        onClick={() => setshowDeleteModal(true)}
                      >
                        Delete Account
                      </Dropdown.Item>
                      <Dropdown.Divider />
                    </>
                  ) : (
                    <>
                      <Dropdown.Item
                        onClick={() => navigate(PATH.CONTRACTORPROFILE)}
                      >
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(PATH.CONTRACTORREVIEWS)}
                      >
                        Reviews
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(PATH.CONTRACTORLICENCES)}
                      >
                        License No
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => navigate(PATH.CONTRACTORSERVICES)}
                      >
                        Services
                      </Dropdown.Item>
                      <Dropdown.Item>
                        {/* <a
                          href="https://zitroconnect.com/contractor/terms_conditions"
                          target="_blank"
                        >
                          Terms & Condition
                        </a> */}
                        <Link to={PATH.CONTRACTORTERMSCONDITION}>
                          Terms & Condition
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        {/* <a
                          href="https://zitroconnect.com/contractor/privacy_policy"
                          target="_blank"
                        >
                          Privacy & Policy
                        </a> */}
                        <Link to={PATH.CONTRACTORPRIVACYPOLICY}>
                          Privacy & Policy
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <a
                          href="https://zitroconnect.com/license"
                          target="_blank"
                        >
                          License Agreement
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-red-500 font-bold"
                        onClick={() => setshowDeleteModal(true)}
                      >
                        Delete Account
                      </Dropdown.Item>
                    </>
                  )}
                  <Dropdown.Item
                    onClick={handleLogoutUser}
                    className="font-bold text-red-500"
                  >
                    Log out
                  </Dropdown.Item>{" "}
                </div>

                <Dropdown.Divider />
                {/* <Dropdown.Item onClick={handleLogoutUser} className="font-bold">
                Log out
              </Dropdown.Item> */}
              </Dropdown>
            )}
          </div>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse className="cursor-pointer">
          {HEADER.map((obj, i) => (
            <div key={i} className="text-base px-4">
              {obj.title === "Messages" ? (
                <button
                  type="button"
                  className="relative inline-flex"
                  onClick={() => dispatch(resetMessages())}
                >
                  <span
                    className={`${
                      pathname.includes(obj.title.toLowerCase())
                        ? "!text-[#ED1C24]"
                        : "text-black"
                    } `}
                    onClick={() => {
                      navigate(
                        role === "contractor"
                          ? obj.contractorPath
                          : obj.userPath
                      );
                      handleHeading(i);
                    }}
                  >
                      <Navbar.Link className="p-0.5">
                    {obj.title}
                      </Navbar.Link>
                  </span>
                  {messages > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-3 -end-3.5 dark:border-gray-900">
                      {messages > 9 ? "9+" : messages}
                    </div>
                  )}
                </button>
              ) : (
                <span
                  className={`${
                    pathname.includes(obj.title.toLowerCase())
                      ? "!text-[#ED1C24]"
                      : "text-black"
                  } `}
                  onClick={() => {
                    navigate(
                      role === "contractor" ? obj.contractorPath : obj.userPath
                    );
                    handleHeading(i);
                  }}
                >
                  <Navbar.Link className="p-0.5">{obj.title}</Navbar.Link>
                </span>
              )}
            </div>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
