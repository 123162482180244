import React, { useEffect, useRef, useState } from "react";
import { useGetSingleChatMutation } from "../../../redux/api/chat";
import { getCurrentUser } from "../../../utils/currentUser";
import SenderMessage from "./SenderMsg";
import ReceiverMessage from "./ReceiverMsg";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { Avatar } from "flowbite-react";
import SendMessage from "./SendMessage";
import { useLocation } from "react-router-dom";
import { useSocket } from "../../../utils/socket";
import Button from "../../../components/Button";
import { IoMdArrowBack } from "react-icons/io";
import useMediaArea from "../../../hooks/useMediaArea";

const SingleChat = ({ chatId, setChatId, selectedProfileChatData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const socket = useSocket();
  const {width}=useMediaArea();
  const [getSingleChat, { isLoading, isError, error }] =
    useGetSingleChatMutation();
  const [chatMessages, setChatMessages] = useState([]);
  const {
    user: { id: userId },
  } = getCurrentUser();
  const { user } = getCurrentUser();
  const { pathname, state } = useLocation();
  const listRef = useRef(null);

  const { name = "", image = null } = selectedProfileChatData || {};

  // chat handler runs one time when component chatId changes
  const getSingleChatHandler = async () => {
    try {
      const {
        data: {
          message: { data: newChatMessages, last_page },
        },
      } = await getSingleChat({
        data: { chat_id: chatId },
        page: 1,
      }).unwrap();
      setTotalPages(last_page);
      setChatMessages([...newChatMessages].reverse());
    } catch (error) {
      console.log(error);
    }
  };

  // runs after page is greater then 1
  const getSingleChatHandlers = async (page = 1) => {
    try {
      const {
        data: {
          message: { data: newChatMessages, last_page },
        },
      } = await getSingleChat({
        data: { chat_id: chatId },
        page,
      }).unwrap();
      setTotalPages(last_page);
      setChatMessages((prevChatMessages) => [
        ...newChatMessages,
        ...prevChatMessages,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  // run only first time when person chat open
  useEffect(() => {
    if (chatId) {
      setCurrentPage(1);
      getSingleChatHandler();
    }
  }, [chatId]);

  useEffect(() => {
    if (totalPages && listRef?.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [totalPages]);

  // add new message from socket
  const addNewMessageBySocket = ({ message }) => {
    setChatMessages((prevMesssages) => [...prevMesssages, message]);
    scrollIntoView();
  };

  // event for receive chat message
  useEffect(() => {
    socket.on("message_receive", addNewMessageBySocket);
    return () => {
      socket.off("message_receive", addNewMessageBySocket);
    };
  }, [socket]);

  useEffect(() => {
    if (currentPage === 1) {
      scrollIntoView();
    }
  }, [chatMessages]);

  const scrollIntoView = () => {
    listRef.current?.lastElementChild?.scrollIntoView();
  };

  return (
    <>
      <div className="h-[74vh] overflow-y-auto">
        {currentPage === 1 && isLoading ? (
          <Loader />
        ) : isError ? (
          <Error error={error} />
        ) : (
          <>
            <div className="flex flex-row py-4 px-2 justify-between items-center border-b-2 ">
              <div className="flex gap-4 items-center">
                {
                  width<769 && (
                    <IoMdArrowBack
                      fontSize={22}
                      onClick={() => setChatId(null)}
                      className="cursor-pointer"
                    />
                  )
                }
                <div className="relative">
                  <Avatar alt="User settings" img={image} rounded />
                  <span className="w-3 h-3 rounded-full bg-[#8FFF00] left-7 top-8 absolute" />
                </div>
                <div>
                  <p className="text-sm font-semibold">
                    {name || state?.receiverName}
                  </p>
                </div>
              </div>
            </div>
            <div className="h-[60vh] overflow-auto">
              {currentPage < totalPages && (
                <div className="flex justify-center my-2 font-normal">
                  <Button
                    disabled={isLoading}
                    className="w-auto p-2"
                    onClick={() => {
                      setCurrentPage((prev) => prev + 1);
                      getSingleChatHandlers(currentPage + 1);
                    }}
                  >
                    {isLoading && currentPage > 1
                      ? "Load more..."
                      : " Load more"}
                  </Button>
                </div>
              )}
              <div ref={listRef}>
                {pathname.includes("user")
                  ? chatMessages.map(
                      ({ id, message, sender_id, updated_at }) => {
                        return sender_id === userId ? (
                          <ReceiverMessage
                            key={id}
                            message={message}
                            updatedAt={updated_at}
                          />
                        ) : (
                          <SenderMessage
                            key={id}
                            message={message}
                            updatedAt={updated_at}
                          />
                        );
                      }
                    )
                  : chatMessages.map(
                      ({ id, message, sender_id, updated_at }) => {
                        return sender_id === user[0].id ? (
                          <ReceiverMessage
                            key={id}
                            message={message}
                            updatedAt={updated_at}
                          />
                        ) : (
                          <SenderMessage
                            key={id}
                            message={message}
                            updatedAt={updated_at}
                          />
                        );
                      }
                    )}
              </div>
            </div>
          </>
        )}
      </div>
      <SendMessage initialChatData={selectedProfileChatData} chatId={chatId} />
    </>
  );
};

export default SingleChat;
