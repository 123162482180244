import React, { useState } from "react";
import CustomButton from "../../components/CustomButton";
import contactUs from "../../assets/images/contact-us.jpg";
import Container from "../../components/Container";
import { FaMapPin } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "flowbite-react";

export const contactUsEmail = "info@zitroservicesllc.com";
export const contactUsPhone = "+1 (301) 434-0314";
export const contactUsAddress =
  "1738 Elton Rd Suite #222, Silver Spring, MD 20903";

export const contactFormDataDefaultValues = {
  name: "",
  email: "",
  description: "",
};

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(contactFormDataDefaultValues);

  // handle form change
  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    for (const formValue of Object.values(formData)) {
      if (!formValue) {
        console.log("....", formValue);
        isFormValid = false;
      }
    }
    if (isFormValid) {
      try {
        setIsLoading(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/support`,
          formData
        );
        toast.success(data.message);
        setFormData(contactFormDataDefaultValues);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <Container className="py-24">
      <div className="flex gap-4 flex-wrap justify-between items-center">
        <div>
          <h6 className="font-semibold text-6xl">Contact Us</h6>
          <CustomButton className="max-w-60 mt-5 p-2.5 font-normal text-xl">
            About Us
          </CustomButton>
        </div>
        <img
          src={contactUs}
          alt="contact-us"
          className="max-h-80 rounded-lg w-full max-w-96 object-cover mix-blend-multiply "
        />
      </div>
      <div className="contact-detail-section grid md:grid-cols-3 justify-center sm:grid-cols-2 grid-cols-1 items-center gap-4 my-20">
        <div className="talk-us bg-pumpkinOrange p-3">
          <h6 className="text-6xl text-white  font-bold">Let's Talk.</h6>
          <p className="text-xl  text-white mt-2">
            Share Your Excitement With US{" "}
          </p>
          <p className="underline text-white font-semibold text-xl mt-2">
            {contactUsEmail}
          </p>
        </div>
        <div className="meet-us mt-3">
          <h6 className="text-6xl font-bold">Meet Us</h6>
          <p className="text-xl mt-2 border-b-2 pb-2 border-gray-300 flex gap-2 items-center">
            {" "}
            <span>
              <FaPhoneAlt className="text-pumpkinOrange" />
            </span>
            {contactUsPhone}
          </p>
          <p className="text-xl mt-2 flex gap-2 items-center">
            {" "}
            <span>
              <FaMapPin className="text-pumpkinOrange" />
            </span>
            {contactUsAddress}
          </p>
        </div>
        <div className="map max-h-52">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1303.3001949007787!2d-76.97370931262587!3d39.020155463279025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c5c90122f239%3A0xfcda845ce90556b6!2sZitro%20Services%20LLC!5e0!3m2!1sen!2s!4v1722436941559!5m2!1sen!2s"
            width="100%"
            height="100%"
            style={{ border: "0px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className="flex justify-center mt-14 request-quote-section">
        <div className="max-w-2xl flex flex-col gap-4 w-full">
          <h6 className="text-4xl text-start font-bold">Contact Us</h6>
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <div className="flex flex-col sm:flex-row gap-3 mt-3">
              <input
                type="text"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                className="border text-sm border-opacity-10 w-full border-black bg-transparent rounded-lg"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={({ target: { name, value } }) =>
                  handleChange(name, value)
                }
                placeholder="Your email Address:"
                className="border text-sm w-full border-opacity-10 border-black bg-transparent rounded-lg"
              />
            </div>

            <textarea
              placeholder="Your message:"
              value={formData.description}
              onChange={({ target: { name, value } }) =>
                handleChange(name, value)
              }
              className="border w-full text-sm border-opacity-10 border-black bg-transparent rounded-lg"
              rows={4}
              name="description"
            />
            <div className="flex justify-center">
              <CustomButton
                type="submit"
                className="rounded-[50px] max-w-48 bg-darkRoyalBlue font-semibold"
              >
                {isLoading ? <Spinner /> : "Submit"}
              </CustomButton>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
