import React, { useState } from "react";
import Card from "../../../../components/Card";
import NoData from "../../../../components/NoData";
import { useGetCompletedJobsQuery } from "../../../../redux/api/Jobs";
import { Spinner } from "flowbite-react";
import { PATH } from "../../../../utils/Path";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../../../components/Pagination";

function CompletedJobs() {
    const navigate=useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const { data:contractorCompletedJobs, isLoading } = useGetCompletedJobsQuery(currentPage,{refetchOnMountOrArgChange:true});
    const handleNavigation = (id, data) => {
        navigate(`${PATH.CONTRACTORJOB}/${id}`, { state: data });
      };
  return (
    <div>
      {isLoading ? (
        <div className="w-full items-center flex justify-center">
          <Spinner />
        </div>
      ):contractorCompletedJobs?.data?.data?.length === 0 ? (
        <NoData message='No Job Found'/> 
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center">
          {contractorCompletedJobs?.data?.data?.map((obj, i) => (
            <div key={i}>
              <Card data={obj} onClick={() => handleNavigation(obj.id, obj)} />
            </div>
          ))}
        </div>
      )}
      {
        contractorCompletedJobs && (contractorCompletedJobs.data.last_page>1? (
          <PaginationComponent currentPage={currentPage} totalPages={contractorCompletedJobs.data.last_page} onPageChange={(page)=>setCurrentPage(page)}/>
        ):null)
      }
    </div>
  );
}

export default CompletedJobs;
