import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import React from "react";
import CreditCard from "../../../assets/icons/creditCard.svg";
import Calendar from "../../../assets/icons/Calendar.svg";
import Cvv from "../../../assets/icons/cvv.svg";
import Button from "../../../components/Button";
import clsx from "clsx";

function Card() {
  const handleSubmit = async (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={handleSubmit}>
      <p className="font-inter font-semibold text-sm">Add Card No</p>
      <div className="relative my-2">
        <CardNumberElement className="w-full border border-grey-900 p-2 rounded-lg bg-whiteSmoke" />
        <img src={CreditCard} className="absolute top-2 right-2" />
      </div>
      <div className="flex w-full gap-4">
        <div className="w-full">
          <p className="font-inter font-semibold text-sm">Add CVC</p>
          <div className="relative my-2">
            <CardCvcElement className=" border border-grey-900 p-2 rounded-lg bg-whiteSmoke" />
            <img src={Cvv} className="absolute top-2 right-2" />
          </div>
        </div>

        <div className="w-full">
          <p className="font-inter font-semibold text-sm">Add Expiry Date</p>
          <div className="relative my-2">
            <CardExpiryElement className=" border border-grey-900 p-2 rounded-lg bg-whiteSmoke" />
            <img src={Calendar} className="absolute top-2 right-2" />
          </div>
        </div>
      </div>
      <Button type="submit" className="mt-2" >Next</Button>
    </form>
  );
}

export default Card;
