import React from "react";
import DetailHeading from "../../../components/DetailHeading";
import CustomInput from "../../../components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import { useAddLicenceMutation } from "../../../redux/api/licence";

const validationSchema = Yup.object({
  licenceNumber: Yup.number("Must be number").positive("Must be positive value").required("licence number is required"),
  state: Yup.string().required('State is required'),
  startDate: Yup.string().required('Start Date is required'),
  expiryDate: Yup.string().required('End Date is required'),
});

function AddLicense({setShowAddLicence}) {
const [addLicense,{isLoading}]=useAddLicenceMutation()
  const {handleSubmit,values,errors,handleChange} = useFormik({
    initialValues: {
      licenceNumber: "",
      state:'',
      startDate:'',
      expiryDate:''
    },
    validationSchema,
    onSubmit: async({licenceNumber,state,startDate,expiryDate}) => {
      try {
       await addLicense({
        licence_no:licenceNumber,
        licence_state:state,
       start_date:startDate,
       end_date:expiryDate
        }
       ).unwrap();
       setShowAddLicence(false)
      } catch (error) {
        console.log(error)
      }
    },
  });

  return (
    <>
      <DetailHeading color="#161140">Add License</DetailHeading>
      <form onSubmit={handleSubmit} className="mt-3">
        <CustomInput
          heading="License Number"
          type="number"
          htmlFor="licenceNumber"
          placeholder="Enter license number"
          onChange={handleChange}
          value={values.licenceNumber}
          error={errors.licenceNumber}
        />
          <CustomInput
          heading="State"
          type="text"
          htmlFor="state"
          placeholder="Select state"
          onChange={handleChange}
          value={values.state}
          error={errors.state}
        />
         <CustomInput
          heading="License Start Data"
          type="date"
          value={values.startDate}
          onChange={handleChange}
          htmlFor="startDate"
          error={errors.startDate}
        />

        <CustomInput
          heading="License Expiry Data"
          type="date"
          value={values.expiryDate}
          onChange={handleChange}
          htmlFor="expiryDate"
          error={errors.expiryDate}
        />
        <Button type="submit" className="mt-2" disabled={isLoading}>{
            isLoading? 'Add...':'Add'
        }</Button>
      </form>
    </>
  );
}

export default AddLicense;