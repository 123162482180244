import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { authApi } from "./api/Auth";
import { completeProfileApi } from "./api/completeProfile";
import { servicesApi } from "./api/services";
import { subscriptionApi } from "./api/subscription";
import { jobsApi } from "./api/Jobs";
import { proposalApi } from "./api/Proposal";
import { chatApi } from "./api/chat";
import { profileApi } from "./api/profile";
import { licenceApi } from "./api/licence";
import { contractorApi } from "./api/contractor";
import { reviewApi } from "./api/Review";
import currentUserReducer from "./slices/currentUser";
import completeProfileReducer from "./slices/Profile";
import jobSlice from "./slices/job";
import notificationsSlice from "./slices/notification";
import { privacyPolicyApi } from "./api/privacy";
import { termsConditionApi } from "./api/TermsCondition";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [completeProfileApi.reducerPath]: completeProfileApi.reducer,
    [servicesApi.reducerPath]: servicesApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [proposalApi.reducerPath]: proposalApi.reducer,
    [reviewApi.reducerPath]: reviewApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [licenceApi.reducerPath]: licenceApi.reducer,
    [contractorApi.reducerPath]: contractorApi.reducer,
    [privacyPolicyApi.reducerPath]: privacyPolicyApi.reducer,
    [termsConditionApi.reducerPath]: termsConditionApi.reducer,
    completeProfile: completeProfileReducer,
    currentUser: currentUserReducer,
    job: jobSlice,
    notifications: notificationsSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      completeProfileApi.middleware,
      servicesApi.middleware,
      subscriptionApi.middleware,
      jobsApi.middleware,
      proposalApi.middleware,
      reviewApi.middleware,
      chatApi.middleware,
      profileApi.middleware,
      licenceApi.middleware,
      contractorApi.middleware,
      privacyPolicyApi.middleware,
      termsConditionApi.middleware
    ),
});

setupListeners(store.dispatch);
