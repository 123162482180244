import React, { useEffect } from "react";
// import Logo from "../../assets/icons/Framelogo.svg";
import Logo from "../../assets/images/logo.png";
import { getCurrentUser, userRole } from "../../utils/currentUser";
import { useNavigate } from "react-router-dom";

function AuthLayout({ children }) {
  const isAuth = getCurrentUser();
  const navigate = useNavigate();
  const role = userRole();
  useEffect(()=>{
    if(isAuth){
      navigate(`/${role}/dashboard`)
    }
  },[isAuth])
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 ">
      <div className="h-full sm:flex justify-center items-center bg-haiti auth-logo hidden">
        <div className="shadow-md p-4 bg-white rounded-md">
          <img src={Logo} width={100} alt="logo" />
        </div>
      </div>
      <div className="flex justify-center py-2 sm:py-0">
        <div className="max-w-[500px] w-full">{children}</div>
      </div>
    </div>
  );
}

export default AuthLayout;
