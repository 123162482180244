import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const reviewApi = createApi({
  reducerPath: "reviewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    contractorReview: builder.mutation({
      query: (data) => ({
        url: "/contractor/review",
        method: "POST",
        body: data,
      }),
    }),

    userReview: builder.mutation({
      query: (data) => ({
        url: "/user/review",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useContractorReviewMutation, useUserReviewMutation } = reviewApi;
