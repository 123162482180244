import clsx from 'clsx'
import React from 'react'
import { img_base_url } from '../utils'

const ProfileUploader = ({ image, isError, name = 'profile', handleChange }) => {
    return (
        <div className="flex justify-center">
            <label htmlFor={name}>
                <img alt="User Pic" src={image ? typeof image === 'string' ? img_base_url + image : URL.createObjectURL(image) : "https://d30y9cdsu7xlg0.cloudfront.net/png/138926-200.png"} height="160" className={clsx('rounded-full size-28 cursor-pointer object-cover', isError && 'border border-rose-700')} />
            </label>
            <input id={name} className="hidden" accept=".png, .jpg, .jpeg" type="file" onChange={(event) =>
                handleChange(event.target.files[0])
            } />

        </div>
    )
}

export default ProfileUploader