import React from "react";
import { Stepper } from "react-form-stepper";

function StepperComponent({currentStep}) {
  return (
    <Stepper
      steps={[{ label: "Step 1" }, { label: "Step 2" }, { label: "Step 3" }, {label:"Step 4"}]}
      activeStep={currentStep}
      styleConfig={{
        activeBgColor:"#161140",
        completedBgColor:"#161140"
      }}
    />
  );
}

export default StepperComponent;
