import React, { useEffect } from "react";
import { useFindContractorsMutation } from "../../../redux/api/contractor";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import { Avatar } from "flowbite-react";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { setContractorData } from "../../../redux/slices/job";
import NoData from "../../../components/NoData";

const FindContractors = ({setOpen,setShowContractors}) => {
  const dispatch=useDispatch();
  const {jobData:{name:jobName},jobCategoryData:{id}}=useSelector(state=>state.job);
  const [findContractors, { data: contractorsData, isLoading, isError }] =
    useFindContractorsMutation();

  const findContractorsHandler = async () => {
    try {
      await findContractors({ service_id:id });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    findContractorsHandler();
  }, []);

  if(!contractorsData&&isLoading){
    return <div className="h-[40vh] flex justify-center items-center">
      <Loader/>
    </div>
  }
  if(isError){
    return <Error/>
  }
  return (
    <div className="mt-6">
      {contractorsData?.data?.length>0?contractorsData?.data?.map((contractor) => {
        const {name,image,id}=contractor;
        return (
          <div className="flex gap-2 cursor-pointer shadow-md mt-4 rounded-md" key={id} onClick={()=>{
            dispatch(setContractorData(contractor));
            setShowContractors(false);
            setOpen(true);
          }}>
            <div className=" w-12 h-12 ">
            <Avatar alt={name} src={`${process.env.REACT_APP_BASE_URL}/${
                  image
                }`} rounded />
            </div>
            <div>
              <p className="font-inter font-semibold text-base">{name}</p>
              <div className="flex gap-4 ">
                <p className="font-inter font-semibold text-xs text-[#2670FF]">
                 {jobName}
                </p>
  
                {/* <div className="flex text-[#2670FF] text-xs ">
                <IoLocationSharp className="my-auto" />
                <p className="font-inter font-semibold ">41 St 2 New Park</p>
              </div> */}
              </div>
            </div>
          </div>
        )
      }):<div className="flex flex-col justify-center mt-2">
      <NoData message='Contractor not available for this service'/>
      <CustomButton className='w-auto' onClick={()=>{
       setShowContractors(false);
       setOpen(true);
       dispatch(setContractorData({id:''}));
    }}>Add Job</CustomButton>
      </div>}
    </div>
  );
};

export default FindContractors;
