
import { twMerge } from 'tw-merge';
import no_data_img from '../assets/images/nodata.svg';
import clsx from 'clsx';

const NoData = ({ message,className }) => {
    return (
        <div className={twMerge(clsx('flex justify-center items-center flex-col h-[60vh]',className&&className))}>
            <img className='rounded-lg max-w-48' src={no_data_img} alt="no-data" />
            {
                message && (
                    <h6 className='mt-2 font-medium'>{message}</h6>
                )
            }
        </div>
    )
}

export default NoData