import React, { useEffect, useState } from "react";
import DetailHeading from "../../../components/DetailHeading";
import { useDispatch, useSelector } from "react-redux";
import { addPlanType } from "../../../redux/slices/Profile";
import { useGetSubscriptionQuery } from "../../../redux/api/subscription";
import { useCompleteProfileMutation } from "../../../redux/api/completeProfile";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/Modal";
import { Spinner } from "flowbite-react";

import Done from "../../../assets/icons/Done.svg";
import { PATH } from "../../../utils/Path";
import CustomButton from "../../../components/CustomButton";

const PLANTYPE = {
  free: "free",
  monthly: "monthly",
};

function Bank({ finish, step }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, error, isLoading } = useGetSubscriptionQuery();
  const [completeProfile, result] = useCompleteProfileMutation();
  // const details = useSelector((state) => state.completeProfile);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePlan = (plan) => {
    setSelectedPlan(plan);
  };
  const handleNext = async () => {
    // dispatch(addPlanType(selectedPlan));
    // finish(selectedPlan);
    setLoading(true);

    try {
      const { data, message, status } = await completeProfile({
        plan_id: selectedPlan.id,
        plan_type: selectedPlan.type,
        personal_information: 0,
        subscription: 1,
        services: 0,
        card: 0,
      }).unwrap();
      // dispatch(addPersonalDetail(values));
      if (status === 200) {
        setOpen(true);
      }
      toast.success(message);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setSelectedPlan(data.data[0]);
    }
  }, [data]);
  return (
    <>
      <CustomModal open={open} closeActionHandler={() => navigate(PATH.CONTRACTORHOME)}>
        <div className="flex flex-col items-center">
          <img src={Done} alt="Added" className="w-20" />
          <p className="font-inter font-extrabold my-3" >Profile Completed</p>
          <CustomButton onClick={() => navigate(PATH.CONTRACTORHOME)}>Next</CustomButton>
        </div>
      </CustomModal>
      <DetailHeading color="#161140">Select a Subscription Plan</DetailHeading>
      {isLoading && (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      )}
      <div className="flex flex-col gap-4 cursor-pointer ">
        {data?.data.map((obj) => (
          <>
            <div
              key={obj.id}
              className={`flex  w-full border  p-1 rounded-lg  bg-whiteSmoke ${
                selectedPlan?.type === obj.type
                  ? "border-black"
                  : "border-grey-900"
              }`}
              onClick={() => handlePlan(obj)}
            >
              <div className=" w-36 bg-slate-400 border border-slate-50  flex justify-center items-center rounded-r-lg">
                <p className=" font-inter text-haiti">{obj.price}$</p>
              </div>
              <div className="bg-[#F5F5F5] flex justify-center items-center flex-col">
                <p className="font-inter text-haiti ">{obj.title}</p>
                <p className="text-xs p-2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </>
        ))}
        {/* Free Plan */}
        {/* <div
          className={`flex  w-full border  p-1 rounded-lg  bg-whiteSmoke ${
            selectedPlan.type === PLANTYPE.free
              ? "border-black"
              : "border-grey-900"
          }`}
          onClick={() => handlePlan(PLANTYPE.free)}
        >
          <div className=" w-36 bg-slate-400 border border-slate-50  flex justify-center items-center rounded-r-lg">
            <p className=" font-inter text-haiti">FREE</p>
          </div>
          <div className="bg-[#F5F5F5] flex justify-center items-center flex-col">
            <p className="font-inter text-haiti ">Free Plan</p>
            <p className="text-xs p-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div> */}

        {/* Paid Plan */}
        {/* <div
          className={`flex  w-full border  p-1 rounded-lg  bg-whiteSmoke ${
            selectedPlan.type === PLANTYPE.monthly
              ? "border-black"
              : "border-grey-900"
          }`}
          onClick={() => handlePlan(PLANTYPE.monthly)}
        >
          <div className=" w-36  bg-slate-400 border border-slate-50  flex justify-center items-center rounded-r-lg">
            <p className=" font-inter text-haiti">30$</p>
          </div>
          <div className="bg-[#F5F5F5] flex justify-center items-center flex-col">
            <p className="font-inter text-haiti">Premiun Subscription</p>
            <p className="text-xs p-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div> */}
      </div>
      <button
        className="w-full bg-haiti text-white  font-semibold font-inter rounded-lg text-sm px-5 py-2.5 text-center "
        type="button"
        onClick={handleNext}
      >
        {/* {step === 2 ? "Finish" : "Next"} */}
        {loading ? "Loading..." : "Finish"}
      </button>
    </>
  );
}

export default Bank;
