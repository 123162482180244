import { Spinner } from 'flowbite-react'
import React from 'react'

const Loader = () => {
    return (
        <div className="w-full items-center flex justify-center my-3">
            <Spinner />
        </div>
    )
}

export default Loader