import React from "react";
import zitro_mobile from "../../../../../assets/images/zitro_mobile.png";
import zitro_mobile2 from "../../../../../assets/images/zitro_mobile_2.png";
import clsx from "clsx";

const AppImg = ({ zitro, animateClass="fade-right" }) => {
  return (
    <div
      className={clsx(
        "col-span-2 sm:flex justify-center",
        zitro && "order-1 md:order-2"
      )}
      data-aos={animateClass}
    >
      <img src={zitro ? zitro_mobile2 : zitro_mobile} alt="zitro-app" />
    </div>
  );
};

export default AppImg;
