import React from "react";
import { getCurrentUser, userRole } from "../../utils/currentUser";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../../components/HomeNavbar";
import Footer from "./components/Footer";

const HomeLayout = ({ children }) => {
  const isAuth = getCurrentUser();
  const navigate = useNavigate();
  const role = userRole();
  useEffect(() => {
    if (isAuth) {
      navigate(`/${role}/dashboard`);
    }
  }, [isAuth]);

  return (
    <div>
      <div className="fixed w-full z-20">
        <Navbar />
      </div>
      {children}
      <Footer/>
    </div>
  );
};

export default HomeLayout;
