import React from "react";
import logo from "../../../../assets/images/logo.png";
import fb from "../../../../assets/icons/fb.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";
import pinterest from "../../../../assets/icons/pinterest.svg";
import phone from "../../../../assets/icons/call.svg";
import email from "../../../../assets/icons/email.svg";
import home from "../../../../assets/icons/home.svg";
import Copyright from "../Copyright";
import Container from "../../../../components/Container";
import {
  contactUsAddress,
  contactUsEmail,
  contactUsPhone,
} from "../../../contact-us";
import { PATH } from "../../../../utils/Path";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";

const otherLinks = [
  { title: "About Us", link: PATH.ABOUTUS },
  { title: "Privacy Policy", link: PATH.PRIVACYPOLICY },
  { title: "Register", link: PATH.SIGNUP },
  { title: "Login", link: PATH.LOGIN },
  { title: "Contact us", link: PATH.CONTACTUS },
];

const contactUs = [
  {
    icon: phone,
    text: contactUsPhone,
  },
  {
    icon: email,
    text: contactUsEmail,
  },
  {
    icon: home,
    text: contactUsAddress,
  },
];

const Footer = () => {
  return (
    <div className="bg-eclipseBlue pt-14 pb-6" data-aos="fade-up">
      <div className="container mx-auto px-4 pb-6 border-b border-black border-opacity-20">
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          <div className="col-span-1">
            <img className="w-[100px]" src={logo} alt="logo" />
            <p className="mt-3 text-black text-opacity-60">
              Join our newsletter to stay up to date on features and releases.
            </p>
          </div>
          <div className="col-span-1">
            <p className="text-[20px] font-syne font-semibold">Other Links</p>
            <ul className="mt-3 flex flex-col gap-2">
              {otherLinks.map(({title,link}, i) => (
                <Link to={link} key={i} className="hover:underline decoration-slate-400">
                <li className="text-black text-opacity-60">
                  {title}
                </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="col-span-1">
            <p className="text-[20px] font-syne font-semibold">Contact Us</p>
            <ul className="mt-3 flex flex-col gap-3">
              {contactUs.map(({ icon, text }, i) => (
                <li
                  key={i}
                  className="text-black items-start text-opacity-60 flex gap-2"
                >
                  <img src={icon} alt={text} />
                  <p> {text}</p>
                </li>
              ))}
            </ul>
            <div className="mt-5">
              <p className="text-[20px] font-syne font-semibold">Follow US</p>
              <ul className="mt-3 flex gap-2">
                {[fb, twitter, linkedin, pinterest].map((icon, i) => (
                  <li key={i} className="text-black text-opacity-60 ">
                    <img src={icon} alt="social" />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-span-1">
            <ContactUs/>
          </div>
        </div>
      </div>
      <Container>
        <Copyright />
      </Container>
    </div>
  );
};

export default Footer;
