import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput";
import SelectInput from "../../../components/SelectInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Map from "../../../components/Map";
import Button from "../../../components/Button";
import { getCurrentAddress } from "../../../utils/Location";
import ImageUploader from "../../../components/ImageUploader";
import { jobsApi } from "../../../redux/api/Jobs";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getCurrentUser } from "../../../utils/currentUser";
import { Datepicker } from "flowbite-react";
import moment from "moment";
import ErrorMessage from "../../../components/ErrorMessage";
import { resetJobData } from "../../../redux/slices/job";
import { useSocket } from "../../../utils/socket";
import { getFormattedDate } from "../../../utils/DateAndTime";

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  category: Yup.string().required("category is required"),
  sub_category: Yup.string().required("Sub category is required"),
  description: Yup.string().required("decription is required"),
  images: Yup.array().min(1, "Select At-least one image").required(),
  date: Yup.string().required("Date is required"),
  start_time: Yup.string().required("Start Time is required"),
  address_detail: Yup.string().required("Address detail is required"),
  end_time: Yup.string().required("End Time is required"),
});

function CreateJobForm({ services, setOpen }) {
  const [loading, setLoading] = useState(false);
  const socket = useSocket();

  const [categories, setCategories] = useState([]);
  const {
    jobData: { id: categoryId },
    jobCategoryData: { id: subCategoryId },
    contractorData: { id: contractorId },
  } = useSelector((state) => state.job);

  const dispatch = useDispatch();
  const user = getCurrentUser();

  const { touched, handleChange, values, errors, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        title: "",
        category: "",
        sub_category: "",
        description: "",
        location: {
          lat: 0,
          lng: 0,
        },
        address: "",
        address_detail: "",
        images: [],
        date: new Date(),
        start_time: "",
        end_time: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        setLoading(true);
        const {
          category,
          title,
          description,
          date,
          start_time,
          end_time,
          location,
          address,
          images,
          address_detail,
        } = values;
        let formData = new FormData();

        formData.append("service_id", parseInt(category));
        formData.append("title", title);
        formData.append("description", description);
        formData.append("date", moment(date).format("YYYY-MM-DD"));
        formData.append("start_time", start_time);
        formData.append("end_time", end_time);
        formData.append("latitude", location.lat);
        formData.append("longitude", location.lng);
        formData.append("address_detail", address_detail);
        formData.append("address", address);
        if (contractorId) {
          formData.append("contractor_id", contractorId);
        }

        images.forEach((image, i) => {
          formData.append(`job_media[${i}]`, image);
        });

        // make post request
        try {
          let data;
          if (contractorId) {
            data = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/user/create_job_invite`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${user?.access_token}`,
                },
              }
            );
            const {
              data: { data: inviteJobNotification },
            } = data;

            socket.emit("notification_send", inviteJobNotification);
          } else {
            data = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/user/create_job`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${user?.access_token}`,
                },
              }
            );
          }

          const {
            data: { status, message },
          } = data;

          if (status === 200) {
            setOpen();
            dispatch(jobsApi.util.invalidateTags(["jobs"]));
            dispatch(resetJobData());
          } else {
            throw new Error(message);
          }
          toast.success(message);
          setLoading(false);
        } catch (err) {
          toast.error(err.message);
          setLoading(false);
        }
      },
    });

  const handleImageChange = (e) => {
    if (e.target.files) {
      setFieldValue("images", [
        ...values.images,
        ...Array.from(e.target.files),
      ]);
    }
  };

  const handleLocation = (location) => {
    setFieldValue("location", location);
  };

  const handleGetAddress = (addr) => {
    setFieldValue("address", addr);
  };

  const handleCategoryChanged = (e) => {
    setFieldValue("sub_category", "");
    let temp = services.find((item) => {
      return item.id == e.target.value;
    });
    setCategories(temp?.categories);
    setFieldValue("category", e.target.value);
  };

  const customDateTheme = {
    popup: {
      root: {
        base: "absolute bottom-10 z-50 block pb-2",
      },
    },
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      setFieldValue("location", {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      const addr = await getCurrentAddress(
        pos.coords.latitude,
        pos.coords.longitude
      );
      setFieldValue("address", addr);
    });
  }, []);

  const handleDeleteImage = (id) => {
    const images = values.images.filter((_, i) => i !== id);
    setFieldValue("images", images);
  };

  useEffect(() => {
    if (subCategoryId) {
      handleCategoryChanged({ target: { value: categoryId } });
      setFieldValue("sub_category", subCategoryId);
    }
  }, []);

  const currentTime = new Date();
  const currentHour = String(currentTime.getHours()).padStart(2, "0");
  const currentMinute = String(currentTime.getMinutes()).padStart(2, "0");

  const endHour = "23";
  const endMinute = "59";

  const minTime = `${currentHour}:${currentMinute}`;
  const maxTime = `${endHour}:${endMinute}`;

  const todayDate = getFormattedDate(new Date());
  const jobSelectedDate = getFormattedDate(values.date);

  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const date = new Date().getDate();

  return (
    <form onSubmit={handleSubmit}>
      
      <div>
          <CustomInput
            heading="Job Title"
            type="text"
            placeholder="Enter job title"
            value={values.title}
            onChange={handleChange}
            htmlFor="title"
            error={touched.title && errors.title}
          />
        </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div>
          <SelectInput
            heading="Category"
            options={services}
            htmlFor="category"
            disabled={categoryId}
            onChange={handleCategoryChanged}
            value={values.category}
            error={touched.category && errors.category}
          />
        </div>

        <div>
          <SelectInput
            heading="Sub Category"
            options={categories}
            htmlFor="sub_category"
            disabled={subCategoryId}
            onChange={handleChange}
            value={values.sub_category}
            error={touched.sub_category && errors.sub_category}
          />
        </div>
        <div>
          <div>
            <CustomInput
              heading="Description"
              type="textarea"
              placeholder="Enter job description"
              value={values.description}
              onChange={handleChange}
              htmlFor="description"
              error={touched.description && errors.description}
            />
          </div>
          <div>
            <p className="text-haiti font-inter font-semibold mb-2">
              Upload Images
            </p>
            <ImageUploader
              images={values.images}
              handleImageChange={handleImageChange}
              handleDeleteImage={handleDeleteImage}
            />
            {touched.images && (
              <p className="text-red-600 text-xs font-inter mt-2">
                {errors.images}
              </p>
            )}
          </div>
        </div>
        <div>
          <p className="block mb-2 text-base font-medium font-inter">
            Location
          </p>
          <Map
            job
            coordinates={values.location}
            handleLocation={handleLocation}
            handleGetAddress={handleGetAddress}
          >
            <div className="my-2">
              <CustomInput
                heading="Location Details"
                type="text"
                placeholder="Suit Number, Appartment"
                value={values.address_detail}
                onChange={handleChange}
                htmlFor="address_detail"
                error={touched.address_detail && errors.address_detail}
              />
            </div>
          </Map>
        </div>
        <div>
        <label htmlFor="date" className="font-medium">
          Date
        </label>
        <div className="mt-2">
          <Datepicker
            format="mm/dd/yyyy"
            id="date"
            // value={values.date}
            minDate={new Date(year, month, date)}
            theme={customDateTheme}
            onSelectedDateChanged={(date) => setFieldValue("date", date)}
          />
        </div>
        <div className="mt-2">
          {errors.date && touched.date && <ErrorMessage error={errors.date} />}
        </div>
      </div>
      <div className="flex w-full justify-between gap-2">
        <CustomInput
          heading="Start Time"
          type="time"
          minTime={todayDate === jobSelectedDate ? minTime : undefined}
          value={values.start_time}
          onChange={handleChange}
          htmlFor="start_time"
          error={touched.start_time && errors.start_time}
          className="w-full "
        />

        <CustomInput
          heading="End Time"
          type="time"
          minTime={
            values.start_time
              ? moment(values.start_time, "HH:mm")
                  .add(2, "minutes")
                  .format("HH:mm")
              : minTime
          }
          maxTime={maxTime}
          value={values.e}
          onChange={handleChange}
          htmlFor="end_time"
          error={touched.end_time && errors.end_time}
          className="w-full"
        />
      </div>
      </div>

      
      {/* <ErrorMessage name="date"/> */}
      {/* <CustomInput
        heading="Date"
        type="date"
        value={values.date}
        onChange={handleChange}
        htmlFor="date"
        error={touched.date && errors.date}
      /> */}
     
      <div>
        <Button type="submit">{loading ? "Creating..." : "Create Job"}</Button>
      </div>
    </form>
  );
}

export default CreateJobForm;
