import React, { useState } from "react";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import CustomModal from "../../../components/Modal";
import AddLicence from "./AddLicence";
import Button from "../../../components/Button";
import { useGetLicencesQuery } from "../../../redux/api/licence";

const ContractorLicences = () => {
  const [selectedServiceCategories, setSelectedServiceCategories] =
    useState("");
  const [openCategories, setOpenCategories] = useState(false);
  const [showAddLicence, setShowAddLicence] = useState(false);

  const {
    data: userServices,
    isLoading,
    isError,
    error,
  } = useGetLicencesQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  const handleSubCategory = (data) => {
    setOpenCategories(true);
    setSelectedServiceCategories(data);
  };

  const handleSelectedCategory = (data) => {
    console.log(data);
    setOpenCategories(false);
  };
  return (
    <>
    
      <CustomModal
        open={showAddLicence}
        setOpen={() => setShowAddLicence((prev) => !prev)}
      >
        <AddLicence setShowAddLicence={setShowAddLicence} />
      </CustomModal>
      <div className="flex mx-6 flex-col items-center justify-center py-2">
        <div className="w-full">
          <div className="flex justify-end">
            <Button
              className="mt-2 w-auto"
              onClick={() => setShowAddLicence(true)}
            >
              Add Licence
            </Button>
          </div>
          <div className=" mt-6 mr-3 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center gap-4 w-full ">
            {userServices &&
              userServices.data.map(
                ({ id,licence_no, state, start_date, end_date }) => {
                  return (
                    <div
                      className="flex gap-3 shadow shadow-slate-300 p-3 rounded-lg w-full cursor-pointer bg-white max-w-md "
                      key={id}
                    >
                      {/* <Avatar img={image ? img_base_url + image : null} className='size-14 rounded-lg' alt={name} /> */}
                      <div className="w-full ">
                        <div className="flex justify-between border-b border-slate-400">
                        <p className="capitalize mt-1">License Number</p>
                        <p className="font-medium capitalize mt-1">{licence_no}</p>
                        </div>
                        <div className="flex justify-between border-b border-slate-400">
                        <p className="capitalize mt-1">License State</p>
                        <p className="font-medium capitalize mt-1">{state}</p>
                        </div>
                        <div className="flex justify-between border-b border-slate-400">
                        <p className="capitalize mt-1">Start Date</p>
                        <p className="font-medium capitalize mt-1">{start_date}</p>
                        </div>
                        <div className="flex justify-between">
                        <p className="capitalize mt-1">Expiry Date</p>
                        <p className="font-medium capitalize mt-1">{end_date}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorLicences;
