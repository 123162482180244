import React from "react";
import correct from "../../../assets/icons/correct.svg";
import { Avatar } from "flowbite-react";
import moment from 'moment'
import { useSearchParams } from "react-router-dom";

function ChatPerson(props) {

  const { onClick,className,...profileChatData } = props;
  const { image, name, selected, last_message, updated_at } = profileChatData
  return (
    <div className="flex flex-row py-4 px-2 justify-between items-center border-b-2 mx-4 cursor-pointer" onClick={() => onClick(profileChatData)}>
      <div className="flex gap-4 ">
        <div className="relative" >
          <Avatar alt="User settings" img={image} className={className} rounded />
          {selected && (
            <div className="overlay absolute inset-0 flex items-center justify-center ">
              <div className="bg-[#005856] opacity-60 h-full w-full absolute rounded-full"></div>
              <img src={correct} alt="correct" className="z-10" />
            </div>
          )}
          <p className="w-2 h-2 rounded-full bg-[#8FFF00] left-7 top-8 absolute "></p>
        </div>
        <div>
          <p className="text-base font-semibold">{name}</p>
          <span className="text-gray-500 text-xs">{last_message}</span>
        </div>
      </div>
      <div>
        <span className="text-xs text-[#AD824B]">{moment(updated_at).fromNow()}</span>
      </div>
    </div>
  );
}

export default ChatPerson;
