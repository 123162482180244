import React from "react";
import { useGetContractorReviewsQuery } from "../../../redux/api/profile";
import { Rating } from "react-simple-star-rating";
import { img_base_url } from "../../../utils";
import { Avatar } from "flowbite-react";
import Loader from "../../../components/Loader";
import NoData from "../../../components/NoData";

const ContractorReviews = () => {
  const { data: contractorReviewsData, isLoading } =
    useGetContractorReviewsQuery();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className="flex flex-col items-center justify-center mx-10">
      <div className="mt-4 w-full">
        {contractorReviewsData?.data?.reviews?.length < 1 ? (
          <NoData message="No Reviews Available" />
        ) : (
          <>
            <p className="font-medium text-center text-lg text-haiti">
              All Reviews
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center mt-3 gap-4 w-full">
              {contractorReviewsData?.data?.reviews?.map(
                ({ user, user_rating, job, id, user_feedback }) => {
                  return (
                    <div
                      key={id}
                      className="shadow shadow-slate-300 p-3 rounded-lg w-full bg-white"
                    >
                      <div className="flex gap-3 justify-between items-center w-full">
                        <div className="flex justify-between items-center gap-3">
                          <Avatar
                            img={user.image ? img_base_url + user.image : null}
                            className="size-10 rounded-lg"
                            alt={user.name}
                          />
                          <p className="font-medium capitalize text-haiti">
                            {user.name}
                          </p>
                        </div>
                        <Rating
                          size={20}
                          disableFillHover={true}
                          onClick={() => {}}
                          allowHover={false}
                          initialValue={user_rating}
                          SVGstyle={{ display: "inline" }}
                          readonly={true}
                        />
                      </div>
                      <div className="flex justify-between w-full mt-4">
                        <p className="font-medium text-haiti">
                          ${job.price_total}/hr
                        </p>
                        <p className="font-medium capitalize text-slate-300">
                          {job.offer_type}
                        </p>
                      </div>
                      <p className="font-inter mt-2">{user_feedback}</p>
                    </div>
                  );
                }
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContractorReviews;
