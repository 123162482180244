import React from "react";
import { Avatar } from "flowbite-react";
import { IoIosTime } from "react-icons/io";
import CarouselComponent from "../../../../components/Carousel";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { jobsApi } from "../../../../redux/api/Jobs";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAcceptProposalMutation, useDeclineProposalMutation } from "../../../../redux/api/Proposal";
import { PATH } from "../../../../utils/Path";
import { useSocket } from "../../../../utils/socket";

function ProposalDetail({ data }) {
  const dispatch = useDispatch();
  const [declineProposal,{isLoading:isProposalDeclining}]=useDeclineProposalMutation();
  const socket = useSocket();

  const navigate = useNavigate();
  const [acceptProposal, { isLoading: loading }] = useAcceptProposalMutation();

  const {
    id,
    contractors,
    description,
    offer_type,
    offer_price,
    status,
    estimated_hours,
  } = data;

  const handleAcceptProposal = async () => {
    try {
      const {data, message } = await acceptProposal({
        proposal_id: id,
      }).unwrap();
      socket.emit("notification_send", data);
      dispatch(jobsApi.util.invalidateTags(["jobs"]));
      dispatch(jobsApi.util.invalidateTags(["active jobs"]));

      toast.success(message);
      navigate(PATH.USERJOB);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const declineProposalHandler=async()=>{
    try {
      const {data, message } = await declineProposal({
        proposal_id: id,
      }).unwrap();
      socket.emit("notification_send", data);
      dispatch(jobsApi.util.invalidateTags(["jobs"]));
      dispatch(jobsApi.util.invalidateTags(["active jobs"]));
      navigate(PATH.USERJOB);
      toast.success(message);
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <>
      <div className="bg-white shadow p-4 w-100 w-full flex flex-col gap-4 cursor-pointer h-fit rounded-md">
        <div className="flex justify-between">
          {/* Header Area */}
          <div className="flex items-center gap-2">
            {/* Image div */}
            <div className=" w-12 h-12 ">
              <Avatar rounded />
            </div>

            {/* Header text Area */}
            <div className="flex gap-4 ">
              <p className="font-inter font-semibold text-lg ">
                {contractors.name}
              </p>
            </div>
          </div>

          <div className="flex gap-3 items-center">
            {status === "pending" && (
              <>
                <CustomButton onClick={declineProposalHandler} disabled={loading} className="text-white flex justify-center items-center bg-red-600 border max-w-20">
                 {isProposalDeclining?'Decline...':' Decline'}
                </CustomButton>
                <CustomButton
                  className="text-center max-w-20"
                  onClick={handleAcceptProposal}
                >
                  {loading ? "hiring..." : "Hire"}
                </CustomButton>
              </>
            )}
            <div>
              <p className="text-xs font-inter">{offer_type}</p>
              <p className="font-bold font-inter">${offer_price}</p>
            </div>
          </div>
        </div>

        <div>
          <div className="flex gap-2">
            <IoIosTime />
            <div>
              <p className="text-xs font-inter">Estimated Time</p>
              <p className="font-semibold font-inter">
                {estimated_hours} hours
              </p>
            </div>
          </div>
        </div>
        {/* body */}
        <div>
          <p className="font-inter font-semibold text-base "> Description</p>
          <p className="font-inter font-normal text-sm line-clamp-2">
            {" "}
            {description}{" "}
          </p>
        </div>
        <div onClick={(event) => event.stopPropagation()}>
          <CarouselComponent media={data?.media || []} />
        </div>
      </div>
      {/* <SingleProposalDetail/> */}
    </>
  );
}

export default ProposalDetail;
