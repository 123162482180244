import React, { createContext, useContext, useMemo } from 'react'
import { Socket, io } from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_URL || 'http://127.0.0.1:4200/';

const SocketContext = createContext(Socket);

const SocketProvider = ({ children }) => {
    const socket = useMemo(() => io(URL), []);
    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    )
}

export default SocketProvider


export const useSocket = () => {
    const socket = useContext(SocketContext);
    return socket
}