export const PATH = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ROOTHOME: "/home",
  VERIFICATION: "/verification",
  FORGOTPASSWORD: "/forgot_password",
  RESETPASSWORD: "/reset_password",
  DASHBOARD: "dashboard",
  JOB: "jobs",
  REVIEWS: "reviews",
  JOBDETAIL: "jobs/:id",
  PROFILE: "profile",
  CHATS: "chats",
  SERVICES: "services",
  LICENCES: "licences",
  PROPOSALS: "/proposals",
  CONTRACTORDETAIL: "/contractor/completeprofile",
  CONTRACTORHOME: "/contractor/dashboard",
  CONTRACTORJOB: "/contractor/jobs",
  CONTRACTORREVIEWS: "/contractor/reviews",
  CONTRACTORJOBDetail: "/contractor/jobs/:id",
  CONTRACTORJOBINVITEDetail: "job/invite/:id",
  CONTRACTORPROFILE: "contractor/profile",
  CONTRACTORCHATS: "/contractor/chats",
  CONTRACTORSERVICES: "/contractor/services",
  CONTRACTORLICENCES: "/contractor/licences",
  USERCHATS: "/user/chats",
  USERHOME: "/user/dashboard",
  USERJOB: "/user/jobs",
  USERREVIEWS: "/user/reviews",
  USERJOBDETAIL: "/user/jobs/:id",
  USERJOBINVITEDETAIL: "job/invite/:id",
  USERPROFILE: "/user/profile",
  USERJOBPROPOSALS: "/user/proposals",
  CONTRACTORPROPOSAL: "/contractor/proposals",
  USERPRIVACYPOLICY: "/user/privacy_policy",
  CONTRACTORPRIVACYPOLICY: "/contractor/privacy_policy",
  USERTERMSCONDITION: "/user/terms_condition",
  CONTRACTORTERMSCONDITION: "/contractor/terms_condition",
  ABOUTUS: "/about-us",
  CONTACTUS: "/contact-us",
  LICENSE: "/license",
  PRIVACYPOLICY:'/privacy-policy',
  TERMSANDCONDITIONS:'/terms-conditions',
  PAGE404:'*'
};
