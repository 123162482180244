import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  phone: "",
  address: "",
  city: "",
  zip: "",
  state: "",
  request_services: [],
  plan_type: "",
  licence_no: "",
  licence_state: "",
  company_name: "",
  license_start_data: "",
  license_expiry_data: "",
};

const completeProfileSlice = createSlice({
  name: "completeProfile",
  initialState,
  reducers: {
    addPersonalDetail: (state, action) => {
      const {
        name,
        contactNumber,
        streetAddress1,
        streetAddress2,
        zip,
        State,
        city,
        license_no,
        license_state,
        company_name,
        license_start_data,
        license_expiry_data,
      } = action.payload;
      state.name = name;
      state.phone = contactNumber;
      state.address = `${streetAddress1} ${streetAddress2}`;
      state.city = city;
      state.zip = zip;
      state.state = State;
      state.licence_no = license_no;
      state.licence_state = license_state;
      state.company_name = company_name;
      state.license_start_data = license_start_data;
      state.license_expiry_data = license_expiry_data;
    },

    addServices: (state, action) => {
      let newServices = [];
      action.payload.services.forEach((obj) => {
        newServices.push({
          service_id: parseInt(obj.service) + 1,
          rate_hourly: obj.hourlyRate,
        });
      });
      return {
        ...state,
        request_services: newServices,
      };

    },

    addPlanType: (state, action) => {
      state.plan_type = action.payload;
    },
  },
});

export default completeProfileSlice.reducer;
export const { addPersonalDetail, addServices, addPlanType } =
  completeProfileSlice.actions;
