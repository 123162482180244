import React, { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetUserJobproposalMutation,
} from "../../../../redux/api/Proposal";
import ProposalDetail from "./ProposalDetail";
import { Spinner } from "flowbite-react";
import NoData from "../../../../components/NoData";

function JobProposals() {
  const [proposals, setJobProposals] = useState([]);
  const { id } = useParams();
  const [jobProposals, { isLoading }] = useGetUserJobproposalMutation();

  const getJobProposals = useCallback(async () => {
    const { data } = await jobProposals({
      job_id: id,
    }).unwrap();
    setJobProposals(data);
  }, [id]);

  useEffect(() => {
    getJobProposals();
  }, []);

  return (
    <>
      <h1 className="text-center font-inter font-extrabold text-haiti mt-4">
        All Proposals
      </h1>
      {isLoading ? (
        <div className="w-full items-center  mt-5 flex justify-center">
          <Spinner />
        </div>
      ) : proposals?.data?.length === 0 ? (
       
          <NoData message='No Proposals Found' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 overflow-y-auto  justify-center">
          {proposals.data?.map((data, index) => {
            return (
              <div className="flex justify-center" key={index}>
                <ProposalDetail data={data}  />
              </div>
            )
          })}
        </div>
      )}
    </>
  );
}

export default JobProposals;
