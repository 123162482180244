import clsx from "clsx";
import React from "react";
import { twMerge } from "tw-merge";

function Button({ children, type, className, disabled,onClick=()=>{} }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={twMerge(
        clsx(
          "w-full bg-haiti text-white  font-semibold font-inter rounded-lg text-sm px-5 py-2.5 text-center ",
          className && className
        )
      )}
    >
      {children}
    </button>
  );
}

export default Button;
