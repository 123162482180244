// import { Carousel } from 'flowbite-react';
import clsx from "clsx";
import Carousel from "react-multi-carousel";
import { twMerge } from "tw-merge";

function CarouselComponent({ media,className }) {
  const imageUrls = media.filter(({media})=>media!=0).map((obj) => {
    return `${process.env.REACT_APP_BASE_URL.replace(/\/api$/, "")}/${
      obj?.media
    }`;
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
    <Carousel responsive={responsive} itemClass={twMerge(clsx("pr-2",className&&className))}>
      {imageUrls?.map((url, index) => {
        return (
          <img key={index} src={url} alt={`Image ${index}`} className="rounded-md w-[100%] h-[100%] object-cover"/>
          )
        })}
    </Carousel>
        </>
  );
}

export default CarouselComponent;
