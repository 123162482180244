import React from "react";
import CustomButton from "../../../../components/CustomButton";
import hero_img from "../../../../assets/images/hero.png";
import Container from "../../../../components/Container";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate=useNavigate();
  return (
    <div className="bg-eclipseBlue py-6">
      <div
        className="flex pt-[4rem] md:p-0 md:items-center bg-bottom bg-no-repeat min-h-[780px] bg-contain sm:bg-auto sm:bg-right"
        style={{
          backgroundImage: `url(${hero_img})`,
        }}
      >
        <Container>
        <div data-aos="fade-right">
          <h6 className="text-[40px] sm:[60px] md:text-[76px] font-bold text-dune font-syne">
            Find Trusted
            <span className="block">Contractors for</span>
            Your Home Projects
          </h6>
          <p className="text-dune text-lg text-opacity-60">
            Get connected with skilled professionals for any home <br />{" "}
            improvement task
          </p>
          <CustomButton onClick={()=>navigate('/login')} className="rounded-[48px] font-syne mt-4 w-full py-4 max-w-[182px]">
            Get Started
          </CustomButton>
        </div>
        </Container>
      </div>
    </div>
  );
};

export default HeroSection;
