import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CreateChat from "./Components/Create";
import SendMessage from "./Components/SendMessage";
import Persons from "./Components/Persons";
import SingleChat from "./Components/SingleChat";
import CustomModal from "../../components/Modal";
import Loader from "../../components/Loader";
import Error from "../../components/Error";
import { useGetSingleChatMutation } from "../../redux/api/chat";
import pin from "../../assets/icons/pin.svg";
import volume from "../../assets/icons/volume_slash.svg";
import cross from "../../assets/icons/cross.svg";
import trash from "../../assets/icons/trash.svg";
import clsx from "clsx";
import useMediaArea from "../../hooks/useMediaArea";
import { twMerge } from "tw-merge";

function Chats() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewChat, setShowNewChat] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [searchParams] = useSearchParams();
  const [chatId, setChatId] = useState(null);
  const {width}=useMediaArea();
  const [selectedProfileChatData, setSelectedProfileChatData] = useState(null);
  const chatSearchId = searchParams.get("chat");

  useEffect(() => {
    if (chatSearchId) {
      setChatId(chatSearchId);
    }
  }, [chatSearchId]);

  return (
    <>
      <CustomModal
        title="Create Chat"
        open={showNewChat}
        setOpen={setShowNewChat}
        closeModal={() => setShowNewChat(false)}
      >
        <CreateChat />
      </CustomModal>
      <div className={clsx("grid grid-cols-3 gap-4 p-4")}>
        <div className={clsx("rounded-xl h-auto bg-[#fff] border col-span-3 md:col-span-1",(width<769&& chatId) && "hidden")}>
          <div className="flex p-4 text-xl justify-between border-b border-gray-100">
            <p className="font-semibold">Message</p>
          </div>
          {selectedCount > 0 && (
            <div className="flex justify-between border-b border-gray-100 p-4">
              <div className="flex gap-2">
                <img
                  src={cross}
                  alt="close"
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedCount(0);
                  }}
                />
                <p className="text-xl font-bold font-Inter">
                  {selectedCount} Selected
                </p>
              </div>
              tabs
              <div className="flex gap-3 ">
                <img src={pin} alt="pin" className="cursor-pointer" />
                <img src={volume} alt="volume" className="cursor-pointer" />
                <img
                  src={trash}
                  alt="trash"
                  className="cursor-pointer"
                  onClick={() => setShowDeleteModal(true)}
                />
              </div>
            </div>
          )}

          <Persons
            chatId={chatId}
            setChatId={setChatId}
            setSelectedProfileChatData={setSelectedProfileChatData}
          />
        </div>
        {/* single chat screen */}
        {chatId && (
          <div
            className={twMerge(clsx(
              "grid md:col-span-2",
              (width<769&& chatId) && "col-span-3" 
            ))}
          >
            <div className=" rounded-xl h-auto bg-white border ">
              <SingleChat
                chatId={chatId}
                setChatId={setChatId}
                selectedProfileChatData={selectedProfileChatData}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Chats;
