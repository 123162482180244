import floor_and_walls from "../../../../assets/images/Floors and Walls.jpg";
import Metal_Steel_Marble from "../../../../assets/images/Metal-Steel-Marble.jpg";
import Kitchen_Bath from "../../../../assets/images/Kitchen & Bath.jpg";
import Pools_Hot_Tubs from "../../../../assets/images/Pools & Hot Tubs.jpg";
import Special_Trades from "../../../../assets/images/Special Trades.jpg";
import HVAC from "../../../../assets/images/HVAC.jpg";
import Interior_of_Residence from "../../../../assets/images/Interior of Residence.jpg";
import Exterior_of_Residence from "../../../../assets/images/Exterior of Residence.jpg";
import Cleaning_and_Treatments from "../../../../assets/images/Cleaning and Treatments.jpg";
import Doors_and_Windows from "../../../../assets/images/Doors and Windows.jpg";
import Energy_Conservation from "../../../../assets/images/Energy Conservation.jpg";
import Fire from "../../../../assets/images/Fire.jpg";
import Plumbing from "../../../../assets/images/service1.png";
import Commercial from "../../../../assets/images/Commercial.jpg";

export const servicesData = [
  {
    heading: "Floors and Walls",
    subHeading:
      "Flooring and drywall services are fundamental components of any construction or renovation project. Flooring services involve the installation and finishing of various materials like hardwood, tile, or carpet, providing a durable and aesthetically pleasing surface to walk on. Drywall services, on the other hand, entail the creation and finishing of interior walls and ceilings using gypsum boards. These services are crucial because they not only contribute to the structural integrity and insulation of a building but also significantly enhance its visual appeal and functionality",
    img: floor_and_walls,
  },
  {
    heading: "Metal/Steel/Marble",
    subHeading:
      "Metal, steel, and marble are popular materials used in construction projects due to their strength, durability, and aesthetic appeal. Metal and steel are commonly used in the framework of buildings, providing structural support and stability. They also have excellent fire-resistant properties, making them a preferred choice for commercial and industrial buildings. Marble is a luxurious material often used for flooring or decorative elements like countertops or wall cladding. Its natural beauty and versatility make it a popular choice for both residential and commercial projects.",
    img: Metal_Steel_Marble,
  },
  {
    heading: "Kitchen & Bath",
    subHeading:
      "The kitchen and bathroom are two of the most essential areas in any home or building. Kitchen renovations often involve the installation of new countertops, cabinets, and appliances to create a functional and visually appealing space. Bathroom renovations may include updating plumbing fixtures, installing new tiles or flooring, and upgrading vanities and other features. These spaces are crucial as they not only serve practical purposes but also significantly impact the overall value and comfort of a property.",
    img: Kitchen_Bath,
  },
  {
    heading: "Pools & Hot Tubs",
    subHeading:
      "Pools and hot tubs are popular additions to residential properties, providing a source of relaxation, entertainment, and exercise. Pool services include design, construction, installation, and maintenance of various types of pools such as inground or above-ground pools. Hot tub services involve the installation and repair of hot tubs or spas, creating a luxurious backyard oasis for homeowners. These services not only add value to a property but also enhance the overall quality of life for its residents.",
    img: Pools_Hot_Tubs,
  },
  {
    heading: "Special Trades",
    subHeading:
      "Special trades refer to specialized services that are crucial in construction projects, such as electrical, plumbing, and HVAC (heating, ventilation, and air conditioning). These trades require specialized knowledge and skills to ensure the safety and functionality of a building. Electrical services involve the installation and maintenance of electrical systems, including lighting, wiring, and outlets. Plumbing services include installing and repairing water supply lines, drainage systems, and fixtures like sinks and toilets. HVAC services deal with the installation and maintenance of heating and cooling systems to regulate indoor temperature and air quality.",
    img: Special_Trades,
  },
  {
    heading: "HVAC",
    subHeading:
      "HVAC (heating, ventilation, and air conditioning) is a crucial aspect of any building project, whether residential or commercial. Proper heating and cooling systems not only ensure comfort but also contribute to the overall energy efficiency of a property. HVAC services involve the installation, repair, and maintenance of various systems like furnaces, air conditioners, heat pumps, and ductwork. These services are essential in creating a comfortable and healthy indoor environment while also reducing energy costs for the property owner.",
    img: HVAC,
  },
  {
    heading: "Interior of Residence",
    subHeading:
      "The interior of a residence encompasses all the spaces inside a home, including living areas, bedrooms, kitchens, bathrooms, and more. Interior design services involve creating functional and aesthetically pleasing spaces by choosing furniture, decor, color schemes, and lighting that reflect the homeowner's style and preferences. These services not only enhance the visual appeal of a property but also contribute to its overall functionality and comfort.",
    img: Interior_of_Residence,
  },
  {
    heading: "Exterior of Residence",
    subHeading:
      "The exterior of a residence includes all the outdoor spaces around a home, such as the front yard, backyard, patio, and deck. Exterior renovations often involve landscaping services to create beautiful and functional outdoor living spaces. This may include planting trees, flowers, and shrubs, building retaining walls or pathways, and installing outdoor features like fire pits or kitchens. These services not only add curb appeal to a property but also provide additional living space for homeowners to enjoy.",
    img: Exterior_of_Residence,
  },
  {
    heading: "Cleaning and Treatments",
    subHeading:
      "Cleaning and treatments play a crucial role in maintaining the appearance and longevity of a property. These services include pressure washing, window cleaning, carpet cleaning, and pest control. Pressure washing removes dirt, grime, and stains from exterior surfaces like siding or decks. Window cleaning ensures that windows are clear and free of streaks for optimal natural light. Carpet cleaning helps to remove dirt, allergens, and stains from carpets to keep them looking fresh. Pest control involves the removal or prevention of unwanted pests like insects or rodents to protect the property's structural integrity and hygiene.",
    img: Cleaning_and_Treatments,
  },
  {
    heading: "Doors and Windows",
    subHeading:
      "Doors and windows are essential elements of any building, providing access, natural light, and ventilation. Door services include installation, repair, and maintenance of various types of doors like interior doors, exterior doors, French doors, or sliding glass doors. Window services involve installing new windows or repairing existing ones to improve energy efficiency and aesthetics. These services not only contribute to the functionality of a property but also enhance its visual appeal.",
    img: Doors_and_Windows,
  },
  {
    heading: "Energy Conservation",
    subHeading:
      "Energy conservation is becoming increasingly important in the construction industry, as buildings account for a significant portion of energy consumption. Energy-efficient services focus on reducing a property's energy usage and carbon footprint while also saving the homeowner money on utility bills. These services may include installing energy-efficient lighting, insulation, windows, or HVAC systems to improve overall efficiency.",
    img: Energy_Conservation,
  },
  {
    heading: "Fire",
    subHeading:
      "The threat of fire is a concern for both residential and commercial properties, making fire safety services crucial in any construction project. These services involve installing and maintaining fire alarms, sprinkler systems, fire extinguishers, fireplaces, and other features to prevent or contain fires. Fireplaces, while providing warmth and aesthetic value, must be properly installed and regularly inspected to ensure they do not become a fire hazard. Regular maintenance and proper installation of these systems can save lives and protect valuable property.",
    img: Fire,
  },
  {
    heading: "Plumbing",
    subHeading:
      "Plumbing is an essential aspect of any property, providing a source of clean water and removing waste. Plumbing services involve the installation, repair, and maintenance of various plumbing fixtures like sinks, toilets, faucets, and pipes. Properly installed and maintained plumbing systems are crucial in maintaining the health and hygiene of a property's residents.",
    img: Plumbing,
  },
  {
    heading: "Commercial",
    subHeading:
      "Commercial properties, such as office buildings, retail spaces, and industrial complexes, require specialized construction services to meet the unique needs of their tenants. These services may include commercial electrical, HVAC, plumbing, and fire safety services tailored to the specific requirements of a commercial property. In addition to these specialized services, commercial properties may also benefit from general renovation or maintenance services to ensure a safe and comfortable working environment for employees.",
    img: Commercial,
  },
];
