import React, { useState } from "react";
import Date from "../assets/icons/Calendar.svg";
import Time from "../assets/icons/Clock.svg";
import Location from "../assets/icons/Location.svg";
import CarouselComponent from "./Carousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButton from "./CustomButton";
import Modal from "./Modal";
import JobProposal from "../screens/contractor/jobs/Proposal";
import {
  useDeclineJobInviteMutation,
  useJobInviteDetailMutation,
} from "../redux/api/Jobs";
import { useEffect } from "react";
import { useCallback } from "react";
import { Spinner } from "flowbite-react";
import ConfirmJob from "../screens/contractor/jobs/confirmation";
import { PATH } from "../utils/Path";
import NoData from "./NoData";
import { useInitiateChatMutation } from "../redux/api/chat";
import { getFormattedDate, getFormattedTime } from "../utils/DateAndTime";
import { useSocket } from "../utils/socket";

function JobInviteDetail() {
  const [open, setOpen] = useState(false);
  const [openConfirmJob, setOpenConfirmJob] = useState(false);
  const socket = useSocket();
  const [jobDetails, setJobDetails] = useState([]);
  const { id } = useParams();
  const [getSingleJob, { isLoading }] = useJobInviteDetailMutation();
  const [declineJobInvite, { isLoading: isDecliningJob }] =
    useDeclineJobInviteMutation();
  const [initiateChat, { isLoading: isChatLoading }] =
    useInitiateChatMutation();
  const navigate = useNavigate();

  const location = useLocation();

  const {
    title = "",
    services = {},
    description = "",
    address = "",
    date = "",
    start_time = "",
    end_time = "",
    job_status = "",
    media = [],
    proposal_auth = null,
    users = null,
  } = jobDetails?.[0] || {};
  const imageUrl = `${process.env.REACT_APP_BASE_URL.replace(/\/api$/, "")}/${
    services?.image
  }`;

  const getJobDetail = useCallback(async () => {
    const { data, message } = await getSingleJob(
      { job_invite_id: id }
    ).unwrap();

    setJobDetails(data);
  }, [id]);

  useEffect(() => {
    getJobDetail();
  }, [id]);

  const handleSubmitProposal = () => {
    setOpen(!open);
    navigate(PATH.CONTRACTORJOB);
  };

  const handleContractorProposalnavigation = () => {
    navigate(`${PATH.CONTRACTORJOB}/${id}${PATH.PROPOSALS}`, {
      state: { id: proposal_auth.id },
    });
  };

  const initiateChatHandler = async () => {
    try {
      const { data } = await initiateChat({
        receiver_id: users?.id,
      }).unwrap();
      navigate(`/contractor/chats?chat=${data?.chat?.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const declineJobInviteHandler = async () => {
    try {
      const { data } = await declineJobInvite({
        job_id: id,
        invite_id: location.state.invite_id,
      }).unwrap();
      navigate("/contractor/dashboard");
      socket.emit("notification_send", data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        onRequestClose={() => {}}
        open={open}
        setOpen={() => setOpen(!open)}
      >
        <p className="font-inter text-haiti font-bold text-center m-2">
          Send Proposal
        </p>
        <hr className="mb-2" />
        <JobProposal job_id={id} setOpen={handleSubmitProposal} />
      </Modal>

      <Modal
        open={openConfirmJob}
        setOpen={() => setOpenConfirmJob(!openConfirmJob)}
      >
        <ConfirmJob
          job_id={id}
          closeModal={() => setOpenConfirmJob(!openConfirmJob)}
        />
      </Modal>
      {isLoading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <Spinner />
        </div>
      ) : jobDetails.length === 0 ? (
        <NoData message="No Job Found" />
      ) : (
        <div className="shadow-lg bg-white flex flex-col  p-4 my-4 mx-auto w-[94%] sm:w-[80%] rounded-lg min-h-[75vh]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              {/* Image div */}
              <div className=" w-12 h-12 ">
                <img src={imageUrl} className="w-full h-full rounded-lg" />
              </div>

              {/* Header text Area */}
              <div>
                <p className="font-inter font-semibold text-base">
                  {services.name}
                </p>
              </div>
            </div>
            <div className="flex gap-2">
              <CustomButton
                onClick={() => initiateChatHandler()}
                type="button"
                disabled={isChatLoading}
                className="bg-white w-auto text-haiti border border-haiti "
              >
                {isChatLoading ? <Spinner /> : "Chat"}
              </CustomButton>
              {job_status === "invited" && (
                <>
                  <CustomButton
                    type="button"
                    className=""
                    disabled={isDecliningJob}
                    onClick={declineJobInviteHandler}
                  >
                    {isDecliningJob ? "Decline..." : "Decline"}
                  </CustomButton>
                </>
              )}
              {job_status === "live" || job_status === "invited" ? (
                <>
                  {proposal_auth ? (
                    <CustomButton
                      type="button"
                      onClick={handleContractorProposalnavigation}
                      className="min-w-[150px] "
                    >
                      View Proposal
                    </CustomButton>
                  ) : (
                    <CustomButton
                      type="button"
                      onClick={() => setOpen(true)}
                      className="min-w-[150px] "
                    >
                      Send Proposal
                    </CustomButton>
                  )}
                </>
              ) : job_status === "assigned" ? (
                <CustomButton
                  type="button"
                  className=""
                  onClick={() => setOpenConfirmJob(true)}
                >
                  Ask For Confirmation
                </CustomButton>
              ) : job_status === "complete_request" ? (
                <CustomButton
                  type="button"
                  className="w-auto mx-auto bg-gray-300"
                  // onClick={() => setOpenConfirmJob(true)}
                >
                  Request Sent
                </CustomButton>
              ) : job_status === "completed" ? (
                <CustomButton
                  type="button"
                  className="bg-[#f980808c] capitalize text-[#ff0000] rounded-lg"
                >
                  Completed
                </CustomButton>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 justify-center mt-4">
            <div className="flex flex-col gap-4 md:border-r md:border-r-slate-400 ">
              {/* Header Area */}

              {/* body */}
              <div>
                <p className="text-xl font-bold font-inter">Job Title</p>
                <p className="font-inter font-normal text-sm">{title}</p>
              </div>
              <div>
                <p className="text-xl font-bold font-inter">Details</p>

                <p className="font-inter font-normal opacity-60 text-sm line-clamp-2">
                  {description}
                </p>
              </div>
              <div>
                <CarouselComponent media={media} />
              </div>
            </div>

            <div className=" flex flex-col gap-4">
              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={Date} alt="Date icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Date:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {getFormattedDate(date)}
                </p>
              </div>

              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={Time} alt="Time icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Start Time:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {getFormattedTime(start_time)}
                </p>
              </div>

              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={Time} alt="Time icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    End Time:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {getFormattedTime(end_time)}
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <img src={Location} alt="Location icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Location:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {address}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default JobInviteDetail;
