import { useEffect, useState } from "react";
import send from "../../../assets/icons/send.svg";
import { useAddMessageMutation } from "../../../redux/api/chat";
import { Spinner } from "flowbite-react";
import { useSocket } from "../../../utils/socket";

const SendMessage = ({ initialChatData,chatId }) => {
  const [message, setMessage] = useState("");
  const socket = useSocket();
  const [addChatMessage, { isLoading }] = useAddMessageMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const {data}=await addChatMessage({
        chat_id: chatId,
        receiver_id: initialChatData.id,
        message,
        type: "text",
      }).unwrap();
      // send_message socket event 
      socket.emit("message_send", data);
      setMessage("");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
setMessage("")
  }, [chatId])
  
  return (
    <form onSubmit={handleSubmit}>
      <footer className=" p-4 flex items-center gap-2">
        <div className="relative w-[90%]">
          <input
            type="text"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            className="bg-gray-100 w-full p-4 rounded-full outline-none font-Inter text-xs "
            placeholder="Type Here"
          />
        </div>
        <button
          disabled={!message.length}
          className="w-12 h-12 rounded-3xl bg-[#0A0A56] relative"
          type="submit"
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <img
              className="absolute top-[25%] left-[25%] cursor-pointer"
              src={send}
              alt="send"
            />
          )}
        </button>
      </footer>
    </form>
  );
};

export default SendMessage;
