import React from "react";

function ServicesCategories({ jobData,categories, handleSelectedCategory }) {

  return (
    <>
    <div>
      <p className="font-inter text-haiti text-base text-center mt-2">
        Select category to invite or create job
      </p>
      {categories?.map((obj) => (
        <p key={obj.id}
        className="border p-3 m-2 rounded-lg font-inter cursor-pointer bg-whiteSmoke"
          onClick={() =>{
            handleSelectedCategory({...obj,jobData});
          }}
        >
          {obj.name}
        </p>
      ))}
    </div>
      </>
  );
}

export default ServicesCategories;
