import React from "react";

const SingleHowItWork = ({i,heading,subHeading}) => {
  return (
    <div className="rounded-xl border bg-alabaster border-etherealBlack p-3">
      <div className="bg-haiti rounded-full p-3 size-14 flex justify-center items-center text-2xl text-white">
        {i+1}
      </div>
      <p className="mt-4 font-bold text-xl font-syne">{heading}</p>
      <p className="mt-2 text-battleshipGrey">
       {subHeading}
      </p>
    </div>
  );
};

export default SingleHowItWork;
