import React from "react";
import { useGetUserPrivacyPolicyQuery } from "../../../redux/api/privacy";
import Loader from "../../../components/Loader";
import Card from "../../../components/TextCard";

function UserPrivacy() {
  const { data, isLoading } = useGetUserPrivacyPolicyQuery();
  return (
    <>
      {isLoading ? <Loader /> : <Card>{data?.data?.home_owners}</Card>}
    </>
  );
}

export default UserPrivacy;
