import { Avatar } from "flowbite-react";
import React from "react";
import moment from 'moment'

function SenderMessage({ profile, message, updatedAt }) {
  return (
    <div className="p-4 flex gap-2 items-end">
      <Avatar alt="User" img={profile} rounded />
      <div className="w-[70%] flex flex-col items-start gap-1">
        <div className=" bg-lightGray7 rounded-tl-lg rounded-tr-lg rounded-br-lg p-2 text-sm border border-dark border-opacity-10">
          {message}
        </div>
        <span className="text-xs ">{moment(updatedAt).fromNow()}</span>
      </div>
    </div>
  );
}

export default SenderMessage;
