import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../utils/Path";
import User from "../../assets/icons/user.svg";
import Email from "../../assets/icons/email.svg";
import Password from "../../assets/icons/password.svg";
import Location from "../../assets/icons/Location.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreateUserMutation,
} from "../../redux/api/Auth";
import { toast } from "react-toastify";
import CustomModal from "../../components/Modal";
import Map from "../../components/Map";
import { setKey } from "react-geocode";
import { getCurrentAddress } from "../../utils/Location";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { storeCurrentUser } from "../../redux/slices/currentUser";
import ErrorMessage from "../../components/ErrorMessage";
import GoogleLogin from "./social/Google";
import SoicalLogin from "./social";

const validationSchema = Yup.object({
  name: Yup.string().min(3, "Must be 3 character").required("name is required"),
  email: Yup.string().email("Invalid email address").required("Email Required"),
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: Yup.string()
    .required("password must match")
    .oneOf([Yup.ref("password"), null], "Password must match"),
  address: Yup.string().required("Address is required"),
});

function Signup() {
  const [createUser] = useCreateUserMutation();
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  setKey(process.env.REACT_APP_MAP_KEY);

  const { touched, handleChange, values, errors, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        user_type: "contractor",
        location: {
          lat: 0,
          lng: 0,
        },
        address: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        const {
          name,
          email,
          password,
          password_confirmation,
          user_type,
          location,
        } = values;
        setIsLoading(true);
        try {
          const { data, status, message } = await createUser({
            name,
            email,
            password,
            password_confirmation,
            user_type,
            lat: location.lat,
            long: location.lng,
          }).unwrap();

          toast.success(message);
          setIsLoading(false);
          if (status === 200) {
            navigate(PATH.VERIFICATION);
          }
          dispatch(storeCurrentUser(data));
        } catch (err) {
          setIsLoading(false);
          toast.error(err.message);
        }
      },
    });

  const getLocation = () => {
    setOpenModal(true);
  };

  const handleModal = () => {
    setOpenModal(false);
  };

  const handleLocation = (location) => {
    setFieldValue("location", location);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(async (pos) => {
      setFieldValue("location", {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      const addr = await getCurrentAddress(
        pos.coords.latitude,
        pos.coords.longitude
      );
      setFieldValue("address", addr);
    });
  }, []);

  const handleAddress = (addr) => {
    setFieldValue("address", addr);
  };


  return (
    <>
      <CustomModal open={showRolesModal} setOpen={setShowRolesModal} onRequestClose={() => { }}>
        <p className="font-inter text-haiti font-bold m-2">
          Select User Type
        </p>
        <div className="mt-4 flex justify-center items-center">
          <GoogleLogin />
        </div>
      </CustomModal>
      <CustomModal open={openModal} setOpen={handleModal}>
        <Map
          coordinates={values.location}
          handleLocation={handleLocation}
          handleGetAddress={handleAddress}
          address={values.address}
        />
      </CustomModal>
      <section className="flex h-[100vh] overflow-y-auto px-4 w-full py-2">
        <div className="w-full py-2">
          <h1 className="mt-14 text-3xl text-center font-inter font-semibold leading-tight tracking-tight text-gray-900 md:text-2xl ">
            Let's Register
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            {/* name */}
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-base font-medium font-inte "
              >
                Name
              </label>
              <div className="flex relative">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-[#F5F5F5] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Enter name"
                  onChange={handleChange}
                  value={values.name}
                  required=""
                />
                <img src={User} className="absolute right-3 top-2.5" />
              </div>
            </div>

            {touched.name && errors.name ? (
              <ErrorMessage error={errors.name} />
            ) : null}

            {/* email */}
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-base font-medium font-inter  "
              >
                Email
              </label>
              <div className="flex relative">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-[#F5F5F5] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  placeholder="Enter your Email Address"
                  required=""
                  onChange={handleChange}
                  value={values.email}
                />
                <img src={Email} className="absolute right-3 top-2.5" />
              </div>
            </div>

            {touched.email && errors.email ? (
              <ErrorMessage error={errors.email} />
            ) : null}

            {/* Location  */}
            <div>
              <label
                htmlFor="address"
                className="block mb-2 text-base font-medium font-inter  "
              >
                Location
              </label>
              <div className="flex relative " onClick={getLocation}>
                <p
                  name="address"
                  id="address"
                  className="bg-[#F5F5F5] border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  "
                >
                  <p
                    className={clsx(
                      "w-[95%] h-full",
                      !values.address && "p-2.5"
                    )}
                  >
                    {values.address ? values.address : "Enter Location"}
                  </p>
                </p>
                <img
                  src={Location}
                  className="absolute right-3 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            </div>

            {/* password */}
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-base font-medium font-inter "
              >
                Password
              </label>
              <div className="flex relative">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Password"
                  className="bg-[#F5F5F5] pr-8 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  required=""
                  onChange={handleChange}
                  value={values.password}
                />
                <img src={Password} className="absolute right-3 top-2.5" />
              </div>
            </div>

            {touched.password && errors.password ? (
              <ErrorMessage error={errors.password} />
            ) : null}

            {/* confirm password */}
            <div>
              <label
                htmlFor="password_confirmation"
                className="block mb-2 text-sm font-medium font-inter"
              >
                Confirm Password
              </label>
              <div className="flex relative">
                <input
                  type="password"
                  name="password_confirmation"
                  id="password_confirmation"
                  placeholder="Confirm your Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg  block w-full p-2.5 "
                  required=""
                  onChange={handleChange}
                  value={values.password_confirmation}
                />
                <img src={Password} className="absolute right-3 top-2.5" />
              </div>
            </div>

            {touched.password_confirmation && errors.password_confirmation ? (
              <ErrorMessage error={errors.password_confirmation} />
            ) : null}

            <div className="flex justify-between w-full gap-2">
              {/* <div> */}
              <button
                type="button"
                className={` rounded-lg p-2 w-full cursor-pointer font-inter bg-whiteSmoke border ${values.user_type === "user"
                  ? "border-haiti"
                  : "border-gray-200"
                  }`}
                onClick={() => setFieldValue("user_type", "user")}
              >
                Home owner
              </button>
              {/* </div> */}
              {/* <div> */}
              <button
                type="button"
                className={` rounded-lg p-2 w-full cursor-pointer font-inter bg-whiteSmoke border ${values.user_type === "contractor"
                  ? "border-haiti"
                  : "border-gray-200"
                  }`}
                onClick={() => setFieldValue("user_type", "contractor")}
              >
                Contractor
              </button>
              {/* </div> */}
            </div>

            {/* Signup Button */}
            <button
              type="submit"
              className="w-full bg-[#161140] text-white  font-semibold font-inter rounded-lg text-sm px-5 py-2.5 text-center "
            >
              {isLoading ? "Loading...." : "Sign Up"}
            </button>

            {/* social buttons */}
            <p className="text-center font-semibold font-inter">
              Or Sign Up With
            </p>
            <SoicalLogin />
          </form>
        </div>
      </section>
    </>
  );
}

export default Signup;
