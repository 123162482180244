import React from "react";
import SingleHowItWork from "./SingleHowItWork";
import Container from "../../../../components/Container";

const howItWorksData = [
  {
    heading: "Search for Services",
    subHeading: "Users can search for the service they need.",
  },
  {
    heading: "Browse Profiles",
    subHeading: "View profiles of contractors and read reviews.",
  },
  {
    heading: "Book a Contractor",
    subHeading: "Select a contractor and schedule the service.",
  },
  {
    heading: "Get it Done",
    subHeading: "Have the work completed with peace of mind.",
  },
];
const HowItWorks = () => {
  return (
    <div className="bg-eclipseBlue py-14">
      <Container>
      <h6 className="text-center text-4xl sm:text-[56px] font-bold font-syne" data-aos="fade-right">How It Works</h6>
      <div className="grid mt-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4" data-aos="fade-up">
        {howItWorksData.map((data, i) => (
          <SingleHowItWork key={i} i={i} {...data} />
        ))}
      </div>
        </Container>
    </div>
  );
};

export default HowItWorks;
