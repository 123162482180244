

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

// const { access_token } = getCurrentUser();
export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders:(headers, {getState}) => {
      return authorizationHeader(headers, getState)
     }
  }),

  endpoints: (builder) => ({
    getSubscription: builder.query({
      query: () => "/contractor/get_subscription_plan",
    }),
  }),
});

export const { useGetSubscriptionQuery } = subscriptionApi;
