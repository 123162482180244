import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const termsConditionApi = createApi({
  reducerPath: "termsConditionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints:(builder) => ({
    getTermsCondition: builder.query({
        query: () => "/term-condition/all"
    })
  })


});

export const {useGetTermsConditionQuery} = termsConditionApi
