import React from "react";
import { Avatar, Dropdown } from "flowbite-react";
import NoData from "./NoData";
import moment from "moment";
import {
  img_base_url,
  inviteJobNotifyTypes,
  jobNotifyTypes,
  proposalNotifyTypes,
  reviewNotifyTypes,
} from "../utils";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, userRole } from "../utils/currentUser";

const DropdownComp = ({ placement, arrowIcon, label, style, data }) => {
  const navigate = useNavigate();
  const role = userRole();
  getCurrentUser();

  return (
    <Dropdown
      label={label || "Dropdown Button"}
      dismissOnClick={true}
      inline
      placement={placement}
      arrowIcon={arrowIcon}
      className={style}
    >
      {data.length < 1 ? (
        <NoData message="No Notification yet" className="h-[40vh]" />
      ) : (
        data.map(
          ({
            id,
            notification_text,
            type,
            data,
            created_at,
            from_user: { image, name },
          }) => {
            return (
              <div key={id}>
                <Dropdown.Item className="w-full mt-2 flex gap-3 p-2  shadow-md rounded-md">
                  <div
                    className="flex gap-2 items-start"
                    onClick={() => {
                      if (jobNotifyTypes.includes(type)) {
                        navigate(
                          role === "contractor"
                            ? `/contractor/jobs/${data}`
                            : `/user/jobs/${data}`
                        );

                      } else if (proposalNotifyTypes.includes(type)) {
                       navigate(  role === "contractor"?`contractor/jobs/${data}/proposals`:`/user/jobs/${data}/proposals`)
                      } else if (inviteJobNotifyTypes.includes(type)) {
                        navigate(
                          role === "contractor"
                            ? `/contractor/jobs/${data}`
                            : `/user/jobs/${data}`
                        );
                      } else if(reviewNotifyTypes.includes(type)) {
                        navigate(
                          role === "contractor"?'/contractor/reviews':'/user/reviews')
                      }
                    }}
                  >
                    <Avatar
                      className="w-10"
                      rounded
                      img={image ? img_base_url + image : null}
                      alt={name}
                    />
                    <div className="text-left flex-1">
                      <p>{notification_text}</p>
                      <p className="mt-1">{moment(created_at).fromNow()}</p>
                    </div>
                  </div>
                </Dropdown.Item>
              </div>
            );
          }
        )
      )}
    </Dropdown>
  );
};

export default DropdownComp;
