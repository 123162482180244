import React, { useState } from "react";
import CustomModal from "../../../components/Modal";
import CreateJobForm from "./CreateJob";
import { setKey } from "react-geocode";
import { useGetServicesQuery } from "../../../redux/api/services";
import PostedJobs from "./JobTypes/Posted";
import ActiveJobs from "./JobTypes/Active";
import HistoryJobs from "./JobTypes/History";
const CONTRACTS = [
  { title: "Posted" },
  { title: "Active" },
  { title: "History" },
];

function CreateJob() {

  const [selected, setSelected] = useState(0);
  const [open, setOpen] = useState(false);
  const { data: services } = useGetServicesQuery();
  setKey(process.env.REACT_APP_MAP_KEY);
  const JobTypeComponents=[PostedJobs,ActiveJobs,HistoryJobs]
  const Jobs=JobTypeComponents[selected]

  const handleJobChange = (id) => {
    setSelected(id);
  };

  return (
    <>
      <CustomModal open={open} setOpen={() => setOpen(!open)}>
        <p className="font-inter text-haiti font-bold text-center m-2">
          Create Job
        </p>
        <hr className="mb-2" />
        <CreateJobForm services={services?.data} setOpen={() => setOpen(!open)} />
      </CustomModal>
      {/* <button
        className="border m-4 p-2 font-inter text-haiti"
        onClick={() => setOpen(true)}
      >
        Create Job
      </button> */}
      <div className="container mx-auto p-2">
      <div className="flex justify-center gap-2  md:justify-between md:gap-0 items-center my-4  flex-wrap">
        {/* Pill Tab Area */}
        <div className="flex border rounded-full text-center overflow-hidden cursor-pointer w-full justify-between md:max-w-[343px] md:min-w-[343px]">
          {CONTRACTS.map((obj, i) => (
            <p
              key={i}
              className={`p-2 font-inter w-full  ${
                i > 0 ? "border-l-2" : ""
              }  ${i === selected ? "bg-haiti  text-white  " : ""} `}
              onClick={() => handleJobChange(i)}
            >
              {obj.title}
            </p>
          ))}
        </div>
      </div>
      {/* render user posted jobs */}
      <Jobs/>
      </div>
    
    
    </>
  );
}

export default CreateJob;
