import React from "react";

const License = () => {
  return (
    <div class="container px-4 max-w-[1156px] w-full mx-auto mt-12">
      <div class="page-header text-center">
          <h6 className="underline font-medium">END USER LICENSE AGREEMENT</h6>
      </div>
      <p className="mt-2 text-balticSea font-normal">
        This End User License Agreement (this “Agreement”) is a legal contract
        between Zitro Connect LLC (“Zitro,” “We,” “Us” or “Our”) and you
        (“You”). By using Our mobile application, You are agreeing to the terms
        in this Agreement. In addition, by agreeing to our Agreement, you are
        also acknowledge our Privacy Policy. READ THE TERMS AND CONDITIONS OF
        THIS AGREEMENT CAREFULLY BEFORE DOWNLOADING OR INSTALLING OR USING OUR
        SOFTWARE APPLICATION TO CONNECT HOMEOWNERS AND CONTRACTORS ACCOMPANIED
        BY THIS AGREEMENT (THE “APP”). BY DOWNLOADING AND/OR USING THE APP, YOU
        AGREE TO THE TERMS AND CONDITIONS OF THE AGREEMENT.
      </p>
      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 1. License Grant</h6>
          <p className="mt-2 text-balticSea font-normal">
            The App is copyrighted and it is licensed under the terms and
            conditions of this Agreement. It is not sold to You. Subject to You
            remaining in full and ongoing compliance with the terms and
            conditions of this Agreement, including payment of any applicable
            license and service fees, We hereby grant to You, and You accept, a
            personal, limited, nonexclusive, non-transferable, non-assignable,
            revocable license to use the App during the Term in
            machine-readable, object code form only on one (1) device, and only
            as authorized in this Agreement. For purposes of this Agreement, the
            term “App” includes Our software, and any enhancements, updates,
            maintenance releases, modifications, revisions, or additions
            (collectively “Updates”) to the App provided by Us and made
            available to You. Notwithstanding the foregoing, We shall be under
            no obligation to provide You with any Updates to the App
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 2. Scope of Use.</h6>
          <p className="mt-2 text-balticSea font-normal">
            2.1. The license to use the App is conditioned on the following
            license restrictions, and any use of the App in violation of any of
            these restrictions, or any of the other terms of this Agreement is a
            breach of this Agreement. 2.2. Installation of the App on a network
            server solely for distribution to other computers is strictly
            prohibited. 2.3. Except as this Agreement expressly permits, You
            shall not, and shall not permit any other person to: (a) copy the
            App, in whole or in part; (b) modify, correct, adapt, translate,
            enhance or otherwise prepare derivative works or improvements of the
            App or any part thereof; (c) rent, lease, lend, sell, sublicense,
            assign, distribute, publish, transfer or otherwise make the App
            available to any person, including on or in connection with the
            Internet or any time-sharing, service bureau, software as a service,
            cloud or other technology or service; (d) reverse engineer,
            disassemble, decompile, decode or adapt the App, or otherwise
            attempt to derive or gain access to the source code of the App, in
            whole or in part; (e) bypass or breach any security device or
            protection used for or contained in the App; (f) remove, delete,
            efface, alter, obscure, translate, combine, supplement or otherwise
            change any trademarks, warranties, disclaimers, or Intellectual
            Property Rights (as defined below), proprietary rights or other
            symbols, notices, marks or serial numbers on or relating to any copy
            of the App; (g) use the App in any manner or for any purpose that
            infringes, misappropriates or otherwise violates any Intellectual
            Property Right or other right of any person, or that violates any
            applicable law (including any laws regarding the export of data or
            software to and from the US or other countries); (h) use the App for
            purposes of: (1) benchmarking or competitive analysis of the App;
            (2) developing, using or providing a competing software product or
            service; or (3) any other purpose that is to Our detriment or
            commercial disadvantage; or (i) use the App in any manner or for any
            purpose or application not expressly permitted by this Agreement.
            2.4. The App may contain technological measures designed to prevent
            unauthorized or illegal use. You acknowledge and agree that: (a) We
            may use these and other lawful measures to verify your compliance
            with the terms of this Agreement and enforce Our rights, including
            all Intellectual Property Rights, in and to the App; (b) We may deny
            any person access to and/or use of the App if, in Our sole
            discretion, We believe that such person's use of the App would
            violate any provision of this Agreement; and (c) We and Our
            personnel may collect, maintain, process and use diagnostic,
            technical, usage and related information, including information
            about your computers, systems and software, that We may gather
            periodically to improve the performance of the App or develop
            Updates. This information will be treated in accordance with Our
            Privacy Policy set forth in Section 10 below, as amended from time
            to time.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold ">Section 3. Term / Termination.</h6>
          <p className="text-balticSea mt-2">
            {" "}
            3.1. The term of this Agreement will commence on the date You
            download the App (“Effective Date”) and will continue in effect
            until You terminate your account (the “Term”). 3.2. You may
            terminate this Agreement by uninstalling the App. 3.3. We may
            terminate this Agreement at any time if You: (i) violate any
            provision in this Agreement or (ii) fail to pay any fee associated
            with the App, and do not cure the failure within ten (10) days
            following receipt of Our written notice of default. 3.4. In the
            event that either party files for protection under U.S. bankruptcy
            laws, makes an assignment for the benefit of creditors, appoints or
            suffers appointment of a receiver or trustee over its property,
            files a petition under any U.S. bankruptcy or insolvency act or has
            any such petition filed against it which is not discharged within
            one hundred eighty (180) days of the filing thereof, then the other
            party may terminate this Agreement effective immediately upon
            written notice to such party. 3.5. Upon the termination of this
            Agreement: (a) all rights, licenses and authorizations granted to
            You hereunder will immediately terminate and You shall: (1)
            immediately cease all use of and other activities with respect to
            the App; (2) within fifteen (15) days, deliver to Us, or at Our
            written request, destroy, and permanently uninstall the App from
            your device; and (3) pay all amounts due and payable by You to Us of
            any kind no later than ten (10) days after the effective date of the
            termination of this Agreement.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 4. Disclaimer.</h6>
          <p className="mt-2 text-balticSea font-normal">
            {" "}
            4.1. EXCEPT AS EXPRESSLY SET FORTH HEREIN, YOU UNDERSTAND,
            ACKNOWLEDGE AND AGREE THAT THE APP IS PROVIDED ON AN “AS IS” BASIS
            AND WE MAKE NO REPRESENTATION OR WARRANTY TO YOU, EXPRESS OR
            IMPLIED, WITH RESPECT TO THE APP, INCLUDING ANY REPRESENTATION OR
            WARRANTY AS TO THE APP’S CONDITION, QUALITY, FITNESS FOR USE OR THAT
            THE APP IS APPROPRIATE FOR YOUR PARTICULAR PURPOSE OR WILL MEET YOUR
            PERSONAL REQUIREMENTS, OR ANY REPRESENTATION OR WARRANTY RESPECTING
            THE APP'S MERCHANTABILITY. 4.2. YOU UNDERSTAND AND ACKNOWLEDGE THAT
            NO INTERNET SERVICE IS COMPLETELY SECURE FROM THREAT AND THAT THERE
            ARE RISKS INHERENT IN INTERNET CONNECTIVITY THAT COULD RESULT IN THE
            LOSS OF YOUR PRIVACY, CONFIDENTIAL INFORMATION AND PROPERTY.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 5. Limitation of Damages.</h6>
          <p className="mt-2 text-balticSea font-normal">
            {" "}
            5.1. NEITHER PARTY SHALL BE LIABLE TO THE OTHER PARTY FOR ANY LOST
            PROFITS, OR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
            CONSEQUENTIAL LOSS OR DAMAGE OF ANY KIND, ARISING IN CONNECTION WITH
            THIS AGREEMENT, EVEN IF THE PARTY HAS BEEN ADVISED OR SHOULD BE
            AWARE OF THE POSSIBILITY OF SUCH DAMAGES. 5.2. NOTWITHSTANDING
            ANYTHING TO THE CONTRARY IN THIS AGREEMENT, OUR MAXIMUM AGGREGATE
            MONETARY LIABILITY UNDER ANY THEORY OF LAW (INCLUDING BREACH OF
            CONTRACT, TORT, STRICT LIABILITY AND INFRINGEMENT) SHALL NOT EXCEED
            $1,000.00.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 6. Intellectual Property Rights. </h6>
          <p className="mt-2 text-balticSea font-normal">
            You acknowledge and agree that: (a) the App is licensed, not sold,
            to You, and You do not and will not have or acquire any ownership
            interest in the App, or in any related Intellectual Property Rights;
            (b) We are, and will remain, the sole and exclusive owner of all
            right, title and interest in and to the App, including all
            Intellectual Property Rights relating thereto, subject only to the
            limited license granted to You under this Agreement; and (c) nothing
            herein transfers or assigns, or will be deemed to transfer or
            assign, any such Intellectual Property Rights in the App to You. For
            purposes of this Agreement, “Intellectual Property Rights” means any
            and all registered and unregistered rights granted, applied for or
            otherwise now or hereafter in existence under or related to any
            patent, copyright, trademark, trade secret, or other intellectual
            property rights laws in any jurisdiction in the world.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 7. General Terms. </h6>
          <p className="mt-2 text-balticSea font-normal">
            7.1. Except as otherwise expressly provided herein, no amendment of
            this Agreement shall be binding upon either party hereto unless such
            amendment is set forth in a writing and executed by both parties
            hereto. Any waiver of any breach of any provision of this Agreement
            shall only be effective if in a writing and executed by both parties
            hereto and only to the extent specifically set forth in such
            writing. 7.2. You shall not assign this Agreement or any part
            thereof to a third party,[ and any such assignment, or attempted
            assignment, will be null and void. 7.3. This Agreement constitutes
            the entire understanding between the parties hereto with respect to
            the subject matter hereof. The parties hereto agree that this
            Agreement supersedes and replaces any and all other agreements,
            whether oral or in writing, regarding the subject matter hereof.
            7.4. This Agreement shall be binding upon and inure to the benefit
            of the parties hereto and their successors and authorized assigns.
            No other person or entity is an intended third party beneficiary of,
            or shall be deemed to be a third party beneficiary of, any of the
            terms and conditions of this Agreement. 7.5. In the event any one or
            more of the provisions contained in this Agreement should be
            determined by a court of competent jurisdiction to be invalid,
            illegal or unenforceable in any respect, the validity, legality and
            enforceability of the remaining provisions contained herein shall
            not in any way be affected or impaired thereby, and the parties
            hereto agree to negotiate in good faith to replace such invalid,
            illegal or unenforceable provision with a replacement provision to
            carry out the intent of such provision to the fullest extent lawful.
            7.6. This Agreement is governed by and construed in accordance with
            the internal laws of the State of Maryland without regard to any
            choice or conflict of law provision or rule that would require or
            permit the application of the laws of any jurisdiction other than
            those of the State of Maryland. 7.7. We shall not be in default of
            any obligation under this Agreement if the failure to perform the
            obligation is due to any event beyond Our control, including
            significant failure of a part of the power grid, ] significant
            failure of the Internet, failure of third party tools and services
            used to provide the App, natural disaster, war, riot, insurrection,
            pandemic, terrorist activity and events related thereto, strikes or
            other organized labor action, or other events of a similar magnitude
            or type. 7.8. In disputes concerning this Agreement, We shall be
            entitled to the costs of collection, enforcement, and injunctive
            relief, including reasonable attorneys’ fees and court costs, and
            all necessary expenses, regardless of whether litigation is
            commenced. 7.9. The definitions in this Agreement shall apply
            equally to both the singular and plural forms of the terms defined.
            Whenever the context may require, any pronoun shall include the
            corresponding masculine, feminine and neuter forms. The words
            “include,” “includes” and “including” shall be deemed to be followed
            by the phrase “without limitation.” Unless otherwise specifically
            indicated, the word “or” shall be deemed to be inclusive and not
            exclusive. 7.10 You understand and acknowledge that We may use third
            party service provider(s) as We deem necessary or desirable to
            provide tech support services to You under this Agreement. Such tech
            support providers may be located overseas.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 8. Ratings and Reviews. </h6>
          <p className="mt-2 text-balticSea font-normal">
            The App permits You to leave a public review (“Review”) and submit a
            star rating (“Rating”) about a contractor. Ratings or Reviews
            reflect the opinions of individual users and do not reflect the
            opinion of Zitro. Ratings and Reviews are not verified by Zitro for
            accuracy and may be incorrect or misleading. If you submit a Review,
            then you grant Us a perpetual, non-terminable, royalty-free license
            to distribute and post your Review in connection with Our App. Your
            reliance on any Rating or Review is at your own risk. If You submit
            a Rating or Review, then it must be accurate and must comply with
            Our Rules of Conduct set forth below.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 9. Rules of Conduct.</h6>
          <p>
            Your use of the App is subject to all applicable local, state,
            national laws and regulations and, in some cases, international
            treaties. You are solely responsible for all activities, acts and
            omissions that occur in, from, or through the App on your mobile
            device. You shall not use, allow, or enable others to use the App,
            the Ratings or Reviews, or provide Reviews that are: (i) libelous,
            defamatory, indecent, vulgar or obscene, pornographic, sexually
            explicit or sexually suggestive, racially, culturally, or ethnically
            offensive, harmful, harassing, intimidating, threatening, hateful,
            objectionable, discriminatory, or abusive, or (ii) transmit,
            distribute or upload programs or material that contain malicious
            code, such as viruses, time bombs, cancel-bots, worms, Trojan
            horses, spyware, or other potentially harmful programs or other
            material or information.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 10. Privacy Policy (Last Updated 2-27-23).</h6>
          <p className="mt-2 text-balticSea font-normal">
            10.1 Our App collects “Personal Information”. Personal Information
            may include your name, location, email and other information that
            identifies You. We collect this information when You voluntarily
            provide it to Us through Our App. We, and Our authorized third party
            service providers only use your Personal Information to provide Our
            services to You. We do not sell, trade, rent or otherwise share your
            Personal Information for marketing purposes with third parties. You
            may request additional information on what types of information We
            collect through Our App by sending an email message to:
            info@zitroconnect.com. 10.2 We implement commercially reasonable
            security measures designed to protect your Personal Information from
            unauthorized access. For example, We protect your Personal
            Information from potential security breaches by implementing certain
            technological measures, including encryption, firewalls and secure
            socket layer technology. However, these measures do not guarantee
            that your Personal Information is free from all unauthorized access,
            disclosure, alteration or destruction by persons who breach such
            firewalls and secure server software. By providing your Personal
            Information to Us, You acknowledge that You understand and agree to
            assume these risks. 10.3 Our services are restricted to users who
            are 18 years of age or older. We do not permit users under the age
            of 18 (a "Child") to download Our App without their parent or
            guardian’s consent. We do not knowingly collect Personal Information
            from a Child without their parent or guardian’s consent.
            Nonetheless, if You are a parent or guardian of a Child and You
            become aware that your Child has provided Us with Personal
            Information without your consent, You should contact Us by sending
            an email addressed to: info@zitroconnect.com. Parents and guardians
            always have the right to inspect any information that We may have
            inadvertently collected from their Child, and have the right to have
            Us edit or delete it.
          </p>
        </div>
      </div>

      <div class="content mb-4 mt-4">
        <div class="header">
          <h6 className="font-bold text-balticSea">Section 11. California Privacy Rights</h6>
          <p className="mt-2 text-balticSea font-normal">
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits users of Our App who are California residents to
            request and obtain from Us, once a year and free of charge,
            information about the Personal Information (if any) We disclosed to
            third parties for direct marketing purposes in the preceding
            calendar year. As noted above, We do not sell, trade, rent or
            otherwise share your Personal Information for marketing purposes
            with third parties. In addition, California consumers have a right
            to know, access, and delete any of their Personal Information that
            we collect under the California Consumer Privacy Act.
          </p>
        </div>
      </div>
    </div>
  );
};

export default License;
