import React, { useState } from "react";
import DateLogo from "../assets/icons/Calendar.svg";
import Time from "../assets/icons/Clock.svg";
import Location from "../assets/icons/Location.svg";
import CarouselComponent from "./Carousel";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomButton from "./CustomButton";
import Modal from "./Modal";
import JobProposal from "../screens/contractor/jobs/Proposal";
import { useGetSingleJobDetailsMutation } from "../redux/api/Jobs";
import { useEffect } from "react";
import { useCallback } from "react";
import { Spinner } from "flowbite-react";
import { userRole } from "../utils/currentUser";
import { PATH } from "../utils/Path";
import { useUserJobCompletedRequestMutation } from "../redux/api/Jobs";
import { toast } from "react-toastify";
import UserFeedback from "../screens/user/Job/feedBack";
import NoData from "./NoData";
import moment from "moment";
import {
  getFormattedDate,
  getFormattedTime,
  compareDate,
} from "../utils/DateAndTime";
import { useInitiateChatMutation } from "../redux/api/chat";
import { useSocket } from "../utils/socket";

function UserJobDetail() {
  const [seeMore, setSeeMore] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const socket = useSocket();
  const [jobDetails, setJobDetails] = useState([]);
  const { id } = useParams();
  const [getSingleJob, { isLoading, error }] = useGetSingleJobDetailsMutation();
  const [userCompleteJob, { isLoading: loading }] =
    useUserJobCompletedRequestMutation();

  const { state } = useLocation();
  const role = userRole();

  const {
    user_id,
    title = "",
    services = {},
    description = "",
    address = "",
    date = "",
    start_time = "",
    end_time = "",
    job_status = "",
    media = [],
    users = null,
  } = jobDetails?.[0] || {};
  const imageUrl = `${process.env.REACT_APP_BASE_URL.replace(/\/api$/, "")}/${
    services?.image
  }`;

  const [initiateChat, { isLoading: isChatLoading }] =
    useInitiateChatMutation();

  const getJobDetail = useCallback(async () => {
    const { data, message } = await getSingleJob({
      job_id: id,
    }).unwrap();

    setJobDetails(data);
  }, [id]);

  useEffect(() => {
    getJobDetail();
  }, [id]);

  const handleJobProposals = () => {};
  const handleEndJob = async () => {
    try {
      const { data, message, status } = await userCompleteJob({
        job_id: id,
      }).unwrap();
      if (status === 200) {
        setOpen(true);
      }
      toast.success(message);
      socket.emit("notification_send", data);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const todayDate = getFormattedDate(new Date());
  const jobDate = getFormattedDate(date);

  const isExpired = compareDate(jobDate, todayDate);

  return (
    <>
      <Modal open={open} setOpen={() => setOpen(!open)}>
        <UserFeedback job_id={id} />
      </Modal>
      {isLoading ? (
        <div className="w-full h-screen items-center flex justify-center">
          <Spinner />
        </div>
      ) : jobDetails.length === 0 ? (
        <NoData message="No Job Found" />
      ) : (
        <div className="shadow-lg bg-white flex flex-col  p-4 my-4 mx-auto w-[94%] sm:w-[80%] rounded-lg min-h-[75vh]">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              {/* Image div */}
              <div className=" w-12 h-12 ">
                <img src={imageUrl} className="w-full h-full rounded-lg" />
              </div>

              {/* Header text Area */}
              <div>
                <p className="font-inter font-semibold text-base">
                  {services.name}
                </p>
              </div>
            </div>

            <div className="flex gap-2">
              {/* <CustomButton
                type="button"
                className=" bg-white w-auto text-haiti border border-haiti"
                onClick={() => initiateChatHandler()}
              >
                {isChatLoading ? <Spinner /> : "Chat"}
              </CustomButton> */}
              {job_status === "live" ? (
                <Link to={`${PATH.USERJOB}/${id}${PATH.PROPOSALS}`}>
                  <CustomButton
                    type="button"
                    onClick={handleJobProposals}
                    className="w-auto"
                  >
                    View Proposals
                  </CustomButton>
                </Link>
              ) : job_status === "assigned" ? (
                <>
                  <CustomButton
                    type="button"
                    className=" bg-gray-300 text-white cursor-default min-w-[150px]"
                  >
                    Job in Progress
                  </CustomButton>
                </>
              ) : job_status === "completed" ? (
                <div className="bg-green-700 rounded-lg flex items-center p-2 h-fit">
                  <p className="text-xs font-inter text-white">Completed</p>
                </div>
              ) : job_status === "complete_request" ? (
                <CustomButton
                  type="button"
                  className=" bg-haiti min-w-[100px]"
                  onClick={handleEndJob}
                >
                  {loading ? "Ending...." : " End Job"}
                </CustomButton>
              ) : job_status === "invited" ? (
                <div>
                  {isExpired ? (
                    <div className="bg-[#f980808c] rounded-lg flex items-center p-2 h-fit">
                      <p className="ml-1 text-xs font-inter text-[#ff0000]">
                        {" "}
                        Expired
                      </p>
                    </div>
                  ) : (
                    // <div className="bg-haiti rounded-lg flex items-center p-2 h-fit">
                    //   <p className="text-xs font-inter text-white">Invited </p>
                    // </div>

                    <Link to={`${PATH.USERJOB}/${id}${PATH.PROPOSALS}`}>
                      <CustomButton
                        type="button"
                        onClick={handleJobProposals}
                        className="w-auto"
                      >
                        View Proposals
                      </CustomButton>
                    </Link>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className=" grid grid-cols-1 md:grid-cols-2 gap-4 justify-center mt-4">
            <div className="flex flex-col gap-4 md:border-r md:border-r-slate-400 ">
              {/* Header Area */}

              {/* body */}
              <div>
                <p className="text-xl font-bold font-inter">Job Title</p>
                <p className="font-inter font-normal text-sm">{title}</p>
              </div>
              <div>
                <p className="text-xl font-bold font-inter">Details</p>

                <p className="font-inter font-normal opacity-60 text-sm ">
                  {seeMore
                    ? description
                    : description.substring(0, 150) + "..."}

                  <button
                    className="font-bold text-haiti ml-1 opacity-100"
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "Show less" : "Show more"}
                  </button>
                </p>
              </div>
              <div>
                <CarouselComponent media={media} />
              </div>
            </div>

            <div className=" flex flex-col gap-4">
              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={DateLogo} alt="Date icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Date:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {/* {getFormattedDate(date)} */}
                  {moment(date, "MM-DD-YYYY HH:mm:ss A").format("MMMM Do YYYY")}
                </p>
              </div>

              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={Time} alt="Time icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Start Time:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                {moment(start_time, "H:mm a").format("H:mm a")}
                </p>
              </div>

              <div className="flex justify-between ">
                <div className="flex items-center gap-2">
                  <img src={Time} alt="Time icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    End Time:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                {moment(end_time, "H:mm a").format("H:mm a")}
                </p>
              </div>

              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <img src={Location} alt="Location icon" className="w-5 h-5" />
                  <p className="font-inter text-haiti text-sm font-medium">
                    Location:
                  </p>
                </div>
                <p className="font-inter text-haiti text-base font-semibold">
                  {address}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserJobDetail;
