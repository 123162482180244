import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import NoData from "../../../../components/NoData";
import { useGetPostedJobsQuery } from "../../../../redux/api/Jobs";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../../utils/Path";
import Card from "../../../../components/Card";
import PaginationComponent from "../../../../components/Pagination";

const PostedJobs = () => {
    const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate();

    const { data:jobsData, isLoading }  = useGetPostedJobsQuery(currentPage,{refetchOnMountOrArgChange:true});
    const handleNavigation = (id) => {
        navigate(`${PATH.CONTRACTORJOB}/${id}`);
      };
  return (
    <div>
      {isLoading ? (
        <div className="w-full items-center flex justify-center">
          <Spinner />
        </div>
      ) : jobsData?.data?.data?.length === 0 ? (
        <NoData message="No Job Found" />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 overflow-y-auto  justify-center">
          {jobsData?.data?.data?.map((data, index) => (
            <div key={index}>
              <Card
                data={data}
                onClick={() => handleNavigation(data.id)}
                proposals={false}
              />
            </div>
          ))}
        </div>
      )}
       {
        jobsData && (jobsData.data.last_page>1? (
          <PaginationComponent currentPage={currentPage} totalPages={jobsData.data.last_page} onPageChange={(page)=>setCurrentPage(page)}/>
        ):null)
      }
    </div>
  );
};

export default PostedJobs;
