import React, { useState } from "react";
import ActiveJobs from "./JobTypes/Active";
import ProposalJobs from "./JobTypes/Proposals";
import CompletedJobs from "./JobTypes/Completed";

const CONTRACTS = ["Active", "Proposals", "Completed"];

function Jobs() {
  const [selected, setSelected] = useState(0);

  const handleJobChange = (id) => {
    setSelected(id);
  };

  const JobsComponents=[ActiveJobs,ProposalJobs,CompletedJobs];
  const Jobs=JobsComponents[selected];

  return (
    <div className="container mx-auto p-2">
      <div className="flex justify-center gap-2   md:justify-between md:gap-0 items-center my-4 flex-wrap">
        {/* Pill Tab Area */}
        <div className="flex border rounded-full text-center overflow-hidden cursor-pointer w-full justify-between md:max-w-[343px] md:min-w-[343px]">
          {CONTRACTS.map((title, i) => (
            <p
              key={i}
              className={`p-2 font-inter w-full  ${
                i > 0 ? "border-l-2" : ""
              }  ${i === selected ? "bg-haiti  text-white  " : ""} `}
              onClick={() => handleJobChange(i)}
            >
              {title}
            </p>
          ))}
        </div>

        {/* Search Box Area  */}
        {/* <SearchBox/> */}
      </div>

      {/* Show Job Cards */}
      {/* <div className="">
        {isLoading ? (
          <div className="w-full items-center flex justify-center">
            <Spinner />
          </div>
        ) : selected === 1 ? (
          <Proposals
            contractorJobs={contractorJobs}
            handleNavigation={handleNavigation}
          />
        ) : (
          <Active
            contractorJobs={contractorJobs}
            handleNavigation={handleNavigation}
          />
        )}
      </div> */}
      <Jobs/>

      {/* Pagination Component */}
      {/* <div>
        <PaginationComponent
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div> */}
    </div>
  );
}

export default Jobs;
