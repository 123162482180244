import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader, getCurrentUser } from "../../utils/currentUser";

const result = getCurrentUser();
export const completeProfileApi = createApi({
  reducerPath: "completeProfileApi",
  tagTypes:[],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
   prepareHeaders:(headers, {getState}) => {
    return authorizationHeader(headers, getState)
   }
  }),

  endpoints: (builder) => ({
    completeProfile: builder.mutation({
     
      query: (data) => ({
        url: "/complete_profile",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useCompleteProfileMutation, useAddPersonalInformationMutation } =
  completeProfileApi;
