import React, { useState } from 'react'
import { useGetJobInvitesQuery } from '../../../../redux/api/Jobs';
import { Spinner } from 'flowbite-react';
import NoData from '../../../../components/NoData';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../utils/Path';
import Card from '../../../../components/Card';
import PaginationComponent from '../../../../components/Pagination';

const InvitedJobs = () => {
  const [currentPage, setCurrentPage] = useState(1)

    const { data:jobsData, isLoading } = useGetJobInvitesQuery(currentPage,{refetchOnMountOrArgChange:true});
    const navigate = useNavigate();

    const handleNavigation = (inviteId,id) => {
      navigate(`${PATH.CONTRACTORJOB}/${id}`,{state:{invite_id:inviteId}});
    };
  return (
    <div>
            {isLoading ? (
        <div className="w-full items-center flex justify-center">
          <Spinner />
        </div>
      ) : jobsData?.data?.data?.length === 0 ? (
        <NoData message='No Job Found' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 overflow-y-auto  justify-center">
          {jobsData?.data?.data?.map(({id,jobs}, index) => (
            <div key={index}>
              <Card
                data={jobs}
                onClick={() => handleNavigation(id,jobs.id)}
                proposals={false}
              />
            </div>
          ))}
        </div>
      )}
       {
        jobsData && (jobsData.data.last_page>1? (
          <PaginationComponent currentPage={currentPage} totalPages={jobsData.data.last_page} onPageChange={(page)=>setCurrentPage(page)}/>
        ):null)
      }
    </div>
  )
}

export default InvitedJobs