import { Spinner } from 'flowbite-react'
import React, { useState } from 'react'
import { useGetUserPostedJobsQuery } from '../../../../redux/api/Jobs';
import NoData from '../../../../components/NoData';
import Card from '../../../../components/Card';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../utils/Path';
import PaginationComponent from '../../../../components/Pagination';

const PostedJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

    const { data: jobsData, isLoading } = useGetUserPostedJobsQuery(currentPage,{refetchOnMountOrArgChange:true});
    const handleNavigation = (id) => {
        navigate(`${PATH.USERJOB}/${id}`);
      };
    
      const handleDeleteJob = (e, id) => {
        e.stopPropagation();

      };
  return (
    <div>
          {isLoading ? (
        <div className="w-full items-center flex justify-center">
          <Spinner />
        </div>
      ) : jobsData?.data?.data?.length === 0 ? (
        <NoData message='No Job Found' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4    justify-center">
          {jobsData?.data?.data?.map((data, index) => (
            <div className="flex " key={index}>
              <Card
                data={data}
                onClick={() => handleNavigation(data.id)}
                handleDeleteJob={handleDeleteJob}
              />
            </div>
          ))}
        </div>
      )}
        {
        jobsData && (jobsData.data.last_page>1? (
          <PaginationComponent currentPage={currentPage} totalPages={jobsData.data.last_page} onPageChange={(page)=>setCurrentPage(page)}/>
        ):null)
      }
    </div>
  )
}

export default PostedJobs