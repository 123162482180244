// import React from "react";
// import DetailHeading from "../../components/DetailHeading";
// import SelectInput from "../../components/SelectInput";
// import CustomInput from "../../components/CustomInput";

// import { useFormik } from "formik";
// import * as Yup from "yup";
// import Button from "../../components/Button";

// const validationSchema = Yup.object({
//   service: Yup.string().required("select service"),
//   hourlyRate: Yup.number("Must be number").positive("Must be positive value").required("hourly rate is required"),
// });

// function Services({next}) {

//   const formik = useFormik({
//     initialValues: {
//       service: "",
//       hourlyRate: "",
//     },
//     validationSchema,
//     onSubmit: (values) => {
//       next()
//     },
//   });
//   return (
//     <>
//       <DetailHeading color="#161140">Add Services</DetailHeading>
//       <form onSubmit={formik.handleSubmit}>
//         <SelectInput
//           heading="Service"
//           options={["option A", "option B", "option C", "option D"]}
//           htmlFor="service"
//           onChange={formik.handleChange}
//           value={formik.values.service}
//           error={formik.errors.service}
//         />
//         <CustomInput
//           heading="Hourly Rate"
//           type="number"
//           htmlFor="hourlyRate"
//           placeholder="Enter hourly rate"
//           onChange={formik.handleChange}
//           value={formik.values.hourlyRate}
//           error={formik.errors.hourlyRate}
//         />
//         <Button type="submit" className="mt-2">Next</Button>
//       </form>
//     </>
//   );
// }

// export default Services;

import React, { useState } from "react";
import DetailHeading from "../../../components/DetailHeading";
import SelectInput from "../../../components/SelectInput";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import Add from "../../../assets/icons/Add.svg";

import { useFormik } from "formik";
import * as Yup from "yup";
import { MdDelete } from "react-icons/md";

import { useDispatch } from "react-redux";
import { addServices } from "../../../redux/slices/Profile";
import { useSelector } from "react-redux";
import { useCompleteProfileMutation } from "../../../redux/api/completeProfile";
import { useGetServicesQuery } from "../../../redux/api/services";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  services: Yup.array().of(
    Yup.object({
      service: Yup.number().required("Select service"),
      hourlyRate: Yup.number("Must be a number")
        .positive("Must be a positive value")
        .required("Hourly rate is required"),
    })
  ),
});

const SERVICES = [
  "Fire",
  "Floors and Walls",
  "Cleaning",
  "Pools",
  "Hot Tubs",
  "Meta/Marble/Stone",
];

function Services({ next, finish }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const details = useSelector((state) => state.completeProfile);
  const [completeProfile, result] = useCompleteProfileMutation();
  const { data, error, isLoading } = useGetServicesQuery();

  const val = data?.data[0].id;
  const formik = useFormik({
    initialValues: {
      services: [{ service: val, hourlyRate: "" }],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const res = values.services.map((obj) => {
        return {
          service_id: parseInt(obj.service),
          rate_hourly: obj.hourlyRate,
        };
      });
      try {
        const { data, message, status } = await completeProfile({
          request_services: res,
          personal_information: 0,
          subscription: 0,
          services: 1,
          card: 0,
        }).unwrap();
        if (status === 200) {
          next();
        }
        setLoading(false);
      } catch (err) {
        console.log(err, "ERROR");
        toast.error(err.message);
        setLoading(false);
      }
      dispatch(addServices(values));
    },
  });

  const addService = () => {
    formik.setValues({
      ...formik.values,
      services: [...formik.values.services, { service: val, hourlyRate: "" }],
    });
  };

  const handleRemoveField = (id) => {
    formik.setFieldValue(
      "services",
      formik.values.services.filter((_, i) => i !== id)
    );
  };

  return (
    <>
      <DetailHeading color="#161140">Add Services</DetailHeading>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.services.map((service, index) => (
          <div key={index}>
            {index > 0 && (
              <div className="flex justify-end mt-2 cursor-pointer text-red-900">
                <MdDelete
                  className="h-7 w-7 ring-1 rounded-full ring-red-950 ring-inset p-1"
                  onClick={() => handleRemoveField(index)}
                />
              </div>
            )}
            <SelectInput
              heading={`Service ${index + 1}`}
              options={data?.data}
              htmlFor={`services[${index}].service`}
              onChange={formik.handleChange}
              value={service.service}
              error={
                formik.errors.services && formik.errors.services[index]?.service
              }
            />
            <CustomInput
              heading={`Hourly Rate ${index + 1}`}
              type="number"
              htmlFor={`services[${index}].hourlyRate`}
              placeholder="Enter hourly rate"
              onChange={formik.handleChange}
              value={service.hourlyRate}
              error={
                formik.errors.services &&
                formik.errors.services[index]?.hourlyRate
              }
            />
          </div>
        ))}

        {/* Add another Service */}
        {/* <div className="flex justify-center items-center"> */}
        <button
          type="button"
          className=" flex justify-center items-center gap-2 w-full bg-whiteSmoke text-haiti p-3 my-4 font-semibold font-inter rounded-lg text-sm  text-center border border-dotted border-gray-600"
          onClick={addService}
        >
          <img src={Add} alt="Add" className="" />
          Add another service
        </button>
        {/* </div> */}
        <Button type="submit" className="mt-2">
          {loading ? "Loading..." : "Next"}
        </Button>
      </form>
    </>
  );
}

export default Services;
