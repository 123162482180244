import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const privacyPolicyApi = createApi({
  reducerPath: "privacyPolicyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints:(builder) => ({
    getUserPrivacyPolicy: builder.query({
        query: () => "/privacy-policy/all"
    })
  })


});

export const {useGetUserPrivacyPolicyQuery} = privacyPolicyApi
