import moment from "moment"

const getFormattedDate=(date)=>{
   return moment(date).format('MM/DD/YYYY')
}
const getFormattedTime=(time)=>{
    return moment(time,'h:mm a').format('h:mm a')
}

const compareDate = (posted, current) => {
    return moment(posted).isBefore(current)
}

export {getFormattedDate,getFormattedTime, compareDate}