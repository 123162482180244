import clsx from "clsx";
import React from "react";

const SingleService = ({heading,subHeading,img,mx='mx-3'}) => {
  return (
    <div className={clsx("bg-white h-full",mx)} data-aos="fade-up">
      <img src={img} className="w-full h-[250px] object-cover" alt="service-1" />
      <div className="p-3 ">
      <p className="text-2xl font-syne font-bold text-dune mt-2">{heading}</p>
      <p className="text-opacity-60 mt-2">
        {subHeading}
      </p>
      </div>
    </div>
  );
};

export default SingleService;
