import React from "react";
import { Navigate } from "react-router-dom";
import { userRole } from "../utils/currentUser";

function RoleRoute({ children, role }) {
 const user = userRole();
  return <>{user === role ? children : <Navigate to={`/${user}/dashboard`} />}</>;
}

export default RoleRoute;
