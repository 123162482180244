import React, { useState } from "react";
import DetailHeading from "../../../components/DetailHeading";
import CustomInput from "../../../components/CustomInput";
import User from "../../../assets/icons/user.svg";
import PhoneNumber from "../../../assets/icons/phoneNumber.svg";
import Location from "../../../assets/icons/Location.svg";
import License from "../../../assets/icons/license.svg";

import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { addPersonalDetail } from "../../../redux/slices/Profile";

import { useCompleteProfileMutation } from "../../../redux/api/completeProfile";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().min(3, "Must be 3 character").required("name is required"),
  contactNumber: Yup.string().required("Phone number is required"),
  streetAddress1: Yup.string().required("Address is required"),
  streetAddress2: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  zip: Yup.number().required("Zip Code is required"),
  State: Yup.string().required("State is required"),
  license_no: Yup.string().required("License No is required"),
  license_state: Yup.string().required("License State is required"),
});

function PersonalDetail({ next, finish }) {
  const [loading, setLoading] = useState(false);
  const details = useSelector((state) => state.completeProfile);
  const [completeProfile, result] = useCompleteProfileMutation();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      contactNumber: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      zip: "",
      State: "",
      license_no: "",
      license_state: "",
      company_name: "",
      license_start_data: "",
      license_expiry_data: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { data, message, status } = await completeProfile({
          name: values.name,
          phone: values.contactNumber,
          address: values.streetAddress1 + " " + values.streetAddress2,
          city: values.city,
          state: values.State,
          zip: values.zip,
          licence_no: values.license_no,
          company_name: values.company_name,
          licence_state: values.license_state,
          start_date: values.license_start_data,
          end_date: values.license_expiry_data,
          personal_information: 1,
          subscription: 0,
          services: 0,
          card: 0,
        }).unwrap();
        // dispatch(addPersonalDetail(values));
        if (status === 200) {
          next();
        }
        if(status === 400){
          toast.error(message)
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
        setLoading(false);
      }
    },
  });
  return (
    <>
      <DetailHeading color="#161140">Add personal details</DetailHeading>
      <form onSubmit={formik.handleSubmit}>
        <CustomInput
          heading="Name"
          type="text"
          placeholder="Enter Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          icon={User}
          htmlFor="name"
          error={formik.errors.name}
        />

        <CustomInput
          heading="Contact Number"
          type="text"
          placeholder="Enter Contact Number"
          value={formik.values.contactNumber}
          onChange={formik.handleChange}
          icon={PhoneNumber}
          htmlFor="contactNumber"
          error={formik.errors.contactNumber}
        />

        <CustomInput
          heading="License Number"
          type="text"
          placeholder="Enter license Number"
          value={formik.values.license_no}
          onChange={formik.handleChange}
          icon={License}
          htmlFor="license_no"
          error={formik.errors.license_no}
        />

        <CustomInput
          heading="License State"
          type="text"
          placeholder="Enter license State"
          value={formik.values.license_state}
          onChange={formik.handleChange}
          icon={License}
          htmlFor="license_state"
          error={formik.errors.license_state}
        />

        {/* <Datepicker/> */}

        <CustomInput
          heading="License Start Data"
          type="date"
          value={formik.values.license_start_data}
          onChange={formik.handleChange}
          htmlFor="license_start_data"
          error={formik.errors.license_start_data}
        />

        <CustomInput
          heading="License Expiry Data"
          type="date"
          value={formik.values.license_expiry_data}
          onChange={formik.handleChange}
          htmlFor="license_expiry_data"
          error={formik.errors.license_expiry_data}
        />

        <CustomInput
          heading="Company Name"
          type="text"
          placeholder="Enter company name"
          value={formik.values.company_name}
          onChange={formik.handleChange}
          icon={License}
          htmlFor="company_name"
          error={formik.errors.company_name}
        />

        <CustomInput
          heading="Address"
          type="text"
          placeholder="Street Address 1"
          value={formik.values.streetAddress1}
          onChange={formik.handleChange}
          icon={Location}
          htmlFor="streetAddress1"
          error={formik.errors.streetAddress1}
        />
        <CustomInput
          type="text"
          placeholder="Street Address 2"
          value={formik.values.streetAddress2}
          onChange={formik.handleChange}
          htmlFor="streetAddress2"
          error={formik.errors.streetAddress2}
        />
        <CustomInput
          type="text"
          placeholder="City"
          value={formik.values.city}
          onChange={formik.handleChange}
          htmlFor="city"
          error={formik.errors.city}
        />
        <div className="flex justify-between gap-2">
          <CustomInput
            type="text"
            placeholder="Zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            htmlFor="zip"
            error={formik.errors.zip}
          />
          <CustomInput
            type="text"
            placeholder="State"
            value={formik.values.State}
            onChange={formik.handleChange}
            htmlFor="State"
            error={formik.errors.zip}
          />
        </div>
        <Button type="submit" className="mt-2">
          {loading ? "Loading...":"Next"}
        </Button>
      </form>
    </>
  );
}

export default PersonalDetail;
