import React, { useState } from "react";
import {
  useGetServicesQuery,
  useGetRemainingServiesQuery,
} from "../../../redux/api/services";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import CustomModal from "../../../components/Modal";
import ServicesCategories from "./Categories";
import AddService from "./AddService";
import Button from "../../../components/Button";

const ContractorServices = () => {
  const [selectedServiceCategories, setSelectedServiceCategories] =
    useState("");
  const [openCategories, setOpenCategories] = useState(false);
  const [showAddService, setShowAddService] = useState(false);

  // const {
  //   data: userServices,
  //   isLoading,
  //   isError,
  //   error,
  // } = useGetServicesQuery();

  const {
    data: remainingServices,
    isLoading,
    isError,
    error,
  } = useGetRemainingServiesQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  const handleSelectedCategory = () => {
    setOpenCategories(false);
  };
  return (
    <>
      <CustomModal
        open={openCategories}
        setOpen={() => setOpenCategories(!openCategories)}
      >
        <ServicesCategories
          categories={selectedServiceCategories}
          handleSelectedCategory={handleSelectedCategory}
        />
      </CustomModal>
      <CustomModal
        open={showAddService}
        setOpen={() => setShowAddService((prev) => !prev)}
      >
        <AddService setShowAddService={setShowAddService} />
      </CustomModal>
      <div className="flex flex-col items-center justify-center py-2">
        <div className="max-w-4xl w-full">
          <div className="flex justify-end">
            <Button
              className="mt-2 w-auto"
              onClick={() => setShowAddService(true)}
            >
              Add Service
            </Button>
          </div>
          <div className=" mt-6 mr-3 grid grid-cols-1 sm:grid-cols-2 items-center gap-4 w-full">
            {remainingServices &&
              remainingServices.data.map(({ id, name, services }) => {
                return (
                  <div
                    className="flex item gap-3 shadow shadow-slate-300 p-3 rounded-lg w-full"
                    // onClick={() => {
                    // handleSubCategory(categories);
                    // }}
                    key={id}
                  >
                    {/* <Avatar img={image ? img_base_url + image : null} className='size-14 rounded-lg' alt={name} /> */}
                    <div className="w-full">
                      <p className="font-medium capitalize mt-1">{name}</p>
                      {/* <p>{desciption}</p> */}
                      <div className="flex justify-end items-end">
                        <p
                          className={
                            services.status === "accepted"
                              ? "font-medium capitalize text-white bg-green-400 rounded-full w-auto mt-2 px-2 inline-block"
                              : services.status === "pending"
                              ? "font-medium capitalize text-white bg-yellow-400 rounded-full w-auto mt-2 px-2 inline-block"
                              : "font-medium capitalize text-white bg-red-400 rounded-full w-auto mt-2 px-2 inline-block"
                          }
                        >
                          {services.status}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorServices;
