import React, { useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import SelectInput from "../../../../components/SelectInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import ImageUploader from "../../../../components/ImageUploader";
import { toast } from "react-toastify";
import { OFFERTYPE } from "../../../../utils/consts";
import axios from "axios";
import { getCurrentUser } from "../../../../utils/currentUser";
import { useSocket } from "../../../../utils/socket";

const validationSchema = Yup.object({
  offer_price: Yup.string(),
  estimated_hours: Yup.string(),
  description: Yup.string().required("Decription is required"),
  offer_type: Yup.string(),
});

function JobProposal({ job_id, setOpen }) {
  const [loading, setLoading] = useState(false);
  const socket = useSocket();

  const user = getCurrentUser();
  const { touched, handleChange, values, errors, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        offer_price: "",
        estimated_hours: "",

        description: "",
        offer_type: "",
        images: [],
      },
      validationSchema,
      onSubmit: async (values) => {
        setLoading(true)
        const {
          description,
          offer_price,
          estimated_hours,
          offer_type,
          images,
        } = values;

        const formData = new FormData();

        formData.append("job_id", parseInt(job_id));
        formData.append("offer_price", offer_price);
        formData.append("description", description);
        formData.append("estimated_hours", estimated_hours);
        if(offer_type){
          formData.append(
            "offer_type",
            OFFERTYPE.find((obj) => obj.id === parseInt(offer_type))?.name
          );
        }

        images.forEach((image, i) => {
          formData.append(`proposal_media[${i}]`, image);
        });

        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/contractor/create_proposal`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${user?.access_token}`,
              },
            }
          );

          if (data.status === 200) {
            socket.emit("notification_send", data.data);
            setOpen();
          }
          toast.success(data.message);
          setLoading(false)
        } catch (err) {
          console.error(err);
          toast.error(err.message);
          setLoading(false)
        }
      },
    });

  const handleImageChange = (e) => {
    if (e.target.files) {
      setFieldValue("images", [
        ...values.images,
        ...Array.from(e.target.files),
      ]);
    }
  };

  const handleDeleteImage = (id) => {
    const images = values.images.filter((_, i) => i !== id);
    setFieldValue("images", images);
};
  return (
    <form onSubmit={handleSubmit}>
      <CustomInput
        heading="Offer"
        type="textarea"
        placeholder="Describe your offer"
        value={values.description}
        onChange={handleChange}
        htmlFor="description"
        error={touched.description && errors.description}
      />

      <CustomInput
        heading="Estimated Cost ($)"
        type="number"
        placeholder="Write your cost"
        value={values.offer_price}
        onChange={handleChange}
        htmlFor="offer_price"
        error={touched.offer_price && errors.offer_price}
      />

      <CustomInput
        heading="Estimated Hours"
        type="number"
        placeholder="Write estimated hours"
        value={values.estimated_hours}
        onChange={handleChange}
        htmlFor="estimated_hours"
        error={touched.estimated_hours && errors.estimated_hours}
      />

      <SelectInput
        heading="Offer type"
        options={OFFERTYPE}
        htmlFor="offer_type"
        onChange={handleChange}
        value={values.offer_type}
        error={touched.offer_type && errors.offer_type}
      />

      <div className="my-2">
        <p className="text-haiti font-inter font-semibold mb-2">
          Upload Images
        </p>
        <ImageUploader
          images={values.images}
          handleImageChange={handleImageChange}
          handleDeleteImage={handleDeleteImage}
        />
        {touched.images && (
          <p className="text-red-900 font-inter text-base">{errors.images}</p>
        )}
      </div>

      <Button type="submit">{loading ? "Creating..." : "Submit"}</Button>
    </form>
  );
}

export default JobProposal;
