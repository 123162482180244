import React from "react";
import googlepay from "../../../../../assets/images/playstore.png";
import applestore from "../../../../../assets/images/applestore.png";
import clsx from "clsx";
import { Link } from "react-router-dom";

const homeOwnerDownloadDescription =
  "Zitro Connect ensures that homeowners can quickly and confidently select contractors who meet their specific needs and standards. The app provides detailed profiles, customer reviews, and ratings for each contractor, offering transparency and peace of mind. Zitro Connect makes finding the right professional both simple and stress-free";
const DownloadFrom = ({
  zitro,
  title,
  description = homeOwnerDownloadDescription,
  animateClass = "fade-left",
  playstoreLink='https://play.google.com/store/apps/details?id=com.zitro_contractor&pli=1',
  applestoreLink='https://apps.apple.com/us/app/pro-leads/id1661324379'
}) => {
  return (
    <div
      className={clsx("col-span-3", zitro && "order-2 md:order-1")}
      data-aos={animateClass}
    >
      <p className="font-syne text-2xl leading-6 sm:leading-10 sm:text-[36px] md:leading-[69px] md:text-[46px] font-bold md:w-2/3">
        {title || "Download Zitro Contractors App"}
      </p>
      <p className="text-opacity-60 text-base text-[18px] mt-3 text-dune">
        {description}
      </p>
      <div className="flex flex-wrap gap-4 mt-10">
        <Link to={playstoreLink} target="_blank">
        <img src={googlepay} className="max-size-48" alt="googelplay" />
        </Link>
        <Link to={applestoreLink} target="_blank">
        <img src={applestore} className="max-size-48" alt="applestore" />
        </Link>
      </div>
    </div>
  );
};

export default DownloadFrom;
