import React from "react";

const SingleTestomonial = ({name,profile_img,profession,headline}) => {
  return (
    <div className="mx-2 py-1.5">
    <div className="bg-polarWhite min-h-[300px] shadow-veilBlack p-4 rounded-2xl flex flex-col">
      <p className="text-battleshipGrey text-lg text-center">
        {headline}
      </p>
      <div className="flex flex-1 mt-auto flex-col items-center justify-end">
        <img src={profile_img} alt="profile-1" />
        <p className="font-syne text-[22px] font-bold mt-4">{name}</p>
        <p className="text-battleshipGrey">{profession}</p>
      </div>
    </div>
    </div>
  );
};

export default SingleTestomonial;
