import React from "react";
import Services from "./components/Services";
import HowItWorks from "./components/HowItWorks";
import Properties from "./components/Properties";
import HeroSection from "./components/HeroSection";
import Testomonials from "./components/Testomonials";
import WhyChoose from "./components/WhyChoose";
import DownloadZitro from "./components/DownloadZitro";
import DownloadZitro2 from "./components/DownloadZitro/Zitro";

const RootHome = () => {
  return (
    <div className="bg-white">
      <HeroSection/>
      <Services/>
      <HowItWorks/>
      <Properties/>
      <Testomonials/>
      <WhyChoose/>
      <DownloadZitro/>
      <DownloadZitro2/>
      {/* <Hero /> */}
    </div>
  );
};

export default RootHome;
