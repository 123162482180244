import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "flowbite-react";
import { contactFormDataDefaultValues } from "../../../contact-us";
import CustomButton from "../../../../components/CustomButton";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(contactFormDataDefaultValues);

  // handle form change
  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isFormValid = true;

    for (const formValue of Object.values(formData)) {
      if (!formValue) {
        isFormValid = false;
      }
    }
    if (isFormValid) {
      try {
        setIsLoading(true);
        const { data } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/support`,
          formData
        );
        toast.success(data.message);
        setFormData(contactFormDataDefaultValues);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <>
      <p className="text-[20px] font-syne font-semibold">Contact Us</p>
      <form onSubmit={handleSubmit} className="flex flex-col gap-3 mt-3">
        <input
          type="text"
          placeholder="Your Name"
          name="name"
          value={formData.name}
          onChange={({ target: { name, value } }) => handleChange(name, value)}
          className="border text-sm border-opacity-10 w-full border-black bg-transparent rounded-lg"
        />
        <input
          type="email"
          placeholder="Your email Address:"
          name="email"
          value={formData.email}
          onChange={({ target: { name, value } }) => handleChange(name, value)}
          className="border text-sm w-full border-opacity-10 border-black bg-transparent rounded-lg"
        />
        <textarea
          placeholder="Your message:"
          value={formData.description}
          onChange={({ target: { name, value } }) => handleChange(name, value)}
          className="border w-full text-sm border-opacity-10 border-black bg-transparent rounded-lg"
          rows={4}
          name="description"
        />
        <CustomButton
          type="submit"
          className="rounded-[50px] w-full bg-darkRoyalBlue font-semibold"
        >
          {isLoading ? <Spinner /> : " Send Message"}
        </CustomButton>
      </form>
    </>
  );
};

export default ContactUs;
