import React from "react";
import Container from "../../../../components/Container";
import DownloadFrom from "./components/DownloadFrom";
import AppImg from "./components/AppImg";

const DownloadZitro2 = () => {
  return (
    <div className="mt-20">
      <Container>
        <div className="grid md:grid-cols-5 gap-8 items-center">
          <DownloadFrom
            zitro
            animateClass="fade-right"
            description="For contractors, Zitro Connect serves as the ideal platform to discover new job opportunities and 
generate leads. The app enables contractors to create comprehensive profiles detailing their 
skills, experience, and specialties, making them easily discoverable to potential clients. Zitro 
Connect ensures a steady stream of projects, fostering growth and success for contractors"
          />
          <AppImg zitro animateClass="fade-left" />
        </div>
      </Container>
    </div>
  );
};

export default DownloadZitro2;
