import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const chatApi = createApi({
    reducerPath: "chatsApi",
    tagTypes: ['chats', 'chat'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            return authorizationHeader(headers, getState);
        },
    }),

    endpoints: (builder) => ({
        getAllChats: builder.query({
            query: () => "/get-current-chats",
        }),
        initiateChat: builder.mutation({
            query: (data) => ({
                url: "/initiate-chat",
                method: "POST",
                body: data,
            }),
        }),
        addMessage: builder.mutation({
            query: (data) => ({
                url: "/add-message",
                method: "POST",
                body: data,
            }),
            invalidatesTags: ['chat']
        }),
        getSingleChat: builder.mutation({
            query: ({data,page}) => ({
                url: `/get-chat?page=${page}`,
                method: "POST",
                body: data,
            }),
            providesTags: ['chat']
        }),
        findChat: builder.mutation({
            query: (data) => ({
                url: "/find-chat",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useGetAllChatsQuery,
    useGetSingleChatMutation,
    useAddMessageMutation,
    useInitiateChatMutation,
    useGetUserPostedJobsQuery,
    useFindChatMutation
} = chatApi;
