import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const jobsApi = createApi({
  reducerPath: "jobsApi",
  tagTypes: ["jobs"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    getPostedJobs: builder.query({
      query: (page = 1) => "/contractor/posted_jobs" + `?page=${page}`,
    }),
    getActiveJobs: builder.query({
      query: (page) => "/contractor/active_jobs" + `?page=${page}`,
    }),

    getCompletedJobs: builder.query({
      query: (page = 1) => "/contractor/completed_jobs" + `?page=${page}`,
    }),
    getJobInvites: builder.query({
      query: (page = 1) => "/contractor/job_invites" + `?page=${page}`,
    }),
    createJob: builder.mutation({
      query: (data) => ({
        url: "/user/create_job",
        method: "POST",
        body: data,
      }),
    }),
    declineJobInvite: builder.mutation({
      query: (data) => ({
        url: "/contractor/decline_job_invite",
        method: "POST",
        body: data,
      }),
    }),

    getSingleJobDetails: builder.mutation({
      query: (data) => ({
        url: "/job_details",
        method: "POST",
        body: data,
      }),
    }),

    getUserPostedJobs: builder.query({
      query: (page = 1) => "/user/posted_jobs" + `?page=${page}`,
      providesTags: ["jobs"],
    }),
    getUserActiveJobs: builder.query({
      query: (page = 1) => "/user/active_jobs" + `?page=${page}`,
      providesTags: ["active jobs"],
    }),
    getUserHistoryJobs: builder.query({
      query: (page = 1) => "/user/completed_jobs" + `?page=${page}`,
      providesTags: ["history jobs"],
    }),
    jobInviteDetail: builder.mutation({
      query: (data) => ({
        url: "/invite_job_details",
        method: "POST",
        body: data,
      }),
    }),
    contractorJobCompleteRequest: builder.mutation({
      query: (data) => ({
        url: "/contractor/job_complete_request",
        method: "POST",
        body: data,
      }),
    }),
    deleteJob: builder.mutation({
      query: (data) => ({
        url: "/user/delete_job",
        method: "POST",
        body:data
      }),
      invalidatesTags: ["jobs"],
    }),
    userJobCompletedRequest: builder.mutation({
      query: (data) => ({
        url: "/user/job_completed",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetPostedJobsQuery,
  useDeleteJobMutation,
  useGetActiveJobsQuery,
  useGetCompletedJobsQuery,
  useGetJobInvitesQuery,
  useCreateJobMutation,
  useGetSingleJobDetailsMutation,
  useDeclineJobInviteMutation,
  useGetUserPostedJobsQuery,
  useGetUserActiveJobsQuery,
  useGetUserHistoryJobsQuery,
  useJobInviteDetailMutation,
  useContractorJobCompleteRequestMutation,
  useUserJobCompletedRequestMutation,
} = jobsApi;
