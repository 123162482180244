import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  tagTypes: ['services','remaining-services'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => "/user/services",
      providesTags:['services']
    }),
    getRemainingServies: builder.query({
        query: () => "/contractor/get_services",
        providesTags:['remaining-services']
      }),
      getContractorRemainingServies: builder.query({
        query: () => "/contractor/remaining_service",
        providesTags:['contractor-remaining-services']
      }),
    addService:builder.mutation({
        query: (data) => ({
          url: "/contractor/add_service",
          method: "POST",
          body: data,
        }),
        invalidatesTags:['services','remaining-services','contractor-remaining-services']
      }),
  }),
});

export const {
  useGetServicesQuery,
  useGetRemainingServiesQuery,
  useGetContractorRemainingServiesQuery,
  useAddServiceMutation,
} = servicesApi;
