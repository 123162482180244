import clsx from 'clsx'
import React from 'react'
import { twMerge } from 'tw-merge'

const Container = ({children,className}) => {
  
  return (
    <div className={twMerge(clsx('container mx-auto px-4',className&&className))}>
        {children}
    </div>
  )
}

export default Container