import React from "react";
import Container from "../../components/Container";
import aboutUs from "../../assets/images/about-us.jpg";
import CustomButton from "../../components/CustomButton";
import HowItWorks from "../home/components/HowItWorks";

const AboutUs = () => {
  return (
    <Container>
      <div className="py-24">
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div>
            <h6 className="font-semibold text-6xl">About Us</h6>
            <CustomButton className="max-w-60 mt-5 p-2.5 font-normal text-xl">
              Get In Touch
            </CustomButton>
          </div>
          <img
            src={aboutUs}
            alt="aboutUs"
            className="max-h-80 rounded-lg w-full max-w-96 object-cover"
          />
        </div>

        <div className="mt-10">
          <HowItWorks />
        </div>

        <section class="py-12 mt-4">
          <div class="container mx-auto px-6">
            <h2 class="text-3xl font-semibold text-gray-800 text-center mb-8">
              Why Choose Zitro?
            </h2>
            <div class="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
              <div class="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 class="text-xl font-bold mb-2">
                  Experienced Professionals
                </h3>
                <p>
                  ur team consists of highly trained and certified professionals
                  who are experts in their respective fields.
                </p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 class="text-xl font-bold mb-2">Reliable Service</h3>
                <p>
                  We pride ourselves on punctuality and reliability, ensuring
                  that your home maintenance needs are met on time, every time.
                </p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 class="text-xl font-bold mb-2">Quality Workmanship</h3>
                <p>
                  We pride ourselves on punctuality and reliability, ensuring
                  that your home maintenance needs are met on time, every time.
                </p>
              </div>
              <div class="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 class="text-xl font-bold mb-2">Customer Satisfaction</h3>
                <p>
                  Your satisfaction is our top priority. We work closely with
                  you to understand your needs and exceed your expectations.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default AboutUs;
