import React, { useRef } from "react";
import SingleTestomonial from "./SingleTestomonial";
import profile from "../../../../assets/images/profile1.png";
import profile2 from "../../../../assets/images/profile2.png";
import profile3 from "../../../../assets/images/profile3.png";
import arrowLeft from "../../../../assets/icons/ArrowLeft.svg";
import arrowRight from "../../../../assets/icons/ArrowRight.svg";
import Carousel from "react-multi-carousel";
import Container from "../../../../components/Container";

const testomonialsData = [
  {
    profile_img: profile,
    headline:
      "Zitro Connect helped me find a reliable contractor to help fix my roof!",
    name: "Rowhan Marlin",
    profession: "Homeowner",
  },
  {
    profile_img: profile2,
    headline:
      "Zitro Connect helped me gain more leads for my new construction business by connecting me with homeowners who needed work done on their house!",
    name: "Jay Simon",
    profession: "Contractor",
  },
  {
    profile_img: profile3,
    headline:
      "Excellent app - it's super easy to use and really helped me level up my construction business!",
    name: "Jim Nejy",
    profession: "Contractor",
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Testomonials = () => {
  const carouselRef = useRef();
  return (
    <div className="py-14 bg-eclipseBlue">
      <Container>
        <h6 data-aos="fade-down" className="text-center text-4xl sm:text-[56px] font-bold font-syne">
          Testimonials
        </h6>
        <div className="mt-20" data-aos="fade-up">
        <Carousel ref={carouselRef} arrows={false} responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>
          {[...testomonialsData, ...testomonialsData].map((data, i) => (
            <SingleTestomonial {...data} key={i} />
          ))}
        </Carousel>
        </div>
        <div className="flex justify-center mt-10 gap-4" data-aos="fade-up">
          <button
            onClick={() => {
              if (carouselRef.current.state.currentSlide > 0) {
                const prevSlide = carouselRef.current.state.currentSlide - 1;
                carouselRef.current.goToSlide(prevSlide);
              }
            }}
          >
            <img
              className="bg-haiti cursor-pointer rounded-full p-2"
              src={arrowLeft}
              alt="prev"
            />
          </button>
          <button
            onClick={() => {
              if (
                carouselRef.current.state.currentSlide <
                testomonialsData.length - 1
              ) {
                const nextSlide = carouselRef.current.state.currentSlide + 1;
                carouselRef.current.next();
                carouselRef.current.goToSlide(nextSlide);
              }
            }}
          >
            <img
              className="bg-haiti cursor-pointer rounded-full p-2"
              src={arrowRight}
              alt="next"
            />
          </button>
        </div>
      </Container>
    </div>
  );
};

export default Testomonials;
