import React, { useState } from "react";
import Card from "../../../../components/Card";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../../utils/Path";
import NoData from "../../../../components/NoData";
import { useGetContractorProposalsQuery } from "../../../../redux/api/Proposal";
import { Spinner } from "flowbite-react";
import PaginationComponent from "../../../../components/Pagination";

function ProposalJobs() {
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const { data: contractorProposalJobs, isLoading } = useGetContractorProposalsQuery(currentPage,{refetchOnMountOrArgChange:true});

  const handleNavigation = (id, proposal_id) => {
    navigate(`${PATH.CONTRACTORJOB}/${id}`, { state: { proposals: true, proposal_id } });
  };
  const data = contractorProposalJobs?.data?.proposals?.data;

  return (
    <div>
      {isLoading ? (
        <div className="w-full items-center flex justify-center">
          <Spinner />
        </div>
      ):data?.length === 0 ? (
         <NoData message='No Job Found' />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4    justify-center">
          {data?.map((obj, i) => (
            <div key={i}>
              <Card
                proposals={true}
                data={obj?.job}
                onClick={() => handleNavigation(obj.job_id, obj.id)}
              />
            </div>
          ))}
        </div>
      )}
      {
        contractorProposalJobs && (contractorProposalJobs.data.proposals.last_page>1? (
          <PaginationComponent currentPage={currentPage} totalPages={contractorProposalJobs?.data?.proposals.last_page} onPageChange={(page)=>setCurrentPage(page)}/>
        ):null)
      }
    </div>
  );
}

export default ProposalJobs;
