import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user:{},
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    storeCurrentUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default currentUserSlice.reducer;
export const { storeCurrentUser } = currentUserSlice.actions;
