import React, { useEffect, useState,useRef } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
  InfoWindow,
} from "@react-google-maps/api";
import { fromLatLng, setKey, setDefaults, fromAddress } from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { IoMdCloseCircleOutline } from "react-icons/io";

const libraries = ["places"];
function Map({children, coordinates, handleLocation, handleGetAddress, address, job }) {
  const [value, setValue] = useState(null);
  const [showClearAddress, setShowClearAddress] = useState(false)
  const placeAutoCompleteRef=useRef();
  const mapContainerStyle = {
    width: "100%",
    height: "200px",
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const getAddress = (addressArray) => {
    let address = "";
    addressArray.map((obj) => {
      address += `${obj.long_name} `;
    });

    return address;
  };

  const getLatLng = (location) => {
    return location[0].geometry.location;
  };

  const handleDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    handleLocation({
      lat: newLat,
      lng: newLng,
    });

    fromLatLng(newLat, newLng).then(({ results }) => {
      const addressArray = results[0].address_components;
      handleGetAddress(getAddress(addressArray));
    });
  };
  const placeChanged = (e) => {
    setValue(handleGetAddress(e.label));
    setShowClearAddress(true);
    fromAddress(e.label).then(({ results }) => {
      const tempLocation = results[0]?.geometry?.location;
      if (tempLocation) {
        handleLocation({
          lat: tempLocation.lat,
          lng: tempLocation.lng,
        });
      }
    });
  };

  const cleargooglePlacesAutocomplete=()=>{
  setValue('');
  setShowClearAddress(false);
  handleGetAddress('');
  }

  return (
    <div className="relative">
      <div className="relative z-10">
        <GooglePlacesAutocomplete
        clear={true}
          apiKey={process.env.REACT_APP_MAP_KEY}
          ref={placeAutoCompleteRef}
          selectProps={{
            onChange: placeChanged,
            value,
            className: "place-select",
          }}
        />
        {children}
        {
          showClearAddress && (
            <button type="button" className="absolute z-10 top-2.5 right-10" onClick={cleargooglePlacesAutocomplete}><IoMdCloseCircleOutline className="size-5 text-lavaRed" />
        </button>
          )
        }
      </div>

      {!job && (
        <p className="font-inter p-4">
          <span className="text-haiti"> Address </span>: {address}
        </p>
      )}
      <div className="relative z-1">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={14}
          center={coordinates}
          className="h-5"
        >
          <MarkerF position={coordinates} draggable onDragEnd={handleDragEnd} />
        </GoogleMap>
      </div>
    </div>
  );
}

export default Map;
