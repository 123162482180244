import React, { useState } from "react";

function ServicesCategories({ categories }) {
  return (
    <div className="mt-6">
      <p className="font-inter text-haiti text-base text-center mt-2">
      </p>
      {categories?.map(({id,name}) => (
        <p
        className="border p-3 m-2 rounded-lg font-inter cursor-pointer bg-whiteSmoke" key={id}
        >
          {name}
        </p>
      ))}
    </div>
  );
}

export default ServicesCategories;
