import React from "react";
import ErrorMessage from "./ErrorMessage";

function CustomInput({
  heading,
  type,
  value,
  onChange,
  icon,
  placeholder,
  htmlFor,
  error,
  disabled = false,
  className,
  minTime,
  maxTime,
}) {
  const isTextarea = type === "textarea";

  return (
    <div className={className}>
      <div>
        <label
          htmlFor={htmlFor}
          className="block mb-2 text-base font-medium font-inter  "
        >
          {heading && heading}
        </label>
        <div className="flex relative">
          {isTextarea ? (
            <textarea
              name={htmlFor}
              id={htmlFor}
              disabled={disabled}
              className="bg-whiteSmoke mb-2 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5   "
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              rows={5}
            />
          ) : (
            <input
              type={type}
              name={htmlFor}
              min={["start_time" , "end_time"].includes(htmlFor) && minTime}
              max={htmlFor === "end_time" && maxTime}
              id={htmlFor}
              disabled={disabled}
              className="bg-whiteSmoke mb-2 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5   "
              placeholder={placeholder}
              required=""
              onChange={onChange}
              value={value}
            />
          )}
          {icon && (
            <img
              src={icon}
              className="absolute right-3 top-2.5 w-5 h-5"
              alt="icon"
            />
          )}{" "}
        </div>
        <ErrorMessage error={error} />
      </div>
    </div>
  );
}

export default CustomInput;
