import React, { useState } from "react";
import Logo from "../../../assets/icons/Framelogo.svg";

import StepperComponent from "./Stepper";
import PersonalDetail from "./PersonalDetail";
import Services from "./Services";
import Bank from "./Bank";
import Card from "./Card";
import { useCompleteProfileMutation } from "../../../redux/api/completeProfile";
import { useDispatch, useSelector } from "react-redux";

const STEP = ["Complete Profile", "Add Service", "Add Bank", "Add Card"];
function ContractorDetail() {
  const [completeProfile, result] = useCompleteProfileMutation();
  const details = useSelector((state) => state.completeProfile);

  const [step, setSteps] = useState(0);
  const RenderComponent = [PersonalDetail, Services, Bank, Card];
  const CurrentStep = RenderComponent[step];

  const nextStep = () => {
    setSteps(step + 1);
  };

  const handleCompleteProfile = async (plan) => {
   
    try {
      const { data, message, status } = await completeProfile({
        ...details,
        plan_type: plan,
        personal_information: 0,
        subscription: 0,
        services: 0,
      }).unwrap();
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="grid  grid-cols-1 sm:grid-cols-2 h-screen">
      <div className="h-full flex justify-center items-center bg-haiti">
        <div className="shadow-md p-4 bg-white rounded-md">
          <img src={Logo} alt="logo" />
        </div>
      </div>
      <section className="flex justify-center items-center">
        <div className="max-w-[500px] w-full  bg-white rounded-lg  h-[100vh] overflow-y-auto ">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <p className="text-haiti text-3xl text-center font-inter font-semibold">
              {STEP[step]}
            </p>
            <StepperComponent currentStep={step} />
            <CurrentStep
              next={nextStep}
              finish={handleCompleteProfile}
              step={step}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContractorDetail;
