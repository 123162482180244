import React, { useState } from "react";
import OtpInput from "react-otp-input";
import CustomButton from "../../components/CustomButton";
import {
  useForgotPasswordEmailVerifyMutation,
  useResendEmailVerificationMutation,
  useVerifyEmailMutation,
} from "../../redux/api/Auth";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH } from "../../utils/Path";
import { storeCurrentUser } from "../../redux/slices/currentUser";
import { UseDispatch, useDispatch } from "react-redux";
import { setCurrentUser } from "../../utils/currentUser";

function Verifacation() {
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [resendEmail] = useResendEmailVerificationMutation();
  const [verifyForgotPassword, { isLoading: loading }] =
    useForgotPasswordEmailVerifyMutation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser?.user);
  const [otp, setOtp] = useState("");
  const handleSubmit = async () => {
    if (state?.forgotPassword) {
      verifyForgotPasswordEmail();
      return;
    }
    try {
      const { data, status, message } = await verifyEmail({
        email: user.email,
        otp: otp,
      }).unwrap();
      setCurrentUser(data);
      if (status === 200) {
        if (
          Array.isArray(data?.user) &&
          data.user[0]?.user_type === "contractor"
        ) {
          if (data.user[0]?.services?.length === 0) {
            navigate(PATH.CONTRACTORDETAIL);
            return;
          } else {
            navigate(PATH.CONTRACTORHOME);
          }
        } else {
          navigate(PATH.USERHOME);
        }
        dispatch(storeCurrentUser(data.user));
      }
      toast.success(message);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleResendEmail = async () => {
    try {
      const { data, message, status } = await resendEmail({
        email: user.email,
      }).unwrap();
      toast.success(message);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const verifyForgotPasswordEmail = async () => {
    const email = JSON.parse(localStorage.getItem("userEmail"));
    try {
      const { data, message, status } = await verifyForgotPassword({
        email,
        otp,
      }).unwrap();
      if (status === 200) {
        toast.success(message);
        navigate(PATH.RESETPASSWORD)
      }
      if (status === 400) {
        toast.error(message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <>
      <section className="h-[100vh] flex justify-center items-center overflow-y-auto px-4 ">
        <div className="space-y-4 md:space-y-6 w-full">
          <p className="font-inter tet-haiti font-extrabold text-3xl text-center">
            OTP Verification
          </p>
          <p className="text-sm  text-center font-medium font-inter">
            Enter the OTP sent to the email
          </p>
          <OtpInput
            containerStyle="flex justify-center flex-wrap"
            inputStyle={{
              width: "3.5rem",
              height: "3.5rem",
              margin: "0 0.5rem",
              fontSize: "1.5rem",
              border: "none",
              outline: "none",
              textAlign: "center",
              padding: "0.25rem",
              borderRadius: "5px",
              backgroundColor: "#E8E8E8	",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />

          <CustomButton onClick={handleSubmit}>
            {" "}
            {isLoading || loading ? "Submitting..." : "Submit"}{" "}
          </CustomButton>
          <p
            className="underline text-haiti text-xl text-end cursor-pointer font-inter"
            onClick={handleResendEmail}
          >
            Resend
          </p>
        </div>
      </section>
    </>
  );
}

export default Verifacation;
