import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "login",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (data) => ({
        url: "/register",
        method: "POST",
        body: data,
      }),
    }),

    loginUser: builder.mutation({
      query: (data) => ({
        url: "/login",
        method: "POST",
        body: data,
      }),
    }),
    socialLogin: builder.mutation({
      query: (data) => ({
        url: "/social_login",
        method: "POST",
        body: data,
      }),
    }),
    verifyEmail: builder.mutation({
      query: (data) => ({
        url: "/verify_email",
        method: "POST",
        body: data,
      }),
    }),

    resendEmailVerification: builder.mutation({
      query: (data) => ({
        url: `resend_email_verification_otp?email=${data.email}`,
        method: "POST",
        body: data,
      }),
    }),


    forgotPassword: builder.mutation({
      query: (data) => ({
        url:`/forgot_password?email=${data.email}`,
        method:"POST",
        body:data
      })
    }),

    forgotPasswordEmailVerify: builder.mutation({
      query: (data) => ({
        url:"/forgot_password_verify_otp",
        method:"POST",
        body:data
      })
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url:`/set_new_password?email=${data.email}`,
        method:"POST",
        body:data
      })
    })
  }),
});

export const {
  useCreateUserMutation,
  useLoginUserMutation,
  useVerifyEmailMutation,
  useSocialLoginMutation,
  useResendEmailVerificationMutation,
  useForgotPasswordMutation,
  useForgotPasswordEmailVerifyMutation,
  useResetPasswordMutation
} = authApi;
