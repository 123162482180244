import React, { useCallback, useState } from "react";
import { Avatar, Spinner } from "flowbite-react";
import { IoIosTime } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useGetJobProposalDetailMutation } from "../../../../redux/api/Proposal";
import { useEffect } from "react";
import CarouselComponent from "../../../../components/Carousel";
import CustomButton from "../../../../components/CustomButton";
import clsx from "clsx";

function ProposalDetail() {
  const { state } = useLocation();
  const [proposal, setProposal] = useState();
  const [proposalDetail, { isLoading }] = useGetJobProposalDetailMutation();

  const {
    offer_type = "",
    offer_price = 0,
    description = "",
    status = "",
    contractors = {},
    estimated_hours = 0,
    media = [],
  } = proposal?.[0] || {};
  const detail = useCallback(async () => {
    const { data } = await proposalDetail({
      proposal_id: state.id,
    }).unwrap();
    setProposal(data);
  }, [state.id]);

  useEffect(() => {
    detail();
  }, [state.id]);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[100vh]">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="shadow-lg rounded-lg bg-white flex flex-col  p-4 my-4 mx-auto w-[95%] md:w-[80%]">
            <div className="flex justify-between">
              {/* Header Area */}
              <div className="flex gap-2 items-center">
                {/* Image div */}
                <div className=" w-10 h-10 ">
                  <Avatar rounded />
                </div>

                {/* Header text Area */}
                <div>
                  {/* <p className="font-inter font-semibold text-base">{title}</p> */}
                  <div className="flex gap-4 ">
                    <p className="font-inter font-semibold text-lg ">
                      {contractors.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-3">
                <div>
                  <CustomButton
                    type="button"
                    className={clsx(
                      "rounded-lg capitalize p-2",
                      status === "rejected" && "bg-[#f980808c]  text-[#ff0000]",
                      status === "pending" && "bg-black text-white"
                    )}
                  >
                    {status}
                  </CustomButton>
                </div>
                <div>
                  <p className="text-xs font-inter">{offer_type}</p>
                  <p className="font-bold font-inter">
                    {offer_price ? `$${offer_price}` : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            {estimated_hours && (
              <div className="flex items-center gap-2 mt-2">
                <div>
                  <IoIosTime />
                </div>
                <div>
                  <p className="text-xs font-inter">Estimated Time</p>
                  <p className="font-semibold font-inter">{estimated_hours}</p>
                </div>
              </div>
            )}

            <div className="mt-2">
              <p className="font-inter text-lg font-semibold">Description</p>
              <p className="font-inter font-normal text-sm">{description}</p>
            </div>

            {/* <Link to={PATH.CONTRACTORJOBDetail}>
            <CustomButton className="w-fit mt-2">Back</CustomButton>
          </Link> */}
            <div className="mt-3 ">
              <CarouselComponent media={media} className="max-h-[240px]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProposalDetail;
