import React from "react";
import Container from "../../../../components/Container";
import AppImg from "./components/AppImg";
import DownloadFrom from "./components/DownloadFrom";

const DownloadZitro = () => {
  return (
    <div className=" py-12 mt-14 bg-eclipseBlue">
      <Container>
        <div className="grid md:grid-cols-5 gap-8 items-center">
          <AppImg />
          <DownloadFrom
            title="Download Zitro Homeowners App"
            playstoreLink="https://play.google.com/store/apps/details?id=com.zitro_homeowner"
            applestoreLink="https://apps.apple.com/us/app/zitroconnect-homeowner/id1660953367"
          />
        </div>
      </Container>
    </div>
  );
};

export default DownloadZitro;
