import React from "react";
import Logo from "../assets/images/logo.png";
import { homeNavLinks } from "../utils/consts";
import { Navbar as FlowbiteNavbar } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="border-b border-black border-opacity-10 bg-alabaster w-full" data-aos="fade-up">
      <FlowbiteNavbar
        theme={customNavTheme}
        className="container bg-transparent px-4 mx-auto "
      >
        <FlowbiteNavbar.Brand
          className="cursor-pointer max-w-[56px]"
          onClick={() => navigate("/home")}
        >
          <img src={Logo} width={100} />
        </FlowbiteNavbar.Brand>
        <FlowbiteNavbar.Toggle />
        <FlowbiteNavbar.Collapse>
          {homeNavLinks.map(({ id, path, name }) => {
            return (
              <FlowbiteNavbar.Link
                className="p-0 cursor-pointer font-normal text-dune"
                key={id}
              >
                <span
                  id={id}
                  to={path}
                  onClick={() => navigate(path)}
                  className="text-base flex items-center transition hover:text-lavaRed px-3"
                >
                  {name}
                </span>
              </FlowbiteNavbar.Link>
            );
          })}
          <div className="flex gap-3 md:hidden">
            <button
              onClick={() => navigate("/login")}
              className="p-2 font-syne px-5 rounded-[24px] text-lavaRed border border-lavaRed"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="p-2 font-syne px-5 rounded-[24px] text-white bg-haiti"
            >
              Sign up
            </button>
          </div>
        </FlowbiteNavbar.Collapse>
        <FlowbiteNavbar.Brand className="py-2 cursor-pointer md:mt-0 hidden md:block">
          <div className="flex gap-3">
            <button
              onClick={() => navigate("/login")}
              className="p-2 font-syne px-5 rounded-[24px] text-lavaRed border border-lavaRed"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="p-2 font-syne px-5 rounded-[24px] text-white bg-haiti"
            >
              Sign up
            </button>
          </div>
        </FlowbiteNavbar.Brand>
      </FlowbiteNavbar>
    </div>
  );
};

export default Navbar;

const customNavTheme = {
  root: {
    base: "bg-white px-2 py-1.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4",
  },
  collapse: {
    list: "mt-4 flex gap-2 py-3 flex-col md:items-center md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium",
  },
};
