import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const contractorApi = createApi({
  reducerPath: "contractorApi",
  tagTypes: ['licences','remaining-licences'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    findContractors:builder.mutation({
      query: (data) => ({
        url: "/user/find_contractors",
        method: "POST",
        body: data,
      }),
      invalidatesTags:['licences']
    }),
    addLicence:builder.mutation({
        query: (data) => ({
          url: "/contractor/add_licence_no",
          method: "POST",
          body: data,
        }),
        invalidatesTags:['licences']
      }),
      deleteLicence:builder.mutation({
        query: (data) => ({
          url: "/contractor/delete_licence_no",
          method: "POST",
          body: data,
        }),
        invalidatesTags:['licences']
      }),
  }),
});

export const {
  useFindContractorsMutation,
  useAddLicenceMutation,
  useDeleteLicenceMutation,
} = contractorApi;
