import React, { useEffect } from "react";
import { servicesData } from "../home/components/Services/data";
import SingleService from "../home/components/Services/SingleService";
import Container from "../../components/Container";
import construction from "../../assets/images/construction.gif";
import CustomButton from "../../components/CustomButton";

const Services = () => {
  useEffect(() => {
    window.scroll(0,0);
  },[]);
  return (
    <Container>
      <div className="py-24">
        <div className="flex gap-4 justify-between items-center mt-10">
          <div data-aos="fade-right">
            <h6 className="font-semibold text-6xl">
              Seamless Construction Execution
            </h6>
            <CustomButton className="max-w-60 mt-5 p-2.5 font-normal text-xl">
              Get In Touch
            </CustomButton>
          </div>
          <img
            src={construction}
            alt="construction"
            data-aos="fade-left"
            className="max-h-80 rounded-lg"
          />
        </div>
        <div className="mt-20" data-aos="fade-up">
          <h6 className="font-semibold text-4xl">
            Our Home Interior and Exterior Services & Solutions
          </h6>
          <p className="mt-2">
            Our comprehensive project management services ensure that every
            aspect of your construction project is handled with precision and
            expertise. We oversee the entire process from initial planning and
            design to final execution, ensuring that all phases are completed on
            time and within budget.
          </p>
        </div>
        <div className="grid grid-cols-3 mt-20 gap-6">
          {servicesData.map((serviceData, i) => (
            <div key={i} className="col-span-1">
              <SingleService mx="mx-0" {...serviceData} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Services;
