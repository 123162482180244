import React, { useState } from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const CustomPagination = ({ totalPages, currentPage, onPageChange }) => {
const [offset, setOffset] = useState(0);
const [limit, setLimit] = useState(4)

  const paginationItems=Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="flex justify-center gap-2 sm:gap-4 items-center mx-auto bg-whiteSmoke w-auto p-2 rounded-lg">
      <button
        onClick={() => {
          onPageChange(prev=>prev-1);
          setOffset(prev=>prev-1);
          setLimit(prev=>prev-1)
        }}
        disabled={currentPage===1}
        className={`mx-1 px-1 py-1 border border-gray-300 ${
          currentPage === 1 ? "bg-[#e4e4ee]" : "bg-haiti"
        } rounded-lg`}
      >
        <GrFormPrevious className="w-6 h-6 text-white" />
      </button>

      {paginationItems.slice(offset,limit).map((page) => (
        <button
          key={page}
          onClick={() => {
            onPageChange(page);
           
          }}
          className={`mx-1 px-3 py-1 border border-gray-300 rounded-lg ${
            currentPage === (page) ? "bg-haiti opacity-90 text-white" : ""
          }`}
        >
          {page}
        </button>
      ))}

      <button
        onClick={() => {
          onPageChange(prev=>prev+1);
          setOffset(prev=>prev+1);
          setLimit(prev=>prev+1)
        }}
        className={`mx-1 px-1 py-1 rounded-lg border border-gray-300 ${
          currentPage === totalPages ? "bg-[#e4e4ee]" : "bg-haiti"
        } `}
        disabled={limit>=totalPages}
      >
        <GrFormNext className="w-6 h-6 text-white" />
      </button>
    </div>
  );
};

function PaginationComponent({ currentPage,totalPages, onPageChange }) {
  return (
    <div className=" p-4">
      <CustomPagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}

export default PaginationComponent;
