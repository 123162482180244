import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

export const licenceApi = createApi({
  reducerPath: "licenceApi",
  tagTypes: ['licences','remaining-licences'],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    getLicences: builder.query({
      query: () => "/contractor/get_licence_no",
      providesTags:['licences']
    }),
    addLicence:builder.mutation({
        query: (data) => ({
          url: "/contractor/add_licence_no",
          method: "POST",
          body: data,
        }),
        invalidatesTags:['licences']
      }),
      deleteLicence:builder.mutation({
        query: (data) => ({
          url: "/contractor/delete_licence_no",
          method: "POST",
          body: data,
        }),
        invalidatesTags:['licences']
      }),
  }),
});

export const {
  useGetLicencesQuery,
  useAddLicenceMutation,
  useDeleteLicenceMutation,
} = licenceApi;
