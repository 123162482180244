export const OFFERTYPE = [
  { id: 1, name: "hourly" },
  { id: 2, name: "fixed" },
];

export const navLinks = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About us",
    path: "#",
  },
  {
    id: 6,
    name: "Privacy Policy",
    path: "/user/privacy_policy",
  },
  {
    id: 3,
    name: "Contact us",
    path: "#",
  },
  {
    id: 4,
    name: "Register",
    path: "/signup",
  },
  {
    id: 5,
    name: "Login",
    path: "/login",
  },
];

export const homeNavLinks = [
  {
    id: 1,
    name: "Home",
    path: "/home",
  },
  {
    id: 2,
    name: "Services",
    path: "/services",
  },
  {
    id: 3,
    name: "About us",
    path: "/about-us",
  },
  // {
  //   id: 5,
  //   name: "Blog",
  //   path: "#",
  // },
  {
    id: 4,
    name: "Contact us",
    path: "/contact-us",
  },
];

export let notificationData = [
  {
    id: 1,
    name: "Notifi 1",
  },
  {
    id: 2,
    name: "Notifi 2",
  },
  {
    id: 3,
    name: "Notifi 3",
  },
];
