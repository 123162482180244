import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages:0,
  userNotifications:0
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNewMessage: (state) => {
      state.messages =state.messages+1;
    },
    addNewNotification:(state)=>{
        state.userNotifications=state.userNotifications+1;
    },
    resetMessages: (state) => {
      state.messages=0;
      },
      resetNotifications: (state) => {
       state.userNotifications=0
       },
  },
});

export default notificationSlice.reducer;
export const { addNewMessage,resetMessages,addNewNotification,resetNotifications } = notificationSlice.actions;
