import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import CustomInput from "../../../../components/CustomInput";

import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import { useContractorReviewMutation } from "../../../../redux/api/Review";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../../../utils/socket";

const validationSchema = Yup.object({
  contractor_feedback: Yup.string().required("Feedback is required"),
});

function ContractorFeedback({ job_id }) {
  const [rating, setRating] = useState(0);
  const [rattingError, setRattingError] = useState("");
  const [contractorReview, { isLoading }] = useContractorReviewMutation();
  const navigate = useNavigate();
  const socket = useSocket();

  const { touched, handleChange, values, errors, handleSubmit } =
    useFormik({
      initialValues: {
        contractor_feedback: "",
      },
      validationSchema,
      onSubmit: async (values) => {
        if (rating === 0) {
          setRattingError("Rating is required");
          return;
        }
        try {
          const { data, status } = await contractorReview({
            job_id,
            contractor_feedback: values.contractor_feedback,
            contractor_rating: rating,
          }).unwrap();
          if(status === 200){
            socket.emit("notification_send", data);
            navigate('/contractor/jobs')
          }
          toast.success('Review Submitted')
        } catch (err) {
          console.log(err);
          toast.error(err.message);
        }
      },
    });

  const handleRating = (rate) => {
    setRating(rate);
    setRattingError("");
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="flex items-center justify-between">
        <p className="font-semibold font-inter ">Rating</p>
        <Rating onClick={handleRating} SVGstyle={{ display: "inline" }} />
      </div>
      <p className="font-normal text-red-700">{rattingError}</p>

      <CustomInput
        heading="Feedback"
        type="textarea"
        htmlFor="contractor_feedback"
        placeholder="Type here"
        onChange={handleChange}
        value={values.contractor_feedback}
        error={touched.contractor_feedback && errors.contractor_feedback}
        />
      <Button type="submit">
        {isLoading ? "Submitting..." : "Submit Review"}
      </Button>
      
    </form>
  );
}

export default ContractorFeedback;
