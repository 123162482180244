import Layout from "./PrivacyLayout";

const Card = ({ children }) => {
  return (
    <div className="bg-white shadow rounded-lg p-4  m-10 border">
      <Layout />
      <div dangerouslySetInnerHTML={{ __html: children }}></div>
    </div>
  );
};

export default Card;
