import React, { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button";
import Email from "../../../assets/icons/email.svg";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getCurrentUser } from "../../../utils/currentUser";
import PhoneNumber from "../../../assets/icons/phoneNumber.svg";
import { profileApi, useGetUserProfileQuery } from "../../../redux/api/profile";
import Loader from "../../../components/Loader";
import Error from "../../../components/Error";
import ProfileUploader from "../../../components/ProfileUploader";
import { base_url } from "../../../utils";

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone is required"),
    image: Yup.mixed().required('File is required'),
});

function ContractorProfile() {
    const [loading, setLoading] = useState(false);
    const { data: profileData, isLoading, isError, error } = useGetUserProfileQuery();

    const dispatch = useDispatch();
    const user = getCurrentUser();

    const { data: { name = '', email = '', phone = '', image = '' } = {} } = profileData || {};

    const { touched, handleChange, values, errors, setFieldValue, handleSubmit } =
        useFormik({
            initialValues: {
                email,
                name,
                phone,
                image,
            },
            enableReinitialize: true,
            validationSchema,
            onSubmit: async (values) => {
                setLoading(true);
                const {
                    name,
                    phone,
                    image,
                } = values;
                let formData = new FormData();

                formData.append("name", name);
                formData.append("phone", phone);
                formData.append('image', image);

                // make post request
                try {
                    const { data } = await axios.post(
                        `${base_url}/update_profile`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${user?.access_token}`,
                            },
                        }
                    );

                    dispatch(profileApi.util.invalidateTags(['profile']))

                    toast.success(data.message);
                    setLoading(false);
                } catch (err) {
                    console.log(err);
                    toast.error(err.message);
                    setLoading(false);
                }
            },
        });

    const handleImageChange = (e) => {
        if (e.target.files) {
            setFieldValue("images", [
                ...values.images,
                ...Array.from(e.target.files),
            ]);
        }
    };

    const handleDeleteImage = (id) => {
        const images = values.images.filter((_, i) => i !== id);
        setFieldValue("images", images);
    };

    if (isLoading) {
        return <Loader />
    }

    if (isError) {
        return <Error error={error} />
    }
    return (
        <div className="flex justify-center items-center min-h-[calc(100vh-66px)]">
            <form onSubmit={handleSubmit} className="max-w-lg flex-1 w-full bg-white p-3 rounded-xl">
                <div className="mb-2">
                    <ProfileUploader isError={errors.image && touched.image} image={values.image} handleChange={(img) => setFieldValue('image', img)} />

                </div>
                <CustomInput
                    heading="Email"
                    type="email"
                    placeholder="Enter email"
                    value={values.email}
                    onChange={handleChange}
                    icon={Email}
                    disabled
                    htmlFor="email"
                    error={errors.email}
                />
                <CustomInput
                    heading="Name"
                    type="text"
                    placeholder="Enter Name"
                    value={values.name}
                    onChange={handleChange}
                    htmlFor="name"
                    error={errors.name}
                />
                <CustomInput
                    heading="Phone"
                    type="phone"
                    placeholder="Enter Phone"
                    value={values.phone}
                    onChange={handleChange}
                    icon={PhoneNumber}
                    htmlFor="phone"
                    error={errors.phone}
                />
                <Button disabled={isLoading} className='mt-3' type="submit">{loading ? "Update..." : "Update Profile"}</Button>
            </form>
        </div>
    );
}

export default ContractorProfile;
