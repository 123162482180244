import React, { useState } from "react";
import Confirmation from "../../../../assets/icons/Confirmation.svg";
import CustomButton from "../../../../components/CustomButton";
import { useContractorJobCompleteRequestMutation } from "../../../../redux/api/Jobs";
import ContractorFeedback from "./Feedback";
import { toast } from "react-toastify";
import { useSocket } from "../../../../utils/socket";

function ConfirmJob({ job_id, closeModal }) {
  const socket = useSocket();

  const [completeJobRequest, { isLoading }] =
    useContractorJobCompleteRequestMutation();
  const [feedBack, setfeedBack] = useState(false);
  const handleConfirmJob = async () => {
    try {
      const { data, message, status } = await completeJobRequest({
        job_id,
      }).unwrap();
      if (status === 200) {
        // closeModal();
        socket.emit("notification_send", data);
        setfeedBack(true);
      }
      toast.success(message);
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const handleDecline = () => {
    closeModal();
  };

  return (
    <>
{
  feedBack ? (
    <ContractorFeedback job_id={job_id} />
  ):(
      <div className="flex flex-col items-center gap-4">
        <img src={Confirmation} alt="Confirm Job" className="w-28 h-28" />
        <p className="font-inter font-semibold text-haiti text-base text-center">
          Do you really wants to send request for the job completion?
        </p>
        <div className="flex  gap-2 w-full">
          <CustomButton
            className="bg-white border text-black"
            onClick={handleDecline}
          >
            Not now
          </CustomButton>
          <CustomButton onClick={handleConfirmJob}>
            {isLoading ? "confirming..." : "Confirm"}
          </CustomButton>
        </div>
      </div>
  )
}
    </>
  );
}

export default ConfirmJob;
