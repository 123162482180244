import React from "react";
import ErrorMessage from "./ErrorMessage";

function SelectInput({ heading, options, htmlFor, onChange, value, error,disabled=false }) {
  return (
    <>
      <label
        htmlFor={htmlFor}
        className="block mb-2 text-base font-medium font-inter  "
      >
        {heading && heading}
      </label>
      <select
        id={htmlFor}
        name={htmlFor}
        onChange={onChange}
        value={value}
        disabled={disabled}
        className="bg-whiteSmoke mb-2 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5   "
      >
        <option value="" disabled>
          Select Service
        </option>
        {options?.map((obj) => (
          <option value={(obj.id)} key={obj.id}>
            {obj.name}
          </option>
        ))}
      </select>
      <ErrorMessage error={error} />
    </>
  );
}

export default SelectInput;
