import React, {useState } from "react";
import CustomModal from "../../../components/Modal";
import CreateJobForm from "../Job/CreateJob";
import { useGetServicesQuery } from "../../../redux/api/services";
import { setKey } from "react-geocode";
import { Spinner } from "flowbite-react";
import ServicesCategories from "./Categories";
import FindContractors from "./FindContractors";
import { useDispatch, useSelector } from "react-redux";
import { resetJobData, setJobCategoryData, setJobData } from "../../../redux/slices/job";

function UserHome() {
  const [open, setOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [showContractors, setShowContractors] = useState(false);
  const [selectedServiceCategories, setSelectedServiceCategories] =
  useState("");
  const dispatch=useDispatch();
  const {jobData}=useSelector(state=>state.job);
  const { data, isLoading } = useGetServicesQuery();

  setKey(process.env.REACT_APP_MAP_KEY);

  const handleSubCategory = ({ categories, jobData }) => {
    setOpenCategories(true);
    dispatch(setJobData(jobData));
    setSelectedServiceCategories(categories);
  };

  const handleSelectedCategory = (jobCategoryData) => {
    dispatch(setJobCategoryData(jobCategoryData));
    setOpenCategories(false);
    setShowContractors(true);
  };

  return (
    <>
      <div className="container mx-auto">
        <CustomModal
          onRequestClose={() => {}}
          open={open}
          setOpen={() => setOpen(!open)}
          width="750px"
        >
          <p className="font-inter text-haiti font-bold text-center m-2">
            Create Job
          </p>
          <hr className="mb-2" />
          <CreateJobForm services={data?.data} setOpen={() => setOpen(!open)} />
        </CustomModal>
        <CustomModal
          open={showContractors}
          setOpen={() => setShowContractors(!showContractors)}
        >
          <FindContractors setOpen={setOpen} setShowContractors={setShowContractors}/>
        </CustomModal>
        <CustomModal
          open={openCategories}
          setOpen={() => setOpenCategories(!openCategories)}
        >
          <ServicesCategories
            categories={selectedServiceCategories}
            handleSelectedCategory={handleSelectedCategory}
            jobData={jobData}
          />
        </CustomModal>
        {/* <LoginSocialGoogle
            isOnlyGetToken
            client_id={'562886071308-2hb7kbciupjomoa080vqicq0s6qdbrmk.apps.googleusercontent.com'}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => {
              setProvider(provider  )
              setProfile(data)
            }}
            onReject={(err) => {
              console.log(err)
            }}
          >
          Login with google
          </LoginSocialGoogle> */}
        <div className="flex flex-wrap items-center gap-3 px-2 justify-between">
          <button
            className="border font-inter py-1 px-3 bg-haiti text-white rounded-md"
            onClick={() =>{
              dispatch(resetJobData());
              setOpen(true);
            }}
          >
            Create Job
          </button>
          {/* <div className="flex justify-center mt-1.5 relative w-full max-w-[343px]">
            <input
              type="text"
              placeholder="Search"
              className="p-2 w-full search-box"
            />
            <FaSearch className="absolute right-2 top-3 cursor-pointer " />
          </div> */}
        </div>
        {isLoading ? (
          <div className="w-full items-center flex justify-center">
            <Spinner />
          </div>
        ) : data?.data?.length === 0 ? (
          <p className="font-inter text-haiti font-bold text-center">
            No Service Available
          </p>
        ) : (
          <div className="container p-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2">
            {data?.data.map(({ name, id, categories, image }, i) => (
              <div
                onClick={() =>
                  handleSubCategory({ categories, jobData: { id, name } })
                }
                key={i}
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_BASE_URL.replace(
                    /\/api$/,
                    ""
                  )}/${image})`,
                  height: "200px",
                  backgroundSize: "100% 100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "center",
                  borderRadius: "12px",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
              >
                <p className="bg-[#0A0A56] font-inter font-semibold category-name">
                  {name}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default UserHome;
