import React, { useState } from "react";
import { IoLocationSharp } from "react-icons/io5";
import Calendar from "../assets/icons/Calendar.svg";
import Clock from "../assets/icons/Clock.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import { userRole } from "../utils/currentUser";
import moment from "moment";
import { compareDate, getFormattedDate } from "../utils/DateAndTime";
import { useDeleteJobMutation } from "../redux/api/Jobs";
import { DeleteJobModal } from "./DeleteJobModal";

function Card({ data, onClick, proposals }) {
  const [showDeleteJobModal, setShowDeleteJobModal] = useState(false);
  const [deleteJob, { isLoading: isJobDeleting }] = useDeleteJobMutation();

  const [cardAction, setCardAction] = useState(false);
  const {
    id,
    title,
    services,
    description,
    start_time,
    address,
    date,
    job_status,
  } = data;
  const imageUrl = `${process.env.REACT_APP_BASE_URL.replace(/\/api$/, "")}/${
    services.image
  }`;
  const role = userRole();

  const handleCardAction = async (e) => {
    e.stopPropagation();
    setCardAction(prev=>!prev);
  };

  const handleDeleteJob = async (e) => {
    e.stopPropagation();
    try {
      await deleteJob({job_id:id}).unwrap();
      setShowDeleteJobModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const todayDate = getFormattedDate(new Date());
  const jobDate = getFormattedDate(date);

  const isExpired = compareDate(jobDate, todayDate);

  return (
    <>
      <DeleteJobModal
        isLoading={isJobDeleting}
        openModal={showDeleteJobModal}
        setOpenModal={setShowDeleteJobModal}
        confirmHandler={(e) => handleDeleteJob(e)}
      />
    <div
      className="bg-white shadow-lg p-4 w-full flex flex-col gap-4 cursor-pointer h-[100%] rounded-xl border border-[#1611401A]"
      onClick={onClick}
    >
      <div className="flex justify-between">
        {/* Header Area */}
        <div className="flex gap-2">
          {/* Image div */}
          <div className=" w-12 h-12 ">
            <img src={imageUrl} alt="" className="w-full h-full rounded-lg" />
          </div>

          {/* Header text Area */}
          <div>
            <p className="font-inter font-semibold text-base">{title}</p>
            <div className="flex gap-4 ">
              <div className="grid grid-cols-3">
                <div className="col-span-1">
                  <p className="font-inter font-semibold text-xs text-clearBlue">
                    {services.name}
                  </p>
                </div>
                <div className="col-span-2">
                  {/* <div className="flex text-clearBlue text-xs ">
                <span className="size-4">
              <IoLocationSharp className="my-auto" />
                </span>
              <p className="font-inter font-semibold ">{address}</p>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {job_status === "completed" && (
          <div className="bg-green-700 rounded-lg flex items-center p-2 h-fit">
            <p className="text-xs font-inter text-white">Completed</p>
          </div>
        )}

        {job_status === "live" && (
          <div className="relative">
            {role === "user" && (
              <BsThreeDotsVertical
                className="font-bold text-lg "
                onClick={(e) => handleCardAction(e)}
              />
            )}

            {cardAction && (
              <div
                className="absolute right-0 mt-2 px-1.5 py-2.5 w-36 bg-white rounded shadow-lg"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteJobModal(prev=>!prev);
                    setCardAction(false);
                  }}
                  className="w-full"
                >
                  <span className="font-inter font-semibold text-red-500">
                    Delete Job
                  </span>
                </button>
              </div>
            )}
          </div>
        )}
        {job_status === "invited" && (
          <div>
            {isExpired ? (
              <div className="bg-[#f980808c] rounded-lg flex items-center p-2 h-fit">
                <p className="ml-1 text-xs font-inter text-[#ff0000]">
                  {" "}
                  Expired
                </p>
              </div>
            ) : (
              <div className="bg-haiti rounded-lg flex items-center p-2 h-fit">
                <p className="text-xs font-inter text-white">Invited </p>
              </div>
            )}
          </div>
        )}
      </div>

      {/* body */}
      <div>
        <p className="font-inter font-normal opacity-60 text-sm line-clamp-2">
          {description}
        </p>
      </div>
      <div className="flex gap-2 ">
        <IoLocationSharp className="my-auto text-clearBlue" />
        <p className="font-inter font-semibold text-xs text-clearBlue">
          {address}
        </p>
      </div>

      {/* Footer */}
      <div className="flex gap-4">
        <div className="flex items-center font-semibold gap-2 text-haiti font-inter text-xs">
          <img src={Calendar} />
          <p className="text-haiti font-semibold">
            {/* {moment(date).format("MM/DD/YYYY")} <br/> */}
            {moment(date, "MM-DD-YYYY HH:mm:ss A").format("MMMM Do YYYY")}
          </p>
        </div>

        <div className="flex items-center gap-2 text-haiti font-inter text-xs">
          <img src={Clock} />
          <p className="text-haiti font-semibold">
            {moment(start_time, "H:mm a").format("H:mm a")}
          </p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Card;
