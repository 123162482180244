import React from "react";
import whychoose_img from "../../../../assets/images/whychoose.png";
import Container from "../../../../components/Container";

const whyChooseData = [
  "Enhanced Communication",
  "Reliable Scheduling",
  "Real-time Updates",
  "User-friendly Interface",
  "Homeowner/Contractor Transparency",
];

const WhyChoose = () => {
  return (
    <div className=" py-10 mt-14">
      <Container>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="col-span-2" data-aos="fade-right">
            <img src={whychoose_img} alt="whychoose-img" />
            <p className="font-syne sm:text-3xl lg:text-4xl sm:text-[56px] font-bold">
              Why Choose Us
            </p>
            <p className="text-opacity-60 text-[18px] mt-2">
              Zitro Connect is a phenomenal new tool that revolutionizes the
              relationship between contractors and homeowners. By facilitating
              clear communication, dependable scheduling, and effortless project
              management, Zitro Connect stands out as the premier choice for
              homeowners and contractors aiming for smooth and successful
              project completions.
            </p>
          </div>
          <div className="col-span-1"  data-aos="fade-left">
            <ul className="bg-haiti py-8 w-full px-5 rounded-3xl flex flex-col gap-4">
              {whyChooseData.map((point, i) => (
                <div
                  className="border-b border-white border-opacity-20"
                  key={i}
                >
                  <li className="text-white text-base lg:text-xl list-disc ms-5 font-normal  pb-3">
                    {point}
                  </li>
                </div>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhyChoose;
