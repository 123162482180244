import React from "react";
import { Navigate } from "react-router-dom";
import { getCurrentUser } from "../utils/currentUser";
import { PATH } from "../utils/Path";

function ProtectedRoute({ children }) {
 const isAuth = getCurrentUser();
  return <>{!isAuth ? <Navigate to={PATH.LOGIN} /> : children}</>;
}

export default ProtectedRoute;
