import React from "react";
import { useGetTermsConditionQuery } from "../../../redux/api/TermsCondition";
import Loader from "../../../components/Loader";
import Card from "../../../components/TextCard";

function UserTermsCondition() {
  const { data, isLoading } = useGetTermsConditionQuery();
  return (
    <div>{isLoading ? <Loader /> : <Card>{data?.data?.home_owners}</Card>}</div>
  );
}

export default UserTermsCondition;
