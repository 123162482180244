import React from "react";
import Password from "../../assets/icons/password.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { useResetPasswordMutation } from "../../redux/api/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../utils/Path";

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password_confirmation: Yup.string()
    .required("password must match")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

function ResetPassword() {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const email = JSON.parse(localStorage.getItem("userEmail"));
      try {
        const { data, message, status } = await resetPassword({
          email,
          new_password: values.password,
          new_password_confirmation: values.password_confirmation,
        }).unwrap();
        if (status === 200) {
          toast.success(message);
          navigate(PATH.LOGIN);
        }
        if(status === 400){
            toast.error(message)
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    },
  });
  return (
    <section className="h-[100vh] flex justify-center items-center overflow-y-auto px-4 ">
      <div className="space-y-4 md:space-y-6 w-full">
        <p className="font-inter tet-haiti font-extrabold text-3xl text-center">
          Forgot Password
        </p>

        <form onSubmit={handleSubmit}>
          <CustomInput
            heading="New Password"
            type="password"
            placeholder="Enter password"
            value={values.password}
            onChange={handleChange}
            icon={Password}
            htmlFor="password"
            error={errors.password}
          />

          <CustomInput
            heading="Confirm New Password"
            type="password"
            placeholder="Enter password"
            value={values.password_confirmation}
            onChange={handleChange}
            icon={Password}
            htmlFor="password_confirmation"
            error={errors.password_confirmation}
          />

          <CustomButton type="submit" className="mt-2">
            {isLoading ? "Resetting..." : "Reset Password"}
          </CustomButton>
        </form>
      </div>
    </section>
  );
}

export default ResetPassword;
