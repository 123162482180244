import React from "react";
import { useGetUserPrivacyPolicyQuery } from "../../redux/api/privacy";
import Loader from "../../components/Loader";
import Card from "../../components/TextCard";

function PrivacyPolicy() {
  const { data, isLoading } = useGetUserPrivacyPolicyQuery();
  return <>{isLoading ? <Loader /> : <Card>{data?.data?.contractors}</Card>}</>;
}

export default PrivacyPolicy;
