import React from "react";
import moment from 'moment'
import { Avatar } from "flowbite-react";

function ReceiverMessage({ profile, message, updatedAt }) {
  return (
    <div className="p-4 flex justify-start items-end flex-row-reverse gap-2">
      {/* <Avatar alt="User settings" img={profile} rounded /> */}
      <div className="max-w-[70%]">
        <div className=" bg-[#0A0A56] rounded-tl-lg rounded-tr-lg rounded-bl-lg p-2 text-sm text-white border border-dark border-opacity-10">
          {message}
        </div>
        <span className="text-xs ">{moment(updatedAt).fromNow()}</span>
      </div>
    </div>
  );
}

export default ReceiverMessage;
