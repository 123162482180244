import React from "react";
import { useGetTermsConditionQuery } from "../../../redux/api/TermsCondition";
import Loader from "../../../components/Loader";
import Card from "../../../components/TextCard";

function ContractorTermsCondition() {
  const { data, error, isLoading } = useGetTermsConditionQuery();
  return (
    <div>{isLoading ? <Loader /> : <Card>{data?.data?.contractors}</Card>}</div>
  );
}

export default ContractorTermsCondition;
