import React from "react";
import { Link } from "react-router-dom";

const Copyright = () => {
  const titles ="Home|Services|How it Works|About US|Contact US|Privacy Policy|Terms of Service";
  const links ="/home|/services|/about-us|/about-us|/contact-us|/privacy-policy|/terms-conditions";
  const zitroLinks=links.split('|');
  const linksArr = links.split("|");
  return (
    <div
      className="flex mt-8 gap-2 justify-center xl:justify-between flex-wrap mx-3 md:container md:mx-auto"
    >
      <p className="text-sm text-center sm:text-left">
        Copyright 2024 Zitro Connect. All Right Rezerved by Zitro Servicess LLC
      </p>
      <ul className="flex justify-center xl:justify-normal flex-wrap gap-2">
        {titles.split("|").map((title, i) => (
          <Link to={zitroLinks[i]} key={i} className="hover:underline decoration-slate-400">
          <li className="text-sm m-0" >
            {title} {i < linksArr.length - 1 && "|"}
          </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default Copyright;
