import React, { useState } from "react";
import SearchBox from "../../../components/SearchBox";
import PostedJobs from "./Jobs/Posted";
import InvitedJobs from "./Jobs/Invites";
import { Link } from "react-router-dom";
import { PATH } from "../../../utils/Path";

const CONTRACTS = [{ title: "Posted" }, { title: "Invites" }];

function Home() {
  const [selected, setSelected] = useState(0);
  const handleJobChange = (id) => {
    setSelected(id);
  };

  const JobsComponents=[PostedJobs,InvitedJobs];

  const Jobs=JobsComponents[selected];
  return (
    <div className="container mx-auto">
      <div className="flex justify-center gap-2  md:justify-between md:gap-0 items-center p-4 flex-wrap">
        {/* Pill Tab Area */}
        <div className="flex border rounded-full text-center overflow-hidden cursor-pointer w-full justify-between md:max-w-[343px] md:min-w-[343px]">
          {CONTRACTS.map((obj, i) => (
            <p
              key={i}
              className={`p-2 font-inter w-full  ${
                i > 0 ? "border-l-2" : ""
              }  ${i === selected ? "bg-haiti  text-white  " : ""} `}
              onClick={() => handleJobChange(i)}
            >
              {obj.title}
            </p>
          ))}
        </div>
        {/* Search Box Area  */}
        {/* <SearchBox/> */}
      </div>
<Jobs/>
    </div>
  );
}

export default Home;
