import React from "react";
import Logo from "../assets/images/logo.png";

function Layout() {
  return (
    <div className="flex flex-col items-center justify-center ">
      <img src={Logo} alt="Logo" className="" width={100} />
      <p className="text-2xl font-inter font-bold mt-2">Privacy Policy</p>
    </div>
  );
}

export default Layout;
