import React from "react";
import Carousel from "react-multi-carousel";
import SingleService from "./SingleService";
import CustomButton from "../../../../components/CustomButton";

import Container from "../../../../components/Container";
import { servicesData } from "./data";
import { useNavigate } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Services = () => {
  const navigate = useNavigate();
  return (
    <div className="py-10 mt-8">
      <Container className='relative'>
        <div
          className="flex items-center justify-between"
          data-aos="fade-right"
        >
          <h6 className="text-4xl sm:text-[46px] font-bold font-syne">
            Services
          </h6>
          <CustomButton
            className="w-auto rounded-megaRound py-2 font-syne"
            data-aos="fade-left"
            onClick={() => navigate('/services')}
          >
            See all
          </CustomButton>
        </div>
        <div>
          <div className="mt-8" data-aos="fade-up">
            <Carousel className="service-carousel" responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>
              {servicesData.map((serviceData, i) => (
                <SingleService {...serviceData} key={i} />
              ))}
            </Carousel>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;
