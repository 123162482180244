import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import useScrollTop from "./hooks/useScrollTop";
import "./App.css";

function App() {
  useScrollTop();

  return (
    <>
      <AppRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
