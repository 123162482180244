import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 jobData:{ name: "", id: "" },
 jobCategoryData:{
    category: "",
    id: "",
  },
contractorData:{
    id:''
}
};

const jobSlice = createSlice({
  name: "jobDataSlice",
  initialState,
  reducers: {
    setJobData: (state, action) => {
      state.jobData = action.payload;
    },
    setJobCategoryData:(state, action) => {
        state.jobCategoryData = action.payload;
      },
      setContractorData:(state, action) => {
        state.contractorData = action.payload;
      },
      resetJobData: () => {
        return initialState; 
      }
  },
});

export default jobSlice.reducer;
export const { setJobData,setJobCategoryData,setContractorData,resetJobData } = jobSlice.actions;
