import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authorizationHeader } from "../../utils/currentUser";

// const { access_token } = getCurrentUser();
export const proposalApi = createApi({
  reducerPath: "proposalApi",
  tagTypes: ["proposals"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      return authorizationHeader(headers, getState);
    },
  }),

  endpoints: (builder) => ({
    createProposal: builder.mutation({
      query: (data) => ({
        url: "/contractor/create_proposal",
        method: "POST",
        body: data,
      }),
    }),

    getUserJobproposal: builder.mutation({
      query: (data) => ({
        url: "/user/proposals",
        method: "POST",
        body: data,
      }),
      providesTags: ["proposals"],
    }),

    acceptProposal: builder.mutation({
      query: (data) => ({
        url: "/user/proposal_accept",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["proposals"],
    }),
    
    declineProposal: builder.mutation({
      query: (data) => ({
        url: "/user/proposal_reject",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["proposals"],
    }),

    getContractorProposals: builder.query({
      query: (page=1) => "/contractor/proposals"+`?page=${page}`,
    }),

    getJobProposalDetail: builder.mutation({
      query: (data) => ({
        url:"/contractor/proposal_details",
        method:"POST",
        body:data
      })
    })
  }),
});

export const {
  useCreateProposalMutation,
  useGetUserJobproposalMutation,
  useAcceptProposalMutation,
  useDeclineProposalMutation,
  useGetContractorProposalsQuery,
  useGetJobProposalDetailMutation
} = proposalApi;
