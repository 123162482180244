import React, { useEffect, useState } from 'react'
import Person from "./Person";
import { useGetAllChatsQuery } from '../../../redux/api/chat';
import Loader from '../../../components/Loader';
import Error from '../../../components/Error';
import search from "../../../assets/icons/search_normal.svg";
import clsx from 'clsx';
import { currentUserInfo } from '../../../utils/currentUser';
import { useSearchParams } from 'react-router-dom';
import { img_base_url } from '../../../utils';

const Persons = ({ chatId, setChatId, setSelectedProfileChatData }) => {
    
    const [persons, setPersons] = useState([]);
    const { data: chatsData, isLoading, isError, error, refetch } = useGetAllChatsQuery();
    const [searchTerm, setSearchTerm] = useState('')
    const handleProfileClick = (profileData) => {
        setSelectedProfileChatData(profileData);
        setChatId(profileData.chatId)
    };
    const user = currentUserInfo();
    const [searchParams, ] = useSearchParams();
    const chatSearchId = searchParams.get('chat');
    useEffect(()=>{
        refetch();
    },[]);
    
    useEffect(() => {
     if(chatsData){
        setPersons(chatsData.data.data);
        if(chatSearchId){
            const tempProfileData = chatsData.data.data.find((item) => {
                return item.id == chatSearchId
            });
            if(tempProfileData){
                const receiver = tempProfileData?.sender_id == user.id ? tempProfileData?.receiver : tempProfileData?.sender;
                setSelectedProfileChatData(receiver);
            }
        }
     }
    }, [chatsData]);

    useEffect(() => {
        let filterData;
        if(chatsData){
            filterData = setTimeout(() => {
                setPersons(chatsData.data.data.filter(({receiver})=>receiver.name.toLowerCase().includes(searchTerm.toLowerCase())))
              }, 500)
        }
        return () => clearTimeout(filterData)
    }, [searchTerm])
    
    if (isLoading) {
        return <Loader />
    }
    if (isError) {
        return <Error error={error} />
    }

    return (
        <div className="h-[70vh] overflow-y-auto">
            <div className="w-[90%] m-auto my-4 relative">
            <img src={search} alt="search" className="absolute top-1/4 left-1" />
            <input
              type="text"
              name="search"
              value={searchTerm}
              onChange={(event)=>setSearchTerm(event.target.value)}
              placeholder="Search"
              className="rounded-xl p-3 pl-9 w-full outline-none bg-lightGray7 z-20 "
            />
          </div>
            {persons.map(({  id,updated_at, receiver, sender, last_message }) => {
                const {
                    name, image, id: receiver_id
                } = user.id == receiver.id ? sender : receiver;
                return (
                    <Person
                        key={id}
                        image={image ? `${img_base_url}${image}` : null}
                        name={name}
                        chatId={id}
                        receiver={receiver_id}
                        updated_at={updated_at}
                        className={clsx('border-2 rounded-full',chatId==id?'border-slate-500':'border-none')}
                        last_message={last_message?.message}
                        onClick={handleProfileClick}
                    />
                )
            })}
        </div>
    )
}

export default Persons