import React from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import Email from "../../assets/icons/email.svg";
import { useForgotPasswordMutation } from "../../redux/api/Auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../utils/Path";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Email Required"),
});

function ForgotPassword() {
  const [ForgotPassword, { isLoading }] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { message, status } = await ForgotPassword({
          email: values.email,
        }).unwrap();
        if (status === 200) {
            localStorage.setItem("userEmail", JSON.stringify(values.email))
          navigate(PATH.VERIFICATION, { state: { forgotPassword: true } });
          toast.success(message);
        }
        if(status === 400){
            toast.error(message)
        }
      } catch (err) {
        console.log(err);
        toast.error(err.message);
      }
    },
  });
  return (
    <section className="h-[100vh] flex justify-center items-center overflow-y-auto px-4 ">
      <div className="space-y-4 md:space-y-6 w-full">
        <p className="font-inter tet-haiti font-extrabold text-3xl text-center">
          Forgot Password
        </p>

        <form onSubmit={handleSubmit}>
          <CustomInput
            heading="Email"
            type="email"
            placeholder="Enter email"
            value={values.email}
            onChange={handleChange}
            icon={Email}
            htmlFor="email"
            error={errors.email}
          />

          <CustomButton type="submit" className="mt-2">
            {isLoading ? "Proceeding..." : "Proceed"}
          </CustomButton>
        </form>
      </div>
    </section>
  );
}

export default ForgotPassword;
