import React from "react";
import { useGetUserPrivacyPolicyQuery } from "../../../redux/api/privacy";
import Loader from "../../../components/Loader";
import Card from "../../../components/TextCard";

function ContractorPrivacy() {
  const { data, error, isLoading } = useGetUserPrivacyPolicyQuery();
  return <>{isLoading ? <Loader /> : <Card>{data?.data?.contractors}</Card>}</>;
}

export default ContractorPrivacy;
