import React, { useState } from "react";
import Modal from "react-modal";
import { IoClose } from "react-icons/io5";

function CustomModal({ children, open, setOpen, closeActionHandler, width = '400px', onRequestClose=
  ()=> {
    setOpen(false);
  }
}) {
  function closeModal() {
    setOpen(false);
  }

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1100, // Set the desired z-index for the overlay
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: width,
      width: "100%",
      maxHeight: "100%",
      overflowY: "auto",
      margin: "10px 0px",
    },
  };

  return (
    <div>
      <Modal
        isOpen={open}
        style={customStyles}
        onRequestClose={onRequestClose||closeModal}
        ariaHideApp={false}
      >
        <IoClose
          className="absolute right-2 top-2 cursor-pointer bg-whiteSmoke w-8 h-8 rounded-full p-1"
          onClick={closeActionHandler ? closeActionHandler : closeModal}
        />
        {children}
      </Modal>
    </div>
  );
}

export default CustomModal;
