import { fromLatLng } from "react-geocode";

export const getCurrentAddress = async (lat, long) => {
  let { results } = await fromLatLng(lat, long);
  const addressArray = results[0].address_components;
  return getAddress(addressArray);
};

const getAddress = (addressArray) => {
  let address = "";
  addressArray.forEach((obj) => {
    address += `${obj.long_name} `;
  });

  return address;
};
