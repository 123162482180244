import { licenceApi } from "../redux/api/licence";
import { profileApi } from "../redux/api/profile";
import { authApi } from "../redux/api/Auth";
import { jobsApi } from "../redux/api/Jobs";
import { proposalApi } from "../redux/api/Proposal";
import { reviewApi } from "../redux/api/Review";
import { chatApi } from "../redux/api/chat";
import { completeProfileApi } from "../redux/api/completeProfile";
import { servicesApi } from "../redux/api/services";
import { subscriptionApi } from "../redux/api/subscription";
import { store } from "../redux/store";

export const base_url = process.env.REACT_APP_BASE_URL;
export const img_base_url = process.env.REACT_APP_IMG_BASE_URL;

export const removeRtkDataHandler = () => {
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(completeProfileApi.util.resetApiState());
  store.dispatch(servicesApi.util.resetApiState());
  store.dispatch(subscriptionApi.util.resetApiState());
  store.dispatch(jobsApi.util.resetApiState());
  store.dispatch(proposalApi.util.resetApiState());
  store.dispatch(reviewApi.util.resetApiState());
  store.dispatch(chatApi.util.resetApiState());
  store.dispatch(profileApi.util.resetApiState());
  store.dispatch(licenceApi.util.resetApiState());
  localStorage.removeItem("userData");
};

export const jobNotifyTypes = ["job_complete_request",'job_completed'];
export const proposalNotifyTypes = ["proposal_submitted"];
export const inviteJobNotifyTypes = ["job_invite_declined","job_invite"];
export const reviewNotifyTypes = ["review"];
