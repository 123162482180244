import React from "react";
import { Route, Routes } from "react-router-dom";
import Signup from "../screens/Auth/Signup";
import Login from "../screens/Auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import RootHome from "../screens/home";
import HomeLayout from "../screens/home/layout";
import { PATH } from "../utils/Path";
import ContractorDetail from "../screens/contractor/completeProfile";
import Layout from "../components/Layout";
import AuthLayout from "../screens/Auth/Layout";
import Home from "../screens/contractor/Home";
import Jobs from "../screens/contractor/jobs";
import JobDetail from "../components/Detail";
import CreateJob from "../screens/user/Job";
import UserHome from "../screens/user/Home";
import UserJobDetail from "../components/UserDetail";
import JobProposals from "../screens/user/Job/proposals";
import ProposalDetail from "../screens/contractor/jobs/Proposal/ProposalDetail";
import Verifacation from "../screens/Auth/Verifacation";
import ForgotPassword from "../screens/Auth/ForgotPassword";
import ResetPassword from "../screens/Auth/ResetPassword";
import Messages from "../screens/Messages";
import UserProfile from "../screens/user/profile";
import ContractorProfile from "../screens/user/profile";
import ContractorReviews from "../screens/contractor/Reviews";
import UserReviews from "../screens/user/Reviews";
import ContractorServices from "../screens/contractor/Service";
import ContractorLicences from "../screens/contractor/Licence";
import RoleRoute from "./RoleRoute";
import JobInviteDetail from "../components/JobInviteDetail";
import UserPrivacy from "../screens/user/Privacy";
import ContractorPrivacy from "../screens/contractor/Privacy";
import UserTermsCondition from "../screens/user/TermsCondition";
import ContractorTermsCondition from "../screens/contractor/TermsCondition";
import Services from "../screens/services";
import AboutUs from "../screens/about-us";
import Page404 from "../screens/page-404";
import ContactUs from "../screens/contact-us";
import License from "../screens/license";
import PrivacyPolicy from "../screens/Privacy";
import TermsAndConditions from "../screens/TermsCondition";

function AppRoutes() {
  return (
    <div className="main-body">
      <Routes>
        {/* Auth Route */}
        <Route
          element={
            <HomeLayout>
              <AuthLayout>
                <Signup />
              </AuthLayout>
            </HomeLayout>
          }
          path={PATH.SIGNUP}
        />
        <Route
          element={
            <HomeLayout>
              <RootHome />
            </HomeLayout>
          }
          path={PATH.ROOTHOME}
        />
        <Route
          element={
            <HomeLayout>
              <Services />
            </HomeLayout>
          }
          path={PATH.SERVICES}
        />
        <Route
          element={
            <HomeLayout>
              <AboutUs />
            </HomeLayout>
          }
          path={PATH.ABOUTUS}
        />
        <Route
          element={
            <HomeLayout>
              <ContactUs />
            </HomeLayout>
          }
          path={PATH.CONTACTUS}
        />
        <Route
          element={
            <HomeLayout>
              <AuthLayout>
                <Login />
              </AuthLayout>
            </HomeLayout>
          }
          path={PATH.LOGIN}
        />
        <Route
          element={
            <AuthLayout>
              <Verifacation />
            </AuthLayout>
          }
          path={PATH.VERIFICATION}
        />

        <Route
          element={
            <AuthLayout>
              <ForgotPassword />
            </AuthLayout>
          }
          path={PATH.FORGOTPASSWORD}
        />

        <Route
          element={
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          }
          path={PATH.RESETPASSWORD}
        />

        {/* contractor complete profile route */}
        <Route element={<ContractorDetail />} path={PATH.CONTRACTORDETAIL} />

        <Route
          path={PATH.HOME}
          element={
            <HomeLayout>
              <RootHome />
            </HomeLayout>
          }
        />

        {/* <Route
          element={
            <ProtectedRoute>
              <Layout/>
            </ProtectedRoute>
          }
          path="/"
        > */}
        <Route
          element={
            <ProtectedRoute>
              <RoleRoute role="contractor">
                <Layout />
              </RoleRoute>
            </ProtectedRoute>
          }
          path="/contractor"
        >
          <Route element={<Messages />} path={PATH.CHATS} />
          <Route element={<Home />} path={PATH.DASHBOARD} />
          <Route element={<Jobs />} path={PATH.JOB} />
          <Route
            element={<ContractorProfile />}
            path={PATH.CONTRACTORPROFILE}
          />
          <Route element={<JobDetail />} path={PATH.CONTRACTORJOBDetail} />
          <Route
            element={<JobInviteDetail />}
            path={PATH.CONTRACTORJOBINVITEDetail}
          />
          <Route element={<ContractorReviews />} path={PATH.REVIEWS} />
          <Route element={<ContractorServices />} path={PATH.SERVICES} />
          <Route element={<ContractorLicences />} path={PATH.LICENCES} />
          <Route
            element={<ProposalDetail />}
            path={`${PATH.JOBDETAIL}${PATH.PROPOSALS}`}
          />
        </Route>

        <Route
          element={<ContractorPrivacy />}
          path={PATH.CONTRACTORPRIVACYPOLICY}
        />
        <Route
          element={<ContractorTermsCondition />}
          path={PATH.CONTRACTORTERMSCONDITION}
        />

        <Route
          element={
            <ProtectedRoute>
              <RoleRoute role="user">
                <Layout />
              </RoleRoute>
            </ProtectedRoute>
          }
          path="/user"
        >
          <Route element={<UserHome />} path={PATH.DASHBOARD} />
          <Route element={<Messages />} path={PATH.CHATS} />
          <Route element={<CreateJob />} path={PATH.JOB} />
          <Route element={<UserProfile />} path={PATH.PROFILE} />
          <Route element={<ContractorProfile />} path={PATH.PROFILE} />
          <Route element={<UserJobDetail />} path={PATH.JOBDETAIL} />
          <Route
            element={<JobInviteDetail />}
            path={PATH.USERJOBINVITEDETAIL}
          />
          <Route element={<UserReviews />} path={PATH.REVIEWS} />

          <Route
            element={<JobProposals />}
            path={`${PATH.JOBDETAIL}${PATH.PROPOSALS}`}
          />
        </Route>

        <Route element={<UserPrivacy />} path={PATH.USERPRIVACYPOLICY} />
        <Route
          element={<UserTermsCondition />}
          path={PATH.USERTERMSCONDITION}
        />
        <Route element={<License />} path={PATH.LICENSE} />
        <Route
          element={
            <HomeLayout>
              <div className="pt-14">
              <PrivacyPolicy />
              </div>
            </HomeLayout>
          }
          path={PATH.PRIVACYPOLICY}
        />
          <Route
          element={
            <HomeLayout>
              <div className="pt-14">
              <TermsAndConditions />
              </div>
            </HomeLayout>
          }
          path={PATH.TERMSANDCONDITIONS}
        />
        <Route
          element={
            <HomeLayout>
              <Page404 />
            </HomeLayout>
          }
          path="*"
        />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default AppRoutes;
