import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export function DeleteJobModal({isLoading,openModal,setOpenModal,confirmHandler}) {

  return (
      <Modal
        show={openModal}
        size="md"
        onClose={() => setOpenModal(false)}
        popup
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400" />
            <h3 className="text-lg font-normal text-gray-500">
              Are you sure you want to delete this Job?
            </h3>
            <div className="flex justify-center gap-4 mt-6">
              <Button className="w-1/2" isProcessing={isLoading} color="failure" onClick={(e)=>confirmHandler(e)}>
                Yes
              </Button>
              <Button className="w-1/2" disabled={isLoading} color="gray" onClick={() => setOpenModal(false)}>
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  );
}
