import React from "react";
import property1 from "../../../../assets/images/property1.png";
import property2 from "../../../../assets/images/property2.png";
import property3 from "../../../../assets/images/property3.png";
import property4 from "../../../../assets/images/property4.png";
import Container from "../../../../components/Container";

const Properties = () => {
 

  return (
    <div className="mt-20 pt-10 pb-14">
      <Container>
        <h6 className="text-3xl sm:text-[46px] font-bold font-syne" data-aos="fade-down">Featured Properties</h6>
        <div className="mt-10 grid grid-cols-3 gap-4">
          <img
            src={property1}
            alt="property1"
            data-aos="fade-right"
            className="w-full row-start-1 row-end-2 max-h-[576px]"
          />
          <div className="w-full  max-h-[576px] h-full flex justify-between gap-4 flex-col row-start-1 row-end-2">
            <img src={property2} data-aos="fade-left" alt="property2" className="w-full h-full" />
            <img src={property3} data-aos="fade-left" alt="property3" className="w-full  h-full" />
          </div>
          <img
            src={property4}
            alt="property4"
            data-aos="fade-right"
            className="w-full row-start-1 row-end-2 max-h-[576px]"
          />
        </div>
      </Container>
    </div>
  );
};

export default Properties;
